import React, { Component } from 'react';
import { BrowserRouter, Route, Link } from "react-router-dom";
import { renderToString } from "react-dom/server";
import { MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter, MDBRow, MDBCol, MDBIcon, MDBBtn, MDBTable, MDBTableBody, MDBTableHead } from 'mdbreact';
import firestore from "firebase/firestore"
import firebase from "../../firebase"
import NoukiModal from './NoukiModal';
import SyukkaModal from './SyukkaModal';
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./Order.css";

import autoTable from "jspdf-autotable";
import _ from "lodash";
import {toast} from "react-toastify";
import {Button} from "antd";

const nl2br = require('react-nl2br');

let MeiryoRegular;
let MeiryoBold;
let downloadClicked = false;

class OrderUrigawa extends Component {
  constructor(props) {
    super(props);
    this.selectorRef = React.createRef(null);

    this.state = {
      data: {},
      noukiSDate: '',
      noukiEDate: '',
      okurijyoNo: '',
      noukiModal: false,
      noukiCommentModal: false,
      noukiAllModal: false,
      syukkaModal: false,
      syukkaCommentModal: false,
      syukkaAllModal: false,
      countZeroModal: false,
      countMinusModal: false,
      notSendingModal: false,
      notZeroModal: false,
      pdfModal: false,
      divideModal: false,
      divideConfirmModal: false,
      dataPDF: {
        deliveryAddress: {}
      },
      fileNamePDF: '',
      loadingPDF: false,
      showMenu: '',
      showMenuIndex: null,

      tempItem: {},
      tempIndex: -1,
      divideType: null,
      cancelModal: false,
      cancelId: null,
      cancelIndex: null,
      cancelRejectModal: false,
      cancelRejectId: null,
      cancelRejectIndex: null,
      returnModal: false,
      returnId: null,
      returnIndex: null,
      returnRejectModal: false,
      returnRejectId: null,
      returnRejectIndex: null,
      showToast: false,
      loadSent: {}
    }
  }


  getCarts = async () => {
    let collection;
    let dateNow = firebase.firestore.Timestamp.now().toDate();
    // console.log('dateNow', dateNow.getFullYear())
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(this.props.org && collection){
      // this.unsub = this.props.db.collection('orders').doc(this.props.id).onSnapshot({
      //   error: e => console.log('e',e),
      //   next: snapshot => {
      //     let data = snapshot.data();
      //     data.id = snapshot.id;
      //     console.log('data', snapshot.data());

          let o = await this.props.db.collection('orders').doc(this.props.id).get();
          let data = o.data();
          data.id = o.id;

          if(data.toOrganization != this.props.userOrg.id ||
              data.fromOrganization != this.props.org.id
          ){
            return;
          }

          let noukiSDate = '';
          let noukiEDate = '';
          let okurijyoNo = '';

          if(data.nouki){
            noukiSDate = data.nouki.slice(-1)[0].sdate ? data.nouki.slice(-1)[0].sdate.toDate() : '';
            noukiEDate = data.nouki.slice(-1)[0].edate ? data.nouki.slice(-1)[0].edate.toDate() : '';
          }
          if(data.okurijyoNo){
            okurijyoNo = data.okurijyoNo.slice(-1)[0].value;
          }

          let cart = data.cart;
          if (!data.newApply && !data.newReject) {
            Promise.all(cart.items.map(async (item) => {
              let itemSell = await collection.doc(item.id).get();
              let itemSellData = itemSell.data();
              if (itemSellData && itemSellData['price']) {
                item.price = itemSellData['price'][0]['price'];
              }
            }));
          }

          this.setState({
            data: data,
            noukiSDate: noukiSDate,
            noukiEDate: noukiEDate,
            okurijyoNo: okurijyoNo,
          }, () => {
            let wrapper1 = document.getElementById('wrapper1');
            let div1 = document.getElementById('div1');
            let wrapper2 = document.getElementsByClassName('table-responsive')[0];
            let div2 = document.getElementById('div2');

            if (wrapper1 && div1 && wrapper2 && div2) {
              div1.style.width = div2.offsetWidth+'px';

              wrapper1.onscroll = function() {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
              };
              wrapper2.onscroll = function() {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
              };
            }
          })
        // }
      // })

    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.org !== prevProps.org) {
      this.getCarts();
    }
  }

  componentDidMount() {
    // console.log('urigawa')
    this.getCarts();
    this.loadFont();
    this.changeListener();
  }

  componentWillUnmount() {
    this.unsub()
  }

  changeListener = () => {
    this.unsub = this.props.db.collection("orders").doc(this.props.id).onSnapshot({
      error: e => console.log('e',e),
      next: snapshot => {
        if (Object.keys(this.state.data).length > 0) {
          let data1 = snapshot.data();
          let changeDate1 = data1.changeDate;

          let data = this.state.data;
          let changeDate = data.changeDate;

          // let isEqual = _.isEqual(items, items1);
          // console.log(changeDate, changeDate1, this.state.showToast);
          if (changeDate !== changeDate1 && !this.state.showToast) {
            this.setState({ showToast: true }, () => {
              toast.info(({closeToast}) => <div style={{display: 'flex', flexDirection: 'column'}}>
                <div>この注文データが更新されました。<br/>更新ボタンをクリックして最新のデータを読み込んでください。</div>
                <Button size="small" type="primary" style={{alignSelf: 'flex-end', backgroundColor: '#3498db'}} onClick={() => {this.getCarts(); closeToast()}}>更新</Button>
              </div>, {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                onClose: (e) => this.setState({showToast: false})
              });
            });
          }
        }

      }
    });
  }

  loadFont = () => {
    Promise.all([import(/* webpackIgnore: true */ '/fontsModule/MeiryoRegular.js'), import(/* webpackIgnore: true */ '/fontsModule/MeiryoBold.js')])
        .then(([module1, module2]) => {
          MeiryoRegular = module1.default;
          MeiryoBold = module2.default;

          if (downloadClicked === true) {
            this.showPdf()
          }
        })
  }

  visitedOrder = (e) => {
    let esId = e.currentTarget.dataset.id;

    let ordersRef = this.props.db.collection('orders').doc(esId);
    let username = this.props.user.last_name + ' '+ this.props.user.first_name;
    let sellerConfirmDate = firebase.firestore.Timestamp.fromDate(new Date());

    ordersRef.set({
      visited: {[this.props.user.id]: true},
      approveUser: username,
      readSeller: true,
      readBuyer: false,
      sellerConfirm: true,
      sellerConfirmDate: sellerConfirmDate,
      // sortDate: sellerConfirmDate,
      latestUpdate: sellerConfirmDate,
    }, { merge: true });

    this.props.readOrder(1);

    this.stateOrderUpdate("visited",{[this.props.user.id]:true});
    this.stateOrderUpdate("sellerConfirmDate",sellerConfirmDate);
    this.stateOrderUpdate("latestUpdate",sellerConfirmDate);
    this.stateOrderUpdate("sellerConfirm",true);
  }

  stateOrderUpdate = (target,value) => {

    let dbOrderUpdated = {};
    Object.assign(dbOrderUpdated, this.state.data);
    if(target === 'visited') {
      let visited = dbOrderUpdated.visited;
      if(visited){
        dbOrderUpdated[target] = Object.assign(visited, value);
      } else {
        dbOrderUpdated[target] = value;
      }
    }else{
      dbOrderUpdated[target] = value;
    }
    this.setState({
      data: dbOrderUpdated,
    })
  }

  handleSubItemChange(v, num, mainSubDivided) {
    let value = v.target.value*1;
    if (value <= 0) {
      this.setState({notZeroModal: true});
      return;
    }
    if (this.state.data.cart.items[num] && this.state.data.cart.items[num].divideConfirm === false) {
      let dbOrderUpdated = {};
      Object.assign(dbOrderUpdated, this.state.data);

      let item = dbOrderUpdated.cart.items[num];
      item.count = value;

      this.setState({
        data: dbOrderUpdated,
      }, () => {
        if (!this.checkMaxDivided(item)) {
          let ordersRef = this.props.db.collection('orders').doc(dbOrderUpdated.id);
          ordersRef.update({
            cart: dbOrderUpdated.cart,
            //   latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
          })
        }
      })


    }
  }

  handleItemNoukiCommentChange(v, num) {
    let value = v.target.value;
    if (this.state.data.cart.items[num]) {
      let dbOrderUpdated = {};
      Object.assign(dbOrderUpdated, this.state.data);

      let item = dbOrderUpdated.cart.items[num];
      if (value) {
        item.noukiComment = value;
      } else {
        item.noukiComment = null;
      }

      // let ordersRef = this.props.db.collection('orders').doc(this.state.data.id);
      // ordersRef.set(dbOrderUpdated, { merge: true });

      this.setState({
        data: dbOrderUpdated
      })
    }
  }

  handleItemOkurijyoCommentChange(v, num) {
    let value = v.target.value;
    if (this.state.data.cart.items[num]) {
      let dbOrderUpdated = {};
      Object.assign(dbOrderUpdated, this.state.data);

      let item = dbOrderUpdated.cart.items[num];
      if (value) {
        item.okurijyoComment = value;
      } else {
        item.okurijyoComment = null;
      }

      item.okurijyoNo[item.okurijyoNo.length - 1].comment = value;
      item.okurijyoNo[item.okurijyoNo.length - 1].latestUpdate = firebase.firestore.Timestamp.fromDate(new Date());

      dbOrderUpdated.isShipped = false;
      dbOrderUpdated.changeDate = new Date().getTime();

      this.setState({
        data: dbOrderUpdated,
      }, () => {
        let ordersRef = this.props.db.collection('orders').doc(this.state.data.id);
        ordersRef.set(dbOrderUpdated, { merge: true });
      })

      // this.setState({
      //   data: dbOrderUpdated
      // })
    }
  }

  handleItemNoukiSDateChange(date,num)  {
    if(this.state.data.cart.items[num]){
      let dbOrderUpdated = {};
      Object.assign(dbOrderUpdated, this.state.data);

      let item = dbOrderUpdated.cart.items[num];
      if(! item.nouki){
        item.nouki = [
          {
            sdate:null,
            edate:null
          }
        ];
      }
      if(date){
        if (date.getFullYear() === 2001) {
          let dateNow = firebase.firestore.Timestamp.now().toDate();
          date.setFullYear(dateNow.getFullYear());
        }
        // console.log('date', date, date.getFullYear())
        item.nouki[item.nouki.length - 1].sdate= firebase.firestore.Timestamp.fromDate(date);
      }else{
        item.nouki[item.nouki.length - 1].sdate= null;
      }
      this.setState({
        data: dbOrderUpdated,
      })
    }
  };
  handleItemNoukiEDateChange = (date,num) => {
    if(this.state.data.cart.items[num]){
      let dbOrderUpdated = {};
      Object.assign(dbOrderUpdated, this.state.data);

      let item = dbOrderUpdated.cart.items[num];
      if(!item.nouki){
        item.nouki = [
          {
            sdate:null,
            edate:null
          }
        ];
      }
      if(date){
        if (date.getFullYear() === 2001) {
          let dateNow = firebase.firestore.Timestamp.now().toDate();
          date.setFullYear(dateNow.getFullYear());
        }

        item.nouki[item.nouki.length - 1].edate= firebase.firestore.Timestamp.fromDate(date);
      }else{
        item.nouki[item.nouki.length - 1].edate= null;
      }
      this.setState({
        data: dbOrderUpdated,
      })
    }
  };
  handleItemHassouDateChange = (date,num) => {
    // console.log('date', date, num);
    // return;
    if(this.state.data.cart.items[num]){
      let dbOrderUpdated = {};
      Object.assign(dbOrderUpdated, this.state.data);

      let item = dbOrderUpdated.cart.items[num];
      if(!item.okurijyoNo){
        item.okurijyoNo = [
          {
            value:null,
            hassouDate:null
          }
        ];
      }

      if (date) {
        // let sDate = item.nouki[item.nouki.length - 1].sdate;
        let dateNow = firebase.firestore.Timestamp.now().toDate();
        if (date.getFullYear() === 2001) {
          date.setFullYear(dateNow.getFullYear());
        }

        // if (sDate) {
        //   let sDateD = sDate.toDate();
        //
        //   let sDateMonth = sDateD.getMonth()+1;
        //   let sDateDay = sDateD.getDate();
        //
        //   let hDateMonth = date.getMonth()+1;
        //   let hDateDay = date.getDate();
        //
        //   // if (sDateMonth > hDateMonth || (sDateMonth === hDateMonth && sDateDay > hDateDay)) {
        //   //   date.setFullYear(dateNow.getFullYear()+1);
        //   // }
        // }

        item.okurijyoNo[item.okurijyoNo.length - 1].hassouDate= firebase.firestore.Timestamp.fromDate(date);
      } else {
        item.okurijyoNo[item.okurijyoNo.length - 1].hassouDate= null;
        dbOrderUpdated.isShipped = false;
      }

      dbOrderUpdated.isShipped = false;

      item.okurijyoNo[item.okurijyoNo.length - 1].latestUpdate = firebase.firestore.Timestamp.fromDate(new Date());

      if (item.nouki) {

      }
      console.log('dbOrderUpdated', dbOrderUpdated);

      dbOrderUpdated.changeDate = new Date().getTime();

      this.setState({
        data: dbOrderUpdated,
      }, () => {
        let ordersRef = this.props.db.collection('orders').doc(this.state.data.id);
        try {
          ordersRef.set(dbOrderUpdated, { merge: true });
        } catch (e) {
          console.log('e',e)
        }
      })
    }
  };
  handleItemOkurijyoNoChange = (e,num) => {
    // console.log('num', num)
    if(this.state.data.cart.items[num]){
      let dbOrderUpdated = {};
      let value = e.currentTarget.value;
      Object.assign(dbOrderUpdated, this.state.data);

      let item = dbOrderUpdated.cart.items[num];
      if(! item.okurijyoNo){
        item.okurijyoNo = [
          {
            value:null,
            hassouDate:null
          }
        ];
      }
      item.okurijyoNo[item.okurijyoNo.length - 1].value = value;
      item.okurijyoNo[item.okurijyoNo.length - 1].latestUpdate = firebase.firestore.Timestamp.fromDate(new Date())
      if (!value) {
        dbOrderUpdated.isShipped = false;
      }

      dbOrderUpdated.isShipped = false;
      dbOrderUpdated.changeDate = new Date().getTime();

      // console.log('dbOrderUpdated', dbOrderUpdated)

      this.setState({
        data: dbOrderUpdated,
      }, () => {
        let ordersRef = this.props.db.collection('orders').doc(this.state.data.id);
        ordersRef.set(dbOrderUpdated, { merge: true });
      })

      // console.log('dbOrderUpdated', dbOrderUpdated)


    }
  };
  handleItemNoukiDateUpdate = (num, e) => {
    // console.log(this.state.data.cart.items[num], this.state.data.cart.items[num].nouki, this.state.data.cart.items[num].noukiComment);
    // return;
    if (this.state.data.cart.items[num] && this.state.data.cart.items[num].nouki && this.state.data.cart.items[num].noukiComment) {
      let esId = e.currentTarget.dataset.id;
      let ordersRef = this.props.db.collection('orders').doc(esId);
      ordersRef.get()
          .then((doc) => {
            if (doc.exists) {
              const data = doc.data();
              let nouki = [];
              if (data.cart.items[num].nouki) {
                nouki = nouki.concat(data.cart.items[num].nouki)
              }
              nouki.push({
                sdate: this.state.data.cart.items[num].nouki.slice(-1)[0].sdate ? this.state.data.cart.items[num].nouki.slice(-1)[0].sdate : '',
                edate: this.state.data.cart.items[num].nouki.slice(-1)[0].edate ? this.state.data.cart.items[num].nouki.slice(-1)[0].edate : '',
                created: firebase.firestore.Timestamp.fromDate(new Date()),
                deliveryDate: firebase.firestore.Timestamp.fromDate(new Date()),
                latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
                user: this.props.user.id,
              })

              data.cart.items[num].nouki = nouki;
              data.cart.items[num].noukiComment = this.state.data.cart.items[num].noukiComment || null;
              data.cart.items[num].readComment = false;

              let orderData = {
                cart: data.cart,
                latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
              }

              if (data.cart.items[num].noukiComment) {
                orderData.readComment = false;
                orderData.readBuyer = false;
              }
              ordersRef.update(orderData)
            }
          });

      this.setState({
        noukiAllModal: true,
      })
    } else if(this.state.data.cart.items[num] && this.state.data.cart.items[num].nouki) {
      let esId = e.currentTarget.dataset.id;

      let ordersRef = this.props.db.collection('orders').doc(esId);

      ordersRef.get()
      .then((doc) => {
        if(doc.exists) {
          const data = doc.data();
          let nouki = [];
          if(data.cart.items[num].nouki){
            nouki = nouki.concat(data.cart.items[num].nouki);
          }
          nouki.push(
            {
              sdate: this.state.data.cart.items[num].nouki.slice(-1)[0].sdate ? this.state.data.cart.items[num].nouki.slice(-1)[0].sdate : '',
              edate: this.state.data.cart.items[num].nouki.slice(-1)[0].edate ? this.state.data.cart.items[num].nouki.slice(-1)[0].edate : '',
              created: firebase.firestore.Timestamp.fromDate(new Date()),
              deliveryDate: firebase.firestore.Timestamp.fromDate(new Date()),
              latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
              user: this.props.user.id,
            }
          )

          // console.log('nouki', nouki);
          // return;

          data.cart.items[num].nouki = nouki;
          // data.cart.items[num].noukiComment = this.state.data.cart.items[num].noukiComment || null;
          // data.cart.items[num].readComment = false;

          let orderData = {
            cart: data.cart,
            latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
          }

          // if (data.cart.items[num].noukiComment) {
          //   orderData.readComment = false;
          //   orderData.readBuyer = false;
          // }
          ordersRef.update(orderData)
        }
      })
      this.setState({
        noukiModal: true,
      })
    } else if (this.state.data.cart.items[num] && this.state.data.cart.items[num].noukiComment) {
      let esId = e.currentTarget.dataset.id;

      let ordersRef = this.props.db.collection('orders').doc(esId);

      ordersRef.get()
          .then((doc) => {
            if(doc.exists) {
              const data = doc.data();
              // let nouki = [];
              // if(data.cart.items[num].nouki){
              //   nouki = nouki.concat(data.cart.items[num].nouki);
              // }
              // nouki.push(
              //     {
              //       sdate: this.state.data.cart.items[num].nouki.slice(-1)[0].sdate ? this.state.data.cart.items[num].nouki.slice(-1)[0].sdate : '',
              //       edate: this.state.data.cart.items[num].nouki.slice(-1)[0].edate ? this.state.data.cart.items[num].nouki.slice(-1)[0].edate : '',
              //       created: firebase.firestore.Timestamp.fromDate(new Date()),
              //       deliveryDate: firebase.firestore.Timestamp.fromDate(new Date()),
              //       latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
              //       user: this.props.user.id,
              //     }
              // )
              // data.cart.items[num].nouki = nouki;
              data.cart.items[num].noukiComment = this.state.data.cart.items[num].noukiComment || null;
              data.cart.items[num].readComment = false;

              let orderData = {
                cart: data.cart,
                latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
              }

              if (data.cart.items[num].noukiComment) {
                orderData.readComment = false;
                orderData.readBuyer = false;
              }
              ordersRef.update(orderData)
            }
          })

      console.log('nouki comment send');
      this.setState({
        noukiCommentModal: true
      })
    }
  };
  handleItemOkurijyoNoUpdate = (num, e) => {
    let loadSent = this.state.loadSent;
    loadSent[num] = true;
    this.setState({loadSent});
    if (this.state.data.cart.items[num] && this.state.data.cart.items[num].okurijyoNo && this.state.data.cart.items[num].okurijyoComment) {
      let esId = e.currentTarget.dataset.id;
      let ordersRef = this.props.db.collection('orders').doc(esId);

      ordersRef.get()
          .then((doc) => {
            if(doc.exists) {
              const data = doc.data();
              let sData = this.state.data;
              let okurijyoNo = [];
              if(data.cart.items[num].okurijyoNo){
                okurijyoNo = okurijyoNo.concat(data.cart.items[num].okurijyoNo);
              }
              let date = firebase.firestore.Timestamp.fromDate(new Date());
              okurijyoNo.push(
                  {
                    value: this.state.data.cart.items[num].okurijyoNo.slice(-1)[0].value,
                    hassouDate: this.state.data.cart.items[num].okurijyoNo.slice(-1)[0].hassouDate,
                    comment: this.state.data.cart.items[num].okurijyoNo.slice(-1)[0].comment || this.state.data.cart.items[num].okurijyoComment || null,
                    created: date,
                    shippingDate: date,
                    shippingValue: this.state.data.cart.items[num].okurijyoNo.slice(-1)[0].value,
                    shippingHassouDate: this.state.data.cart.items[num].okurijyoNo.slice(-1)[0].hassouDate,
                    latestUpdate: date,
                    user: this.props.user.id,
                  }
              )
              data.cart.items[num].okurijyoNo = okurijyoNo;
              data.cart.items[num].okurijyoComment = this.state.data.cart.items[num].okurijyoComment || null;
              data.cart.items[num].sendShippedEmail = true;
              data.cart.items[num].no = sData.cart.items[num].no || null;

              let shippedItemCount = 0;
              let possibleShippedItemCount = 0;
              for (let i=0; i<data.cart.items.length; i++) {
                let item = data.cart.items[i];
                if (!item.divided && !item.cancelConfirm && !item.returnMain && !item.returnConfirm) {
                  possibleShippedItemCount += 1;
                }
                if (item.okurijyoNo) {
                  let okurijyoNo = item.okurijyoNo.slice(-1)[0].value;
                  let hassouDate = item.okurijyoNo.slice(-1)[0].hassouDate ? item.okurijyoNo.slice(-1)[0].hassouDate.toDate() : '';
                  if (okurijyoNo && hassouDate) {
                    shippedItemCount++;
                  }
                }
              }

              let latestDueUpdateDate = new Date();

              let tItems = data.cart.items;
              let tOno = tItems[num].okurijyoNo;
              if (tOno) {
                tOno[tOno.length-1].shippingDate = date;
                tOno[tOno.length-1].latestUpdate = date;
              }

              let upd = {
                cart: data.cart,
                readBuyer: false,
                latestUpdate: date,
                latestDueUpdateDate: latestDueUpdateDate.getTime(),
              }

              // console.log('upd', upd)

              if (shippedItemCount >= possibleShippedItemCount) {
                upd['isShipped'] = true;
                // tData.isShipped = true;
                // this.props.shippedOrder(1);
              }

              if (data.cart.items[num].okurijyoComment) {
                upd.readComment = false;
                upd.readBuyer = false;
              }

              upd.changeDate = new Date().getTime();

              let updD = _.cloneDeep(upd);

              let updatedCart = updD.cart;
              let updatedItems = updatedCart.items;
              // let updated = false;
              for (let i=0; i<updatedItems.length; i++) {
                let updatedItem = updatedItems[i];
                if (updatedItem.sendShippedEmail === true) {
                  delete updatedItem.sendShippedEmail;
                  // updated = true;
                }
              }
              // console.log('upd', upd, updD)

              this.setState({
                data: {...data, ...updD},
              }, () => {
                ordersRef.set(upd, { merge: true }).then(v => {
                  loadSent[num] = false;
                  this.setState({loadSent})
                });
              })
            }
          })
      this.setState({
        syukkaAllModal: true,
      })
    } else if(this.state.data.cart.items[num] && this.state.data.cart.items[num].okurijyoNo) {
      let esId = e.currentTarget.dataset.id;

      let ordersRef = this.props.db.collection('orders').doc(esId);

      ordersRef.get()
      .then((doc) => {
        if(doc.exists) {
          const data = doc.data();
          let sData = this.state.data;
          let okurijyoNo = [];
          if(data.cart.items[num].okurijyoNo){
            okurijyoNo = okurijyoNo.concat(data.cart.items[num].okurijyoNo);
          }
          let date = firebase.firestore.Timestamp.fromDate(new Date());
          okurijyoNo.push(
            {
              value: this.state.data.cart.items[num].okurijyoNo.slice(-1)[0].value,
              hassouDate: this.state.data.cart.items[num].okurijyoNo.slice(-1)[0].hassouDate,
              comment: this.state.data.cart.items[num].okurijyoComment || null,
              created: date,
              shippingDate: date,
              shippingValue: this.state.data.cart.items[num].okurijyoNo.slice(-1)[0].value,
              shippingHassouDate: this.state.data.cart.items[num].okurijyoNo.slice(-1)[0].hassouDate,
              latestUpdate: date,
              user: this.props.user.id,
            }
          )
          data.cart.items[num].okurijyoNo = okurijyoNo;
          data.cart.items[num].sendShippedEmail = true;
          data.cart.items[num].no = sData.cart.items[num].no || null;

          let shippedItemCount = 0;
          let possibleShippedItemCount = 0;
          for (let i=0; i<data.cart.items.length; i++) {
            let item = data.cart.items[i];
            if (!item.divided && !item.cancelConfirm && !item.returnMain && !item.returnConfirm) {
              possibleShippedItemCount += 1;
            }

            if (item.okurijyoNo) {
              let okurijyoNo = item.okurijyoNo.slice(-1)[0].value;
              let hassouDate = item.okurijyoNo.slice(-1)[0].hassouDate ? item.okurijyoNo.slice(-1)[0].hassouDate.toDate() : '';
              if (okurijyoNo && hassouDate) {
                shippedItemCount++;
              }
            }
          }

          let latestDueUpdateDate = new Date();

          let tItems = data.cart.items;
          let tOno = tItems[num].okurijyoNo;
          if (tOno) {
            tOno[tOno.length-1].shippingDate = date;
            tOno[tOno.length-1].latestUpdate = date
          }

          let upd = {
            cart: data.cart,
            readBuyer: false,
            latestUpdate: date,
            latestDueUpdateDate: latestDueUpdateDate.getTime(),
          }

          // console.log('shippedItemCount', shippedItemCount, possibleShippedItemCount, data.cart.items.length)
          // return;

          // let tData = this.state.data;

          if (shippedItemCount >= possibleShippedItemCount) {
            upd['isShipped'] = true;
            // this.props.shippedOrder(1);
            // tData.isShipped = true;
          }

          // ordersRef.update(upd)
          // console.log('upd', upd);
          upd.changeDate = new Date().getTime();

          let updD = _.cloneDeep(upd);

          let updatedCart = updD.cart;
          let updatedItems = updatedCart.items;
          // let updated = false;
          for (let i=0; i<updatedItems.length; i++) {
            let updatedItem = updatedItems[i];
            if (updatedItem.sendShippedEmail === true) {
              delete updatedItem.sendShippedEmail;
              // updated = true;
            }
          }
          // console.log('upd', upd, updD)

          this.setState({
            data: {...data, ...updD},
          }, () => {
            ordersRef.set(upd, { merge: true }).then(v => {
              loadSent[num] = false;
              this.setState({loadSent})
            }).catch(e => console.log('error', e));
          });
        }
      })
      this.setState({
        syukkaModal: true,
      })
    } else if (this.state.data.cart.items[num] && this.state.data.cart.items[num].okurijyoComment) {
      let esId = e.currentTarget.dataset.id;
      let ordersRef = this.props.db.collection('orders').doc(esId);

      ordersRef.get()
          .then((doc) => {
            if(doc.exists) {
              const data = doc.data();
              let sData = this.state.data;
              data.cart.items[num].okurijyoComment = this.state.data.cart.items[num].okurijyoComment || null;
              data.cart.items[num].no = sData.cart.items[num].no;

              let date = firebase.firestore.Timestamp.fromDate(new Date());
              let latestDueUpdateDate = new Date();

              let upd = {
                cart: data.cart,
                readBuyer: false,
                latestUpdate: date,
                latestDueUpdateDate: latestDueUpdateDate.getTime(),
              }

              if (data.cart.items[num].okurijyoComment) {
                upd.readComment = false;
                upd.readBuyer = false;
              }

              upd.changeDate = new Date().getTime();

              let updD = _.cloneDeep(upd);

              let updatedCart = updD.cart;
              let updatedItems = updatedCart.items;
              // let updated = false;
              for (let i=0; i<updatedItems.length; i++) {
                let updatedItem = updatedItems[i];
                if (updatedItem.sendShippedEmail === true) {
                  delete updatedItem.sendShippedEmail;
                  // updated = true;
                }
              }
              // console.log('upd', upd, updD)

              this.setState({
                data: {...data, ...updD},
              }, () => {
                ordersRef.set(upd, { merge: true }).then(v => {
                  loadSent[num] = false;
                  this.setState({loadSent})
                });
              })
            }
          });

      this.setState({
        syukkaCommentModal: true,
      })
    }

};



  handleNoukiSDateChange = (date) => {
    this.setState({
      noukiSDate: date
    });
  };

  handleNoukiEDateChange = (date) => {
    this.setState({
      noukiEDate: date
    });
  };

  handleOkurijyoNoChange = (e) => {
    this.setState({
      okurijyoNo: e.currentTarget.value
    });
  };

  handleNoukiDateUpdate = (e) => {
    let esId = e.currentTarget.dataset.id;

    let ordersRef = this.props.db.collection('orders').doc(esId);

    ordersRef.get()
    .then((doc) => {
      if(doc.exists) {
        const data = doc.data();
        let nouki = [];
        if(data.nouki){
          nouki = nouki.concat(data.nouki);
        }
        nouki.push(
          {
            sdate: this.state.noukiSDate,
            edate: this.state.noukiEDate,
            created: firebase.firestore.Timestamp.fromDate(new Date()),
            deliveryDate: firebase.firestore.Timestamp.fromDate(new Date()),
            latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
            user: this.props.user.id,
          }
        )
        ordersRef.update({
          nouki: nouki
        })
      }
    })

  }

  handleOkurijyoNoUpdate = (e) => {
    let esId = e.currentTarget.dataset.id;

    let ordersRef = this.props.db.collection('orders').doc(esId);

    ordersRef.get()
    .then((doc) => {
      if(doc.exists) {
        const data = doc.data();
        let okurijyoNo = [];
        if(data.okurijyoNo){
          okurijyoNo = okurijyoNo.concat(data.okurijyoNo);
        }
        okurijyoNo.push(
          {
            value: this.state.okurijyoNo,
            created: firebase.firestore.Timestamp.fromDate(new Date()),
            shippingDate: firebase.firestore.Timestamp.fromDate(new Date()),
            latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
            user: this.props.user.id,
          }
        )
        ordersRef.update({
          okurijyoNo: okurijyoNo
        })
      }
    })
  }

  noukiModalClose = (e) => {
    this.setState({
      noukiModal: false,
    });
  }
  syukkaModalClose = (e) => {
    this.setState({
      syukkaModal: false,
    });
  }

  pdfSave = () => {

    // const input = document.getElementById('divToPrint');
    html2canvas(this.selectorRef.current, {scale: 2})
        .then((canvas) => {

          var imgData = canvas.toDataURL('image/png');
          var imgWidth = 210;
          var pageHeight = 295;
          var pageInnerHeight = 275;
          var imgHeight = canvas.height * imgWidth / canvas.width;
          var heightLeft = imgHeight;
          var doc = new jsPDF('p', 'mm', 'a4', true);
          var position = 10; // give some top padding to first page

          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            // position += heightLeft - imgHeight; // top padding for other pages
            position = heightLeft - imgHeight + 10
            // console.log('position', position)
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          doc.save( this.state.fileNamePDF+'.pdf');

          // window.open(doc.output('bloburl'));
          // pdf.save("download.pdf");
        })
  }

  toggle = () => {
    this.setState({
      pdfModal: !this.state.pdfModal
    });
  }

  divideToggle = (e) => {
    let confirm = e && e.currentTarget.dataset.confirm;
    console.log('confirm', confirm);
    let obj = {
      divideModal: !this.state.divideModal,
    }
    // if (confirm) {
    //   obj.divideConfirmModal = true;
    // }
    this.setState(obj)
  }

  divideConfirmToggle = (e) => {
    let confirm = e && e.currentTarget.dataset.confirm;
    console.log('confirm1', confirm)
    this.setState({
      divideConfirmModal: !this.state.divideConfirmModal
    })
  }

  noukiCommentToggle = (e) => {
    let confirm = e && e.currentTarget.dataset.confirm;
    console.log('confirm2', confirm)
    if (confirm) {}
    this.setState({
      noukiCommentModal: !this.state.noukiCommentModal
    })
  }

  noukiAllToggle = (e) => {
    let confirm = e && e.currentTarget.dataset.confirm;
    // if (confirm) {}

    this.setState({
      noukiAllModal: !this.state.noukiAllModal
    })
  }

  syukkaAllToggle = (e) => {
    this.setState({
      syukkaAllModal: !this.state.syukkaAllModal
    })
  }

  syukkaCommentToggle = (e) => {
    this.setState({
      syukkaCommentModal: !this.state.syukkaCommentModal
    })
  }

  countZeroToggle = (e) => {
    this.setState({
      countZeroModal: !this.state.countZeroModal
    })
  }

  countMinusToggle = (e) => {
    this.setState({
      countMinusModal: !this.state.countMinusModal
    })
  }

  notSendingToggle = (e) => {
    let confirm = e && e.currentTarget.dataset.confirm;
    // console.log('confirm', confirm, this.state.tempItem);

    if (confirm) {
      let data = this.state.data;
      let cart = data.cart;
      let items = cart.items;
      delete items[this.state.tempIndex].okurijyoNo;
      this.setState({data}, () => {
        switch (this.state.divideType) {
          case 'divide':
            this.divide(this.state.tempItem);
            break;
          case 'divideMore':
            this.divideMore(this.state.tempItem);
            break;
          case 'divideSub':
            this.subDivide(this.state.tempItem, this.state.tempIndex);
            break;
        }
      });
    }
    this.setState({
      notSendingModal: !this.state.notSendingModal
    });
  }

  notZeroToggle = () => {
    this.setState({
      notZeroModal: !this.state.notZeroModal
    })
  }

  showPdf = async () => {
    this.setState({loadingPDF: true});

    if (!MeiryoRegular || !MeiryoBold) {
      downloadClicked = true;
      return;
    }

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    let sellerOrg = this.props.userOrg;
    let buyerOrg = this.props.org;
    let data = this.state.data;
    let cart = this.state.data.cart;
    let items = cart.items || [];
    let fileNamePDF = '';
    let deliveryAddress = {};
    let applyId = '';
    let mainInstallmentStatus = '分納手続中';

    if (data.apply) {
      applyId = Object.keys(data.apply)[0];
    }

    // console.log('data',data)

    let orderNoukiDate = '';
    if (cart.orderNouki == '日付指定') {
      orderNoukiDate = cart.orderNoukiDate ? cart.orderNoukiDate.toDate().getFullYear() + '/'
          + n(cart.orderNoukiDate.toDate().getMonth()+1) + '/'
          + n(cart.orderNoukiDate.toDate().getDate()) + '' : '';
    }

    let toUserData = await firebase.functions().httpsCallable('getUserEmail')({uid: data.toUser})

    let date;
    if (data.orderDate) {
      date = data.orderDate
    } else {
      date = data.created;
    }
    let orderDate = date.toDate().getFullYear() + '年'
        + n(date.toDate().getMonth() + 1) + '月'
        + n(date.toDate().getDate()) + '日 '
        + n(date.toDate().getHours()) + ':'
        + n(date.toDate().getMinutes());

    let nDate = date.toDate().getFullYear() + ''
        + n(date.toDate().getMonth() + 1) + ''
        + n(date.toDate().getDate()) + ''
        + n(date.toDate().getHours()) + ''
        + n(date.toDate().getMinutes()) + ''
        + n(date.toDate().getSeconds());

    let divideDate;
    let divideCount = items.filter(v => v.subDivided === true);
    if (divideCount.length > 0) {
      if (data.dividedDate) {
        divideDate = data.dividedDate;
        if (this.state.algolia) {
          divideDate = new Date(divideDate._seconds * 1000 + divideDate._nanoseconds/1000000);
        } else {
          divideDate = divideDate.toDate();
        }

        divideDate = divideDate.getFullYear() + ''
            + n(divideDate.getMonth() + 1) + ''
            + n(divideDate.getDate()) + ''
            + n(divideDate.getHours()) + ''
            + n(divideDate.getMinutes()) + ''
            + n(divideDate.getSeconds());
      }
    }

    let no = nDate+'-'+data.id;

    //divided item-g xasax
    items = items.filter(v => v.divided !== true);

    let totalAmount = 0;
    let cancelAmount = 0;
    let returnAmount = 0;
    let mainAmount = 0;
    let totalTax = 0;
    let taxes = {};
    let totals = {};
    let subDivideCount = 0;
    let subDivideConfirmCount  = 0;
    cart.items.forEach((item) => {
      if (item.subDivided) {
        subDivideCount += 1;
        if (item.divideConfirm === true) {
          subDivideConfirmCount += 1;
        }
      }
      if (!item.divided) {
        if (item.cancelConfirm) {
          cancelAmount += (item.count * item.price);
        }
        if (item.returnConfirm) {
          returnAmount += (item.count * item.price);
        }
        if (!item.returnSub) {
          mainAmount += (item.count * item.price);
        }
        totalAmount += (item.cancelConfirm === true ? 0 : item.price*item.count);
        totalTax += parseInt((item.cancelConfirm === true ? 0 : item.count * item.price * item.tax), 10);

        if (taxes[item.tax]) {
          taxes[item.tax] += (item.cancelConfirm === true ? 0 : item.count * item.price * item.tax);
          totals[item.tax] += (item.cancelConfirm === true ? 0 : item.count * item.price);
        } else {
          taxes[item.tax] = (item.cancelConfirm === true ? 0 : item.count * item.price * item.tax);
          totals[item.tax] = (item.cancelConfirm === true ? 0 : item.count * item.price);
        }
      }
    });

    if (subDivideCount === subDivideConfirmCount) {
      mainInstallmentStatus = '分納完了済';
    } else {
      mainInstallmentStatus = '分納手続中';
    }

    switch (cart.orderBasyo) {
      case '登録住所':
        deliveryAddress = {
          postCode: this.props.userOrg.zipcode,
          address1: this.props.userOrg.address,
          address2: '',
          officeName: this.props.userOrg.display_name,
          personInCharge: this.state.data['fromUserName'],
          phoneNumber: this.props.userOrg.TEL,
          propertyName: ""
        };
        break;
      case '登録住所以外':
        deliveryAddress = {
          postCode: cart.orderZipcode,
          address1: cart.orderAddr1,
          address2: cart.orderAddr2,
          officeName: cart.orderJigyosyo,
          personInCharge: cart.orderTanto,
          phoneNumber: cart.orderPhone,
          propertyName: ""
        }
        break;
      case '前回の納品先':
        deliveryAddress = cart.deliveryAddress;
        break;
      case 'アドレス帳から選択する':
        deliveryAddress = cart.deliveryAddress;
        break;
      case '販売側からお助けカートで指定された納品先':
        deliveryAddress = cart.otasukeDeliveryAddress;
        break;
      case '納品先を直接入力する':
        deliveryAddress = {
          postCode: cart.orderZipcode,
          address1: cart.orderAddr1,
          address2: cart.orderAddr2,
          officeName: cart.orderJigyosyo,
          personInCharge: cart.orderTanto,
          phoneNumber: cart.orderPhone,
          propertyName: cart.orderPropertyName
        }
        break;

    }

    // let sAddress = deliveryAddress.propertyName + ' ' + deliveryAddress.address1 + ' ' + deliveryAddress.address2 + ' ' + deliveryAddress.officeName;

    fileNamePDF = 'ORD-'+nDate+'-'+data.id;

    let dataPDF = {
      sellerZipcode: sellerOrg.zipcode,
      sellerAddress: sellerOrg.address,
      sellerFAX: sellerOrg.FAX,
      sellerTEL: sellerOrg.TEL,
      sellerMAIL: toUserData.data,
      sellerOrganizationsCompanyName: sellerOrg.company_name,
      sellerOrganizationsName: sellerOrg.name,

      buyerZipcode: buyerOrg.zipcode,
      buyerAddress: buyerOrg.address,
      buyerFAX: buyerOrg.FAX,
      buyerTEL: buyerOrg.TEL,
      buyerOrganizationsCompanyName: buyerOrg.company_name,
      buyerOrganizationsName: buyerOrg.name,

      toUserName: data.toUserName,
      fromUserName: data.fromUserName,
      applyUserName: data.applyUserName,
      applyId: applyId,

      orderNo: cart.orderNo,
      deliveryAddress: deliveryAddress,
      orderDate: orderDate,
      orderNouki: cart.orderNouki,
      orderNoukiDay: cart.orderNoukiDay,
      orderNoukiDate: orderNoukiDate,
      orderBikou: cart.orderBikou,
      mainInstallmentStatus: mainInstallmentStatus,
      no: no,
      items: items,
      totalAmount: totalAmount,
      cancelAmount: cancelAmount,
      returnAmount: returnAmount,
      mainAmount: mainAmount,
      totalTax: totalTax,
      taxes: taxes,
      totals: totals,
      divideDate: divideDate
    }

    this.setState({dataPDF: dataPDF, pdfModal: true, fileNamePDF, loadingPDF: false}, () => {
      this.pdfGenerator(fileNamePDF, dataPDF, false);
    });
  }

  checkDividedItemShipped = (item) => {
    let data = this.state.data;
    let cart = data.cart;
    let items = cart.items;
    let count = item.count;

    let shipped = false;

    for (let i=0; i<items.length; i++) {
      if (item.id === items[i].id && items[i].subDivided === true) {
        // let okurijyoStatus = 'unShipped';
        if(items[i].okurijyoNo){
          let okurijyoNo = items[i].okurijyoNo.slice(-1)[0].value;
          let hassouDate = items[i].okurijyoNo.slice(-1)[0].hassouDate ? items[i].okurijyoNo.slice(-1)[0].hassouDate.toDate() : '';
          let shippingDate = items[i].okurijyoNo.slice(-1)[0].shippingDate;

          if (okurijyoNo && hassouDate && shippingDate) {
            // okurijyoStatus = 'shipped';
            shipped = true;
          }
        }
      }
    }

    return shipped;
  }

  cancelDivide = (item) => {
    let data = this.state.data;
    let cart = data.cart;
    let items = cart.items;

    let tItems = [];
    let withComment = false;

    for (let i=0; i<items.length; i++) {
      let tItem = items[i];
      if (tItem.id === item.id && tItem.divided) {
        delete tItem.divided;
      }
      if (!(tItem.id === item.id && tItem.subDivided)) {
        tItems.push(tItem);
      }
      // if (tItem.okurijyoComment || tItem.noukiComment) {
      //   withComment = true;
      // }
    }

    for (let i1=0; i1<tItems.length; i1++) {
      if (tItems[i1].okurijyoComment || tItems[i1].noukiComment) {
        withComment = true;
      }
    }

    cart.items = tItems;
    delete data.divideDate;
    delete data.divideConfirm;

    data.divideCancelBy = this.props.user.id;
    data.divideCancelByName = this.props.user.last_name + ' '+ this.props.user.first_name;
    data.divideCancelDate = firebase.firestore.Timestamp.fromDate(new Date());

    if (withComment === false) {
      delete data.readComment;
    }

    data.changeDate = new Date().getTime();

    this.setState({data, showMenu: null, showMenuIndex: null}, () => {
      let ordersRef = this.props.db.collection('orders').doc(data.id);
      ordersRef.set(data);
    })

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    let orderDate = '';
    if (data.orderDate) {
      orderDate = data['orderDate'].toDate().getFullYear() + '/'
          + n(data['orderDate'].toDate().getMonth()+1) + '/'
          + n(data['orderDate'].toDate().getDate()) + ' '
          + n(data['orderDate'].toDate().getHours()) + ':'
          + n(data['orderDate'].toDate().getMinutes());
    }

    let orderId = data.id;

    firebase.functions().httpsCallable('sendDivideCancelEmail')({fromUser: data.fromUser, orderDate: orderDate, itemName: item.name, toOrganization: data.toOrganization, orderId});
  }

  deleteDividedItem = (item, index) => {
    let data = this.state.data;
    let cart = data.cart;
    let items = cart.items;

    let added = false;
    let withComment = false;
    items = items.filter((v,i) => i !== index)
    for (let i=0; i<items.length; i++) {
      let tItem = items[i];
      // console.log('tItem', tItem)
      let okurijyoNoShipped = false;
      if (tItem.okurijyoNo) {
        let latestOkurijyoNo = tItem.okurijyoNo[tItem.okurijyoNo.length-1];
        if (latestOkurijyoNo.user) {
          okurijyoNoShipped = true;
        }
      }
      if (tItem.id === item.id && tItem.subDivided && !tItem.divideConfirm && !added && !okurijyoNoShipped) {
        added = true;
        tItem.count += item.count;
        // console.log('tItem', tItem)
      }

      if (tItem.okurijyoComment || tItem.noukiComment) {
        withComment = true;
      }
    }

    cart.items = items;

    if (withComment === false) {
      delete data.readComment;
    }

    data.changeDate = new Date().getTime();

    this.setState({data, showMenu: null, showMenuIndex: null}, () => {
      let ordersRef = this.props.db.collection('orders').doc(data.id);
      ordersRef.set(data);
    });
  }

  divide = (item) => {
    let data = this.state.data;
    let cart = data.cart;
    let items = cart.items;
    let count = item.count;

    let index = items.findIndex(v => v.id === item.id);

    let dividedDate = firebase.firestore.Timestamp.fromDate(new Date());
    let dividedBy = this.props.user.id;
    let dividedByName = this.props.user.last_name + ' '+ this.props.user.first_name;
    item.divided = true;
    item.divideConfirm = false;
    item.dividedBy = dividedBy;

    delete item.nouki;
    delete item.noukiComment;

    let tempItem = {...item};
    tempItem.count = 1;
    tempItem.mainCount = count;
    tempItem.subDivided = true;
    tempItem.mainSubDivided = true;
    tempItem.divideConfirm = false;
    tempItem.dividedDate = dividedDate;
    tempItem.dividedBy = dividedBy;
    tempItem.dividedByName = dividedByName;

    delete tempItem.divided;
    items.splice(index+1, 0, tempItem);

    tempItem = {...item};
    tempItem.count = (count - 1);
    tempItem.mainCount = count;
    tempItem.subDivided = true;
    tempItem.mainSubDivided = true;
    tempItem.divideConfirm = false;
    tempItem.dividedDate = dividedDate;
    tempItem.dividedBy = dividedBy;
    tempItem.dividedByName = dividedByName;

    delete tempItem.divided;
    items.splice(index+2, 0, tempItem);

    let dateTime = new Date().getTime();

    data.divideDate = dividedDate;
    data.dividedDate = dividedDate;
    data.dividedBy = dividedBy;
    data.dividedByName = dividedByName;
    data.divideConfirm = false;
    data.changeDate = dateTime;

    this.setState({data, divideItem: item, divideModal: true, showMenu: null, showMenuIndex: null,}, () => {
      let ordersRef = this.props.db.collection('orders').doc(data.id);
      ordersRef.set({
        cart: data.cart,
        divideDate: dividedDate,
        dividedDate: dividedDate,
        dividedBy: dividedBy,
        dividedByName: dividedByName,
        divideConfirm: false,
        changeDate: dateTime
      }, { merge: true });
    });

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    let orderDate = '';
    if (data.orderDate) {
      orderDate = data['orderDate'].toDate().getFullYear() + '/'
          + n(data['orderDate'].toDate().getMonth()+1) + '/'
          + n(data['orderDate'].toDate().getDate()) + ' '
          + n(data['orderDate'].toDate().getHours()) + ':'
          + n(data['orderDate'].toDate().getMinutes());
    }
    let orderId = data.id;

    firebase.functions().httpsCallable('sendDivideEmail')({fromUser: data.fromUser, orderDate: orderDate, itemName: item.name, toOrganization: data.toOrganization, orderId});
  }

  divideMore = (item) => {
    let data = this.state.data;
    let cart = data.cart;
    let items = cart.items;
    let count = item.count;
    let dividedDate = firebase.firestore.Timestamp.fromDate(new Date());
    let dividedBy = this.props.user.id;
    let dividedByName = this.props.user.last_name + ' '+ this.props.user.first_name;
    let lastIndex = 0;

    let index = items.findIndex(v => v.id === item.id);

    for (let i=0; i<items.length; i++) {
      if (items[i].id === item.id) {
        lastIndex = i;
      }
    }

    let minused = false;
    let subCount = 0;
    let mainCount = 0;
    let possibleDivide = false;
    for (let i=0; i<items.length; i++) {
      let subItem = items[i];
      if (item.id === subItem.id && subItem.divided) {
        mainCount = subItem.count;
      }
      if (item.id === subItem.id && subItem.subDivided && subItem.count > 1) {
        possibleDivide = true;
      }
      if (item.id === subItem.id && subItem.subDivided) {
        subCount += subItem.count;
      }

      let okurijyoNoShipped = false;
      if (subItem.okurijyoNo) {
        let latestOkurijyoNo = subItem.okurijyoNo[subItem.okurijyoNo.length-1];
        if (latestOkurijyoNo.user) {
          okurijyoNoShipped = true;
        }
      }

      if (minused === false && subItem.subDivided && subItem.id === item.id && subItem.count > 1 && !okurijyoNoShipped) {
        subItem.count -= 1;
        minused = true;
      }
    }

    let tempCount = 1;
    if (mainCount <= subCount && possibleDivide === false) {
      tempCount = 0;
    }

    let tempItem = {...item};
    tempItem.count = tempCount;
    tempItem.mainCount = count;
    tempItem.subDivided = true;
    tempItem.divideConfirm = false;
    tempItem.dividedDate = dividedDate;
    tempItem.dividedBy = dividedBy;
    tempItem.dividedByName = dividedByName;
    delete tempItem.divided;
    items.splice(lastIndex+1, 0, tempItem);

    let dateTime = new Date().getTime();

    data.divideDate = dividedDate;
    data.dividedDate = dividedDate;
    data.dividedBy = dividedBy;
    data.dividedByName = dividedByName;
    data.divideConfirm = false;
    data.changeDate = dateTime;

    this.setState({data, showMenu: null, showMenuIndex: null}, () => {
      let ordersRef = this.props.db.collection('orders').doc(data.id);
      ordersRef.set({
        cart: data.cart,
        divideDate: dividedDate,
        dividedDate: dividedDate,
        dividedBy: dividedBy,
        dividedByName: dividedByName,
        divideConfirm: false,
        changeDate: dateTime
      }, { merge: true });
    }) //divideModal: true,
  }

  subDivide = (item, index) => {
    let data = this.state.data;
    let cart = data.cart;
    let items = cart.items;
    let spliceIndex = index;
    let splicePossible = false;

    // console.log('spliceIndex', spliceIndex);

    let dividedDate = firebase.firestore.Timestamp.fromDate(new Date());
    let dividedBy = this.props.user.id;
    let dividedByName = this.props.user.last_name + ' '+ this.props.user.first_name;

    // console.log('spliceIndex', spliceIndex)

    let changedSplice = false;

    while (!splicePossible) {
      if (items[spliceIndex] && items[spliceIndex].mainSubDivided) {
        spliceIndex+=1;
        changedSplice = true;
      } else {
        splicePossible = true
      }
      // console.log('while')
    }

    // console.log('splicePossible', items, splicePossible, spliceIndex)

    if (!changedSplice) {
      spliceIndex+=1;
    }

    items[index].count -= 1;
    items[index].divideConfirm = false;

    let tempItem = {...item};
    tempItem.count = 1;
    tempItem.mainCount = item.mainCount;
    tempItem.subDivided = true;
    tempItem.divideConfirm = false;
    tempItem.dividedDate = dividedDate;
    tempItem.dividedBy = dividedBy;
    tempItem.dividedByName = dividedByName;
    delete tempItem.divided;
    delete tempItem.mainSubDivided;

    items.splice(spliceIndex, 0, tempItem);

    data.changeDate = new Date().getTime();

    this.setState({data, showMenu: null, showMenuIndex: null}, () => {
      let ordersRef = this.props.db.collection('orders').doc(data.id);
      ordersRef.set(data, {merge: true})
    });
  }

  checkDividedAllItemCount = () => {
    let data = this.state.data;
    let cart = data.cart;
    let items = cart.items;

    let count = 0;
    let mainCount = 0;

    for (let i=0; i<items.length; i++) {
      let subItem = items[i];
      if (subItem.subDivided) {
        count += subItem.count;
      }
      if (subItem.divided) {
        mainCount += subItem.count;
      }
    }

    return mainCount !== count
  }

  checkDividedItemCount = (item, num) => {
    let data = this.state.data;
    let cart = data.cart;
    let items = cart.items;

    let count = 0;
    let mainCount = 0;
    for (let i=0; i<items.length; i++) {
      let subItem = items[i];
      if (subItem.id === item.id && subItem.subDivided && !subItem.returnConfirm) {
        count += subItem.count;
      }
      if (subItem.id === item.id && subItem.divided) {
        mainCount = subItem.count;
      }
    }
    // console.log('count', count, '/', mainCount);

    return mainCount !== count
  }

  checkAllConfirmed = (item) => {
    let data = this.state.data;
    let cart = data.cart;
    let items = cart.items;

    let allConfirmed = true;
    for (let i=0; i<items.length; i++) {
      if (items[i].id === item.id && items[i].subDivided && items[i].divideConfirm === false) {
        allConfirmed = false;
      }
    }

    return allConfirmed
  }

  checkOneConfirmed = (item) => {
    let data = this.state.data;
    let cart = data.cart;
    let items = cart.items;

    let confirm = false;
    for (let i=0; i<items.length; i++) {
      if (items[i].id === item.id && items[i].subDivided && items[i].divideConfirm === true) {
        confirm = true;
      }
    }

    return confirm;
  }

  checkMaxDivided = (item) => {
    let data = this.state.data;
    let cart = data.cart;
    let items = cart.items;

    let disable = false;
    let count = 0;
    let mainCount = 0;
    for (let i=0; i<items.length; i++) {
      let subItem = items[i];
      // if (subItem.id === item.id && subItem.subDivided && subItem.count > 1) {
      //   disable = false
      // }
      if (subItem.id === item.id && subItem.subDivided) {
        count += subItem.count;
        mainCount = subItem.mainCount;
      }
    }

    if (mainCount !== count) {
      disable = true;
    }

    return disable
  }

  showMenu = (itemId, index) => {
    let obj;
    if (itemId === this.state.showMenu && index === this.state.showMenuIndex) {
      obj = {
        showMenu: null,
        showMenuIndex: null
      }
    } else {
      obj = {
        showMenu: itemId,
        showMenuIndex: index
      }
    }

    this.setState({showMenu: obj.showMenu, showMenuIndex: obj.showMenuIndex})
  }

  confirmDivide = () => {
    let data = this.state.data;
    let cart = data.cart;
    let items = cart.items;

    data.divideConfirm = true;

    for (let i=0; i<items.length; i++) {
      if (items[i].divideConfirm === false) {
        items[i].divideConfirm = true;
      }
    }

    data.changeDate = new Date().getTime();

    this.setState({data, divideConfirmModal: true}, () => {
      let ordersRef = this.props.db.collection('orders').doc(data.id);
      ordersRef.set(data, { merge: true });
    })
  }

  confirmSubDivide = (item, index) => {
    let data = this.state.data;
    let cart = data.cart;
    let items = cart.items;

    if (item.count === 0) {
      this.setState({countZeroModal: true, showMenu: null, showMenuIndex: null});
      return;
    } else if (item.count < 0) {
      this.setState({countMinusModal: true, showMenu: null, showMenuIndex: null});
      return;
    }

    items[index].divideConfirm = true;

    let confirmAll = true;
    for (let i=0; i<items.length; i++) {
      let subItem = items[i];

      if (subItem.id === item.id && subItem.subDivided && subItem.divideConfirm === false) {
        confirmAll = false;
      }
    }

    if (confirmAll) {
      function n(n){
        return n > 9 ? "" + n: "0" + n;
      }

      let orderDate = '';
      if (data.orderDate) {
        orderDate = data['orderDate'].toDate().getFullYear() + '/'
            + n(data['orderDate'].toDate().getMonth()+1) + '/'
            + n(data['orderDate'].toDate().getDate()) + ' '
            + n(data['orderDate'].toDate().getHours()) + ':'
            + n(data['orderDate'].toDate().getMinutes());
      }
      let orderId = data.id;

      firebase.functions().httpsCallable('sendDivideConfirmEmail')({fromUser: data.fromUser, orderDate: orderDate, itemName: item.name, toOrganization: data.toOrganization, orderId});
    }

    data.changeDate = new Date().getTime();

    this.setState({ data, divideItem: item, showMenu: null, showMenuIndex: null, divideConfirmModal: true }, () => {
      let ordersRef = this.props.db.collection('orders').doc(data.id);
      ordersRef.set(data, { merge: true });
    });

  }

  cancelMainDivide = () => {
    let data = this.state.data;
    let cart = data.cart;
    let items = cart.items;

    let tItems = [];
    let unConfirmId = null;

    for (let i=0; i<items.length; i++) {
      let tItem = items[i];
      if (tItem.divided && tItem.divideConfirm === false) {
        unConfirmId = tItem.id;
        delete tItem.divided;
        delete tItem.divideConfirm;
      }

      if (!(unConfirmId === tItem.id && tItem.subDivided)) {
        tItems.push(tItem)
      }
    }

    data.cart.items = tItems;
    delete data.divideDate
    delete data.divideConfirm;
    data.divideCancelBy = this.props.user.id;
    data.divideCancelByName = this.props.user.last_name + ' '+ this.props.user.first_name;
    data.divideCancelDate = firebase.firestore.Timestamp.fromDate(new Date());

    // console.log('data',data, tItems)

    data.changeDate = new Date().getTime();

    this.setState({data}, () => {
      let ordersRef = this.props.db.collection('orders').doc(data.id);
      ordersRef.set(data);
    });
  }

  addFooters = (doc, data) => {
    const pageCount = doc.internal.getNumberOfPages()

    doc.setFont('Meiryo', 'normal')
    doc.setFontSize(8)
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text('Page ' + String(i) + '/' + String(pageCount), 15, 15);

      if (i > 1) {
        doc.text(`発注日 ${data.orderDate}\nNo.${data.no}`, 130, 15);
      }
    }
  }

  pdfGenerator = (fileName, data, download) => {
    let doc = new jsPDF('p', 'mm', 'a4', true);
    let top = 20;
    doc.addFileToVFS("MeiryoRegular.ttf", MeiryoRegular);
    doc.addFileToVFS("MeiryoBold.ttf", MeiryoBold);
    doc.addFont("MeiryoRegular.ttf", "Meiryo", "normal");
    doc.addFont("MeiryoBold.ttf", "Meiryo", "bold");
    doc.setFont("Meiryo", 'normal');
    doc.setFontSize(8);
    doc.setFontSize(25);
    doc.text('発注書', 90, top);
    doc.setFontSize(8);

    if (data.divideDate) {
      doc.text(`（分納版 - ${data.divideDate})`, 195, 15, {align: 'right'});
      doc.text(`${data.mainInstallmentStatus}`, 179.5, 19);
      doc.roundedRect(178, 16.4, 17, 3.2, 0.5, 0.5);
    }

    doc.text(`〒${data.sellerZipcode}`, 15, top+20);
    doc.text(`${data.sellerAddress}`, 15, top+25);

    doc.text(`発注日 ${data.orderDate}`, 125, top+20);
    doc.text(`No.${data.no}`, 125, top+25);

    doc.setFont('Meiryo', 'bold');
    doc.text(`${data.sellerOrganizationsCompanyName} ${data.sellerOrganizationsName}`, 17, top+30);
    doc.text('御中', 110, top+30);
    doc.line(15, top+31, 110, top+31);

    doc.setFont('Meiryo', 'normal');
    doc.text(`${data.toUserName} 様`, 15, top+37);
    doc.text(`〒${data.buyerZipcode}`, 125, top+37);
    doc.text(`${data.buyerAddress}`, 125, top+42);
    doc.text(`${data.buyerOrganizationsCompanyName} ${data.buyerOrganizationsName}`, 125, top+50);
    doc.text(`TEL: ${data.buyerTEL}`, 125, top+55);
    doc.text(`FAX: ${data.buyerFAX}`, 170, top+55);
    doc.setFontSize(6);
    doc.text(`TEL: ${data.sellerTEL}`, 15, top+40);
    doc.text(`FAX: ${data.sellerFAX}`, 40, top+40);
    doc.text(`Mail: ${data.sellerMAIL}`, 15, top+43);
    doc.setFontSize(8);
    doc.text('以下の通り発注いたします。', 15, top+53);

    doc.text('〔本書に関するお問い合わせ〕', 125, top+63);
    let fromUserName = doc.splitTextToSize(`発注者 ${data.buyerOrganizationsCompanyName} ${data.buyerOrganizationsName} ${data.fromUserName}`, 70);
    let applyUserName = doc.splitTextToSize(`承認者 ${data.buyerOrganizationsCompanyName} ${data.buyerOrganizationsName} ${data.applyUserName || data.applyId}`, 70)
    doc.text(fromUserName, 125, top+68);
    doc.text(applyUserName, 125, top+78);

    top = top+63

    doc.text('発注番号:', 15, top);
    let text = `${data.orderNo}`.trim();
    let da = doc.splitTextToSize(text, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines = da.length;
    doc.text(da, 40, top);
    if (amountOfLines > 1) {
      top += doc.getTextDimensions(da)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('納品場所:', 15, top);
    let text1 = `〒${data.deliveryAddress.postCode}`.trim();
    let da1 = doc.splitTextToSize(text1, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines1 = da1.length;
    doc.text(da1, 40, top);
    if (amountOfLines1 > 1) {
      top += doc.getTextDimensions(da1)['h']-2;
    } else {
      top += 3;
    }

    let text2 = `${data.deliveryAddress.propertyName} ${data.deliveryAddress.address1} ${data.deliveryAddress.address2} ${data.deliveryAddress.officeName}`.trim();
    let da2 = doc.splitTextToSize(text2, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines2 = da2.length;
    doc.text(da2, 40, top);
    if (amountOfLines2 > 1) {
      top += doc.getTextDimensions(da2)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('受取担当者:', 15, top);
    let text3 = `${data.deliveryAddress.personInCharge} ${data.deliveryAddress.personInCharge && '様'}`.trim();
    let da3 = doc.splitTextToSize(text3, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines3 = da3.length;
    doc.text(da3, 40, top);
    if (amountOfLines3 > 1) {
      top += doc.getTextDimensions(da3)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('電話番号:', 15, top);
    let text4 = `${data.deliveryAddress.phoneNumber}`.trim();
    let da4 = doc.splitTextToSize(text4, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines4 = da4.length;
    doc.text(da4, 40, top);
    if (amountOfLines4 > 1) {
      top += doc.getTextDimensions(da4)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('希望納期:', 15, top);
    let orderNouki = data['orderNouki'] === '最短' ? '最短' : data['orderNouki'] === '希望' ? `${data['orderNoukiDay']}日` : `${data['orderNoukiDate']}`
    let da5 = doc.splitTextToSize(`${orderNouki}`, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines5 = da5.length;
    doc.text(da5, 40, top);
    if (amountOfLines5 > 1) {
      top += doc.getTextDimensions(da5)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    autoTable(doc, {
      startY: top+8,
      head: [['税抜合計金額', 'キャンセル', '返品', '消費税額計', '今回発注額']],
      body: [
        [
          `${data.mainAmount.toLocaleString()}円`,
          `${data.cancelAmount ? '-' : ''}${data.cancelAmount.toLocaleString()}円`,
          `${data.returnAmount.toLocaleString()}円`,
          `${parseInt((Object.values(data.taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10).toLocaleString()}円`,
          `${(data.totalAmount + parseInt((Object.values(data.taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10)).toLocaleString()}円`],
      ],
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 27, halign: 'right' },
        1: { cellWidth: 27, halign: 'right' },
        2: { cellWidth: 27, halign: 'right' },
        3: { cellWidth: 27, halign: 'right' },
        4: { cellWidth: 27, halign: 'right' }
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain'
    });

    let posY = doc.lastAutoTable.finalY;
    // console.log('posY', posY);

    autoTable(doc, {
      startY: posY+8,
      head: [['課税対象額(10%)', '消費税(10%)', '課税対象額(8%)', '消費税(8%)']],
      body: [
        [
          `${(data.totals['0.1'] || 0).toLocaleString()}円`,
          `${parseInt((data.taxes['0.1'] || 0), 10).toLocaleString()}円`,
          `${(data.totals['0.08'] || 0).toLocaleString()}円`,
          `${parseInt((data.taxes['0.08'] || 0), 10).toLocaleString()}円`
        ],
      ],
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 33, halign: 'right' },
        1: { cellWidth: 34, halign: 'right' },
        2: { cellWidth: 34, halign: 'right' },
        3: { cellWidth: 34, halign: 'right' }
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain'
    });

    posY = doc.lastAutoTable.finalY;

    autoTable(doc, {
      startY: posY+8,
      head: [['備考', 'orderBikou']],
      showHead: false,
      body: [
        ['備考', `${data['orderBikou'] ? data['orderBikou'] : ''}`],
      ],
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 15, minCellHeight: 20, halign: 'center', valign: 'middle', fillColor: [189, 190, 191] },
        1: { cellWidth: 'auto', halign: 'left', valign: 'middle' },
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain'
    });

    posY = doc.lastAutoTable.finalY;

    const substringFullWidth = (str, limit) => {
      var l = str.length;
      var c = '';
      var res = '';
      var length = 0;
      for (var i = 0; i < l; i++) {
        c = str.charCodeAt(i);
        if (0x0000 <= c && c <= 0x0019) {
          // length 0
          // do nothing
        } else if (0x0020 <= c && c <= 0x1FFF) {
          // length 1
          length = length + 1;
        } else if (0x2000 <= c && c <= 0xFF60) {
          // length 2
          length = length + 2;
        } else if (0xFF61 <= c && c <= 0xFF9F) {
          // length 1
          length = length + 1;
        } else if (0xFFA0 <= c) {
          // length 2
          length = length + 2;
        }
        if (length <= limit) {
          res += str.charAt(i);
        }
        else {
          break;
        }
      }
      return res;
    }

    let no = 0;
    let subNum = 0;
    let subId = '';
    let itemDataBody = data.items.map((item, index) => {
      if (subId !== item.id) {
        subId = item.id;
        if (!item.returnSub) {
          no += 1;
        }
        if (item.subDivided) {
          subNum = 1;
        } else {
          subNum = 0;
        }
      } else {
        if (item.subDivided) {
          if (!item.returnSub) {
            subNum += 1;
          }
        } else {
          subNum = 0;
        }
      }

      let confirmStatus = '';
      if (item.subDivided) {
        confirmStatus = `分納手続中`;
        if (item.divideConfirm) {
          confirmStatus = `分納`;
        }
      }

      let cancelConfirmStatus = '';
      if (item.cancelConfirm) {
        cancelConfirmStatus = `キャンセル`;
      }

      let returnConfirmStatus = '';
      if (item.returnConfirm) {
        returnConfirmStatus = `返品`;
      }

      return [
        `${no}${item.returnSub ? '-1':''}${subNum > 0 ? `\n(${subNum}${item.returnSub ? '-1':''})` : ''}`,
        `${item.code ? item.code : ''}${item.subDivided ? `\n${confirmStatus}` : ''}${item.cancelConfirm ? `\n${cancelConfirmStatus}` : ''}${item.returnConfirm ? `\n${returnConfirmStatus}` : ''}`,
        `${item.maker}\n${substringFullWidth(item.name,156)}`,
        `${item.price.toLocaleString()}\n${item.count.toLocaleString()}`,
        `${(item.count * item.price).toLocaleString()}\n${item.tax*100}%`,
        // `${item.description ? substringFullWidth(item.description,87).trim() : ''}`
        ``
      ]
    });

    autoTable(doc, {
      startY: posY+8,
      margin: {top: 20},
      rowPageBreak: 'avoid',
      head: [['No.', '商品コード', 'メーカー名\n商品名', '単価(円)\n数量', '合計金額(円)\n税率', '備考']],
      body: itemDataBody,
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 10, halign: 'center', valign: 'middle' },
        1: { cellWidth: 20, halign: 'left', valign: 'middle' },
        2: { cellWidth: 'auto', halign: 'left', valign: 'middle' },
        3: { cellWidth: 15, halign: 'right', valign: 'middle' },
        4: { cellWidth: 25, halign: 'right', valign: 'middle' },
        5: { cellWidth: 35, halign: 'left', valign: 'middle', minCellHeight: 15 }
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain',
      didDrawCell: (data) => {
        if (data.section === 'body' && data.column.index === 1) {
          // console.log('celltext', data.cell.text)
          if ((data.cell.text[1] === '分納手続中' || data.cell.text[1] === '分納') || (data.cell.text[2] === '分納手続中' || data.cell.text[2] === '分納') || (data.cell.text[3] === '分納手続中' || data.cell.text[3] === '分納')) {
            let w = 16.6;
            if (data.cell.text[1] === '分納' || data.cell.text[2] === '分納' || data.cell.text[3] === '分納') {
              w = 8.5;
            }
            doc.roundedRect(data.cell.x+0.5, data.cell.y+data.cell.contentHeight+(data.cell.height-data.cell.contentHeight)/data.cell.text.length-(5.3 * (data.cell.text.length > 2 ? 1.6 : 1)), w, 3.2, 0.5, 0.5);
          }
          if ((data.cell.text[1] === 'キャンセル' || data.cell.text[1] === '返品') || (data.cell.text[2] === 'キャンセル' || data.cell.text[2] === '返品') || (data.cell.text[3] === 'キャンセル' || data.cell.text[3] === '返品')) {
            let w = 16.6;
            let text = 'キャンセル';
            if (data.cell.text[1] === '返品' || data.cell.text[2] === '返品' || data.cell.text[3] === '返品') {
              w = 8.5;
              text = '返品';
            }
            doc.roundedRect(data.cell.x+0.5, data.cell.y+data.cell.contentHeight+(data.cell.height-data.cell.contentHeight)/2-5.3, w, 3.2, 0.5, 0.5, 'F');
            doc.setTextColor('#fff');
            doc.setFontSize(8);
            doc.text(text, data.cell.x+2, data.cell.y+data.cell.contentHeight+(data.cell.height-data.cell.contentHeight)/2-2.6);
          }
        }
      },
      willDrawCell: (d) => {
        if (d.section === 'body' && d.column.index === 5) {
          let desc = data.items[d.row.index]['description'] || '';
          let a =  doc.splitTextToSize(desc, 32);
          d.cell.text = a.slice(0,4);
        }
      }
    });

    this.addFooters(doc, data);

    this.setState({loadingPDF: false});

    if (download) {
      doc.save(`${fileName}.pdf`)
    } else {
      doc.setProperties({title: this.state.fileNamePDF});
      setTimeout(() => {
        document.getElementById("showPDF").data = doc.output('datauristring');
      }, 10);
    }
  }

  returnModalOpen = (index) => {
    this.setState({
      returnModal: true,
      returnIndex: index,
    });
  }

  returnClose = async (e) => {
    let index = e && e.currentTarget.dataset.index;
    this.setState({
      returnModal: false,
      // returnId: null,
    });

    if (index !== undefined) {
      try {
        let data = this.state.data;
        let cart = this.state.data.cart;
        let items = cart.items || [];
        index *= 1;

        let returnConfirmDate = firebase.firestore.Timestamp.now();
        let returnConfirmBy = this.props.user.id;
        let returnConfirmByUser = this.props.user.last_name + ' '+ this.props.user.first_name;

        let item = items[index];
        let newIndex = index+1;

        items.splice(index+1, 0, { ..._.cloneDeep(item), returnConfirmDate, returnConfirmBy, returnConfirmByUser, returnSub: true, returnConfirm: true, price: -item.price });
        delete item.returnConfirmDate;
        delete item.returnConfirmBy;
        delete item.returnConfirmByUser;
        delete item.returnConfirm;
        item.returnMain = true;

        let ordersRef = this.props.db.collection('orders').doc(data.id);

        let obj = {
          cart: data.cart,
          returnConfirmDate: returnConfirmDate,
          returnConfirmBy: returnConfirmBy,
          returnConfirmByUser: returnConfirmByUser,
          latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        }

        let allConfirm = true;
        if (items.some(e => e.returnConfirm === false && e.returnReject === false)) {
          allConfirm = false;
        }

        obj.returnConfirmed = true;
        if (allConfirm) {
          obj.returnWait = false;
        }

        let shippedItemCount = 0;
        let possibleShippedItemCount = 0;
        for (let i=0; i<items.length; i++) {
          let itemTemp = items[i];
          if (!itemTemp.divided && !itemTemp.cancelConfirm && !itemTemp.returnMain && !itemTemp.returnConfirm) {
            possibleShippedItemCount += 1;
          }
          if (itemTemp.okurijyoNo) {
            let okurijyoNo = itemTemp.okurijyoNo.slice(-1)[0].value;
            let hassouDate = itemTemp.okurijyoNo.slice(-1)[0].hassouDate ? itemTemp.okurijyoNo.slice(-1)[0].hassouDate.toDate() : '';
            if (okurijyoNo && hassouDate) {
              shippedItemCount++;
            }
          }
        }

        // console.log(items, shippedItemCount, possibleShippedItemCount)

        if (shippedItemCount >= possibleShippedItemCount) {
          obj.isShipped = true
        }

        obj.changeDate = new Date().getTime();

        data = {...data, ..._.cloneDeep(obj)};

        // console.log('data', data)

        this.setState({data}, () => {
          ordersRef.set(obj, { merge: true });
        });

        //TODO: send email return confirm
        function n(n){
          return n > 9 ? "" + n: "0" + n;
        }

        let returnConfirmDateFormatted = '';
        if (returnConfirmDate) {
          returnConfirmDateFormatted = returnConfirmDate.toDate().getFullYear() + '/'
              + n(returnConfirmDate.toDate().getMonth()+1) + '/'
              + n(returnConfirmDate.toDate().getDate()) + ' '
              + n(returnConfirmDate.toDate().getHours()) + ':'
              + n(returnConfirmDate.toDate().getMinutes());
        }

        let returnDateFormatted = '';
        if (item.returnDate) {
          returnDateFormatted = item.returnDate.toDate().getFullYear() + '/'
              + n(item.returnDate.toDate().getMonth()+1) + '/'
              + n(item.returnDate.toDate().getDate()) + ' '
              + n(item.returnDate.toDate().getHours()) + ':'
              + n(item.returnDate.toDate().getMinutes());
        }

        let apply = Object.keys(data.apply)
        let orderId = data.id;
        await firebase.functions().httpsCallable('sendReturnConfirmEmail')({
          fromUser: data.fromUser,
          orderNo: data.cart.orderNo,
          returnDate: returnDateFormatted,
          returnBy: item.returnBy,
          returnByUser: item.returnByUser,
          returnConfirmDate: returnConfirmDateFormatted,
          returnConfirmByUser: returnConfirmByUser,
          apply: apply,
          toUser: data.toUser,
          toUserCC: data.toUserCC,
          itemName: item.name,
          toOrganization: data.toOrganization,
          fromOrganization: data.fromOrganization,
          orderId
        });
      } catch (e) {
        console.log('returnClose', e)
      }

    }
  }

  returnRejectModalOpen = (index) => {
    this.setState({
      returnRejectModal: true,
      returnRejectIndex: index
    });
  }

  returnRejectClose = async (e) => {
    let index = e && e.currentTarget.dataset.index;
    this.setState({
      returnRejectModal: false,
      // returnRejectId: null
    });

    if (index !== undefined) {
      try {
        let data = this.state.data;
        let cart = this.state.data.cart;
        let items = cart.items || [];

        // let index = items.findIndex(v => v.id === id);

        let returnRejectDate = firebase.firestore.Timestamp.now();
        let returnRejectBy = this.props.user.id;
        let returnRejectByUser = this.props.user.last_name + ' '+ this.props.user.first_name;

        let item = items[index];
        item.returnRejectDate = returnRejectDate;
        item.returnRejectBy = returnRejectBy;
        item.returnRejectByUser = returnRejectByUser;
        item.returnReject = true;

        let ordersRef = this.props.db.collection('orders').doc(data.id);

        let obj = {
          cart: data.cart,
          returnRejectDate: returnRejectDate,
          returnRejectBy: returnRejectBy,
          returnRejectByUser: returnRejectByUser,
          latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        }

        let allConfirm = true;
        if (items.some(e => e.returnConfirm === false && e.returnReject === false)) {
          allConfirm = false;
        }

        obj.returnRejected = true;
        if (allConfirm) {
          obj.returnWait = false;
        }

        let shippedItemCount = 0;
        let possibleShippedItemCount = 0;
        for (let i=0; i<items.length; i++) {
          let itemTemp = items[i];
          if (!itemTemp.divided && !itemTemp.cancelConfirm && !itemTemp.returnMain && !itemTemp.returnConfirm) {
            possibleShippedItemCount += 1;
          }
          if (itemTemp.okurijyoNo) {
            let okurijyoNo = itemTemp.okurijyoNo.slice(-1)[0].value;
            let hassouDate = itemTemp.okurijyoNo.slice(-1)[0].hassouDate ? itemTemp.okurijyoNo.slice(-1)[0].hassouDate.toDate() : '';
            if (okurijyoNo && hassouDate) {
              shippedItemCount++;
            }
          }
        }

        if (shippedItemCount >= possibleShippedItemCount) {
          obj.isShipped = true
        }

        obj.changeDate = new Date().getTime();

        data = {...data, ...obj};
        console.log('obj', obj)
        this.setState({data}, () => {
          ordersRef.set(obj, { merge: true });
        });

        //TODO: send email cancel confirm
        function n(n){
          return n > 9 ? "" + n: "0" + n;
        }

        let returnRejectDateFormatted = '';
        if (returnRejectDate) {
          returnRejectDateFormatted = returnRejectDate.toDate().getFullYear() + '/'
              + n(returnRejectDate.toDate().getMonth()+1) + '/'
              + n(returnRejectDate.toDate().getDate()) + ' '
              + n(returnRejectDate.toDate().getHours()) + ':'
              + n(returnRejectDate.toDate().getMinutes());
        }

        let returnDateFormatted = '';
        if (item.returnDate) {
          returnDateFormatted = item.returnDate.toDate().getFullYear() + '/'
              + n(item.returnDate.toDate().getMonth()+1) + '/'
              + n(item.returnDate.toDate().getDate()) + ' '
              + n(item.returnDate.toDate().getHours()) + ':'
              + n(item.returnDate.toDate().getMinutes());
        }

        let apply = Object.keys(data.apply);
        let orderId = data.id;
        await firebase.functions().httpsCallable('sendReturnRejectEmail')({
          fromUser: data.fromUser,
          orderNo: data.cart.orderNo,
          returnDate: returnDateFormatted,
          returnBy: item.returnBy,
          returnByUser: item.returnByUser,
          returnRejectDate: returnRejectDateFormatted,
          returnRejectByUser: item.returnRejectByUser,
          apply: apply,
          toUser: data.toUser,
          toUserCC: data.toUserCC,
          itemName: item.name,
          toOrganization: data.toOrganization,
          fromOrganization: data.fromOrganization,
          orderId
        });
      } catch (e) {
        console.log('returnRejectClose', e);
      }

    }
  }

  cancelModalOpen = (index) => {
    this.setState({
      cancelModal: true,
      cancelIndex: index,
    });
  }

  cancelClose = async (e) => {
    let index = e && e.currentTarget.dataset.index;
    this.setState({
      cancelModal: false,
      // cancelId: null,
    });

    if (index !== undefined) {
      try {
        let data = this.state.data;
        let cart = this.state.data.cart;
        let items = cart.items || [];

        // let index = items.findIndex(v => v.id === id);

        let cancelConfirmDate = firebase.firestore.Timestamp.now();
        let cancelConfirmBy = this.props.user.id;
        let cancelConfirmByUser = this.props.user.last_name + ' '+ this.props.user.first_name;

        let item = items[index];
        item.cancelConfirmDate = cancelConfirmDate;
        item.cancelConfirmBy = cancelConfirmBy;
        item.cancelConfirmByUser = cancelConfirmByUser;
        item.cancelConfirm = true;

        let ordersRef = this.props.db.collection('orders').doc(data.id);
        let obj = {
          cart: data.cart,
          cancelConfirmDate: cancelConfirmDate,
          cancelConfirmBy: cancelConfirmBy,
          cancelConfirmByUser: cancelConfirmByUser,
          latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        }

        let allConfirm = true;
        if (items.some(e => e.cancelConfirm === false && e.cancelReject === false)) {
          allConfirm = false;
        }

        obj.cancelConfirmed = true;
        if (allConfirm) {
          obj.cancelWait = false;
        }

        let shippedItemCount = 0;
        let possibleShippedItemCount = 0;
        for (let i=0; i<items.length; i++) {
          let itemTemp = items[i];
          if (!itemTemp.divided && !itemTemp.cancelConfirm && !itemTemp.returnMain && !itemTemp.returnConfirm) {
            possibleShippedItemCount += 1;
          }
          if (itemTemp.okurijyoNo) {
            let okurijyoNo = itemTemp.okurijyoNo.slice(-1)[0].value;
            let hassouDate = itemTemp.okurijyoNo.slice(-1)[0].hassouDate ? itemTemp.okurijyoNo.slice(-1)[0].hassouDate.toDate() : '';
            if (okurijyoNo && hassouDate) {
              shippedItemCount++;
            }
          }
        }

        if (shippedItemCount >= possibleShippedItemCount) {
          obj.isShipped = true
        }

        obj.changeDate = new Date().getTime();

        data = {...data, ...obj};
        this.setState({data}, () => {
          ordersRef.set(obj, { merge: true });
        });

        //TODO: send email cancel confirm

        function n(n){
          return n > 9 ? "" + n: "0" + n;
        }

        let cancelDateFormatted = '';
        if (item.cancelDate) {
          cancelDateFormatted = item.cancelDate.toDate().getFullYear() + '/'
              + n(item.cancelDate.toDate().getMonth()+1) + '/'
              + n(item.cancelDate.toDate().getDate()) + ' '
              + n(item.cancelDate.toDate().getHours()) + ':'
              + n(item.cancelDate.toDate().getMinutes());
        }

        let cancelConfirmDateFormatted = '';
        if (item.cancelConfirmDate) {
          cancelConfirmDateFormatted = item.cancelConfirmDate.toDate().getFullYear() + '/'
              + n(item.cancelConfirmDate.toDate().getMonth()+1) + '/'
              + n(item.cancelConfirmDate.toDate().getDate()) + ' '
              + n(item.cancelConfirmDate.toDate().getHours()) + ':'
              + n(item.cancelConfirmDate.toDate().getMinutes());
        }

        let apply = Object.keys(data.apply);
        let orderId = data.id;
        firebase.functions().httpsCallable('sendCancelConfirmEmail')({
          fromUser: data.fromUser,
          orderNo: data.cart.orderNo,
          cancelDate: cancelDateFormatted,
          cancelBy: item.cancelBy,
          cancelByUser: item.cancelByUser,
          cancelConfirmDate: cancelConfirmDateFormatted,
          cancelConfirmByUser: item.cancelConfirmByUser,
          apply: apply,
          toUser: data.toUser,
          toUserCC: data.toUserCC,
          itemName: item.name,
          toOrganization: data.toOrganization,
          fromOrganization: data.fromOrganization,
          orderId
        }).then(() => {
          console.log('success')
        }).catch((e) => {
          console.log('e', e)
        });
      } catch (e) {
        console.log('e', e)
      }

    }
  }

  cancelRejectModalOpen = (index) => {
    this.setState({
      cancelRejectModal: true,
      cancelRejectIndex: index
    });
  }

  cancelRejectClose = async (e) => {
    let index = e && e.currentTarget.dataset.index;
    this.setState({
      cancelRejectModal: false,
      // cancelRejectId: null
    });

    if (index !== undefined) {
      try {
        let data = this.state.data;
        let cart = this.state.data.cart;
        let items = cart.items || [];

        // let index = items.findIndex(v => v.id === id);

        let cancelRejectDate = firebase.firestore.Timestamp.now();
        let cancelRejectBy = this.props.user.id;
        let cancelRejectByUser = this.props.user.last_name + ' '+ this.props.user.first_name;

        let item = items[index];
        item.cancelRejectDate = cancelRejectDate;
        item.cancelRejectBy = cancelRejectBy;
        item.cancelRejectByUser = cancelRejectByUser;
        item.cancelReject = true;

        // console.log('cancelClose', data, item, index);

        let ordersRef = this.props.db.collection('orders').doc(data.id);

        let obj = {
          cart: data.cart,
          cancelRejectDate: cancelRejectDate,
          cancelRejectBy: cancelRejectBy,
          cancelRejectByUser: cancelRejectByUser,
          latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        }

        let allConfirm = true;
        if (items.some(e => e.cancelConfirm === false && e.cancelReject === false)) {
          allConfirm = false
        }
        // console.log('cancelReject',items, item, allConfirm);
        // return;

        obj.cancelRejected = true;
        if (allConfirm) {
          obj.cancelWait = false;
        }

        let shippedItemCount = 0;
        let possibleShippedItemCount = 0;
        for (let i=0; i<items.length; i++) {
          let itemTemp = items[i];
          if (!itemTemp.divided && !itemTemp.cancelConfirm && !itemTemp.returnMain && !itemTemp.returnConfirm) {
            possibleShippedItemCount += 1;
          }
          if (itemTemp.okurijyoNo) {
            let okurijyoNo = itemTemp.okurijyoNo.slice(-1)[0].value;
            let hassouDate = itemTemp.okurijyoNo.slice(-1)[0].hassouDate ? itemTemp.okurijyoNo.slice(-1)[0].hassouDate.toDate() : '';
            if (okurijyoNo && hassouDate) {
              shippedItemCount++;
            }
          }
        }

        if (shippedItemCount >= possibleShippedItemCount) {
          obj.isShipped = true
        }

        obj.changeDate = new Date().getTime();

        data = {...data, ...obj};
        this.setState({data}, () => {
          ordersRef.set(obj, { merge: true });
        });

        //TODO: send email cancel reject
        function n(n){
          return n > 9 ? "" + n: "0" + n;
        }

        let cancelDateFormatted = '';
        if (item.cancelDate) {
          cancelDateFormatted = item.cancelDate.toDate().getFullYear() + '/'
              + n(item.cancelDate.toDate().getMonth()+1) + '/'
              + n(item.cancelDate.toDate().getDate()) + ' '
              + n(item.cancelDate.toDate().getHours()) + ':'
              + n(item.cancelDate.toDate().getMinutes());
        }

        let cancelRejectDateFormatted = '';
        if (cancelRejectDate) {
          cancelRejectDateFormatted = cancelRejectDate.toDate().getFullYear() + '/'
              + n(cancelRejectDate.toDate().getMonth()+1) + '/'
              + n(cancelRejectDate.toDate().getDate()) + ' '
              + n(cancelRejectDate.toDate().getHours()) + ':'
              + n(cancelRejectDate.toDate().getMinutes());
        }

        let apply = Object.keys(data.apply);
        let orderId = data.id;
        firebase.functions().httpsCallable('sendCancelRejectEmail')({
          fromUser: data.fromUser,
          orderNo: data.cart.orderNo,
          cancelDate: cancelDateFormatted,
          cancelBy: item.cancelBy,
          cancelByUser: item.cancelByUser,
          cancelRejectDate: cancelRejectDateFormatted,
          cancelRejectByUser: item.cancelRejectByUser,
          apply: apply,
          toUser: data.toUser,
          toUserCC: data.toUserCC,
          itemName: item.name,
          toOrganization: data.toOrganization,
          fromOrganization: data.fromOrganization,
          orderId
        });
      } catch (e) {
        console.log('cancelRejectClose', e)
      }
    }
  }

  render() {
    if (!this.props.org) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }
    if (!this.state.data.id) {
      return (
        <>
        </>
      )
    }

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    // console.log('urigawa', this.state.data);

    let cart = [];
    let total = 0;
    let mainTotal = 0;
    let postage = 0;
    let taxes = {};
    let taxTotal = 0;
    let cancelTotal = 0;
    let returnTotal = 0;
    // let dateNow = firebase.firestore.Timestamp.now().toDate();
    // console.log('minDate', `${dateNow.getFullYear()}/01/01`)

    let cartData = {
      items: [],
      orderNo: '',
      toUser: '',
      toUserName: '',
      orderNouki: '最短',
      orderNoukiDay: '',
      orderNoukiDate: '',
      orderBasyo: '登録住所',
      orderZipcode: '',
      orderAddr1: '',
      orderAddr2: '',
      orderJigyosyo: '',
      orderTanto: '',
      orderPhone: '',
      orderBikou: '',
      requested: false,
    };
    if(this.state.data && this.state.data.cart && this.state.data.cart.items){
      Object.assign(cartData, this.state.data.cart);
      let items = this.state.data.cart.items;
      let num = 0;
      let no = 0;
      let subDividedNo = 1;
      let returnSubNo = 1;

      // console.log('items', items)

      items.forEach((item, index) => {
        const cnum=num;
        let date = item.update.toDate().getFullYear() + '年'
              + (item.update.toDate().getMonth() + 1) + '月'
              + item.update.toDate().getDate() + '日';

              let noukiSDate = '';
              let noukiEDate = '';
              let okurijyoNo = '';
              let hassouDate = '';
              if(item.nouki){
                noukiSDate = item.nouki.slice(-1)[0].sdate ? item.nouki.slice(-1)[0].sdate.toDate() : '';
                noukiEDate = item.nouki.slice(-1)[0].edate ? item.nouki.slice(-1)[0].edate.toDate() : '';
              }

              let okurijyoProcess = <div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#FF3547'}}/>;
              let okurijyoStatus = 'unShipped';
              let sendButtonText = "送信";
              let sendButtonClass = "";

              if(item.okurijyoNo){
                // console.log(item.okurijyoNo)
                okurijyoNo = item.okurijyoNo.slice(-1)[0].value;
                hassouDate = item.okurijyoNo.slice(-1)[0].hassouDate ? item.okurijyoNo.slice(-1)[0].hassouDate.toDate() : '';
                // console.log('shippingDate', item.name,item.okurijyoNo.slice(-1)[0].shippingDate);
                let latestUpdate = item.okurijyoNo.slice(-1)[0].latestUpdate;
                let shippingDate = item.okurijyoNo.slice(-1)[0].shippingDate;

                if (this.state.loadSent[cnum]) {
                  sendButtonClass = "waitFirstSentShipment";
                } else {
                  sendButtonClass = "";
                }

                try {
                  if (latestUpdate && shippingDate) {
                    let luTime = latestUpdate.toDate().getTime();
                    let shTime = shippingDate.toDate().getTime();

                    // console.log(luTime, shTime)

                    if (okurijyoNo && hassouDate && (luTime === shTime)) {
                      okurijyoProcess = <div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#19A22B'}}/>;
                      okurijyoStatus = 'shipped';
                      sendButtonText = "送信済";
                    }

                    if (luTime !== shTime) {
                      if (this.state.loadSent[cnum]) {
                        sendButtonClass = "waitMultiSentShipment"
                      } else {
                        sendButtonClass = "multiSentShipment"
                      }
                      sendButtonText = "修正内容再送信";
                    }
                  }

                  if (!latestUpdate && !shippingDate && hassouDate) {
                    okurijyoProcess = <div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#19A22B'}}/>;
                    okurijyoStatus = 'shipped';
                  }

                  if (!latestUpdate && shippingDate && hassouDate) {
                    okurijyoProcess = <div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#19A22B'}}/>;
                    okurijyoStatus = 'shipped';
                  }
                } catch (e) {
                  console.log(e)
                }
              }

              if (item.cancelConfirm === false && item.cancelReject === false) {
                okurijyoProcess = <div style={{width: 12, height: 12, backgroundColor: '#ff63e0'}}/>;
              }

              if (item.cancelConfirm === true) {
                okurijyoProcess = <div style={{width: 12, height: 12, borderRadius: 6, backgroundColor: '#ff63e0'}}/>;
              }

              if (item.returnConfirm === false && item.returnReject === false) {
                okurijyoProcess = <div style={{width: 12, height: 12, backgroundColor: '#00C2FF'}}/>;
              }

              if (item.returnConfirm === true) {
                okurijyoProcess = <div style={{width: 12, height: 12, borderRadius: 6, backgroundColor: '#00C2FF'}}/>;
              }

              let templatedNo;
              if (item.subDivided) {
                templatedNo = n(no)+'-'+subDividedNo;
                if (item.returnSub) {
                  templatedNo = n(no)+'-'+(subDividedNo-1)+'-'+returnSubNo;
                  returnSubNo += 1;
                } else {
                  returnSubNo = 1;
                  subDividedNo += 1;
                }
              } else {
                if (item.returnSub) {
                  templatedNo = n(no)+'-'+returnSubNo;
                  returnSubNo += 1;
                } else {
                  no += 1;
                  subDividedNo = 1;
                  returnSubNo = 1;
                  templatedNo = n(no);
                }
              }

              item.no = templatedNo;

              // console.log('disabled', !(hassouDate && okurijyoNo) && !item.okurijyoComment || (item.subDivided && !item.divideConfirm), !(hassouDate && okurijyoNo));
              let cancelDate = '';
              let cancelConfirmDate = '';
              let cancelRejectDate = '';
              let returnDate = '';
              let returnConfirmDate = '';
              let returnRejectDate = '';
              if (item.cancelDate) {
                cancelDate = item.cancelDate
                    ? item.cancelDate.toDate().getFullYear() + '/'
                    + n(item.cancelDate.toDate().getMonth()+1) + '/'
                    + n(item.cancelDate.toDate().getDate()) + ' '
                    + n(item.cancelDate.toDate().getHours()) + ':'
                    + n(item.cancelDate.toDate().getMinutes())
                    : '';
              }

              if (item.cancelConfirmDate) {
                cancelConfirmDate = item.cancelConfirmDate
                    ? item.cancelConfirmDate.toDate().getFullYear() + '/'
                    + n(item.cancelConfirmDate.toDate().getMonth()+1) + '/'
                    + n(item.cancelConfirmDate.toDate().getDate()) + ' '
                    + n(item.cancelConfirmDate.toDate().getHours()) + ':'
                    + n(item.cancelConfirmDate.toDate().getMinutes())
                    : '';
              }

              if (item.cancelRejectDate) {
                cancelRejectDate = item.cancelRejectDate
                    ? item.cancelRejectDate.toDate().getFullYear() + '/'
                    + n(item.cancelRejectDate.toDate().getMonth()+1) + '/'
                    + n(item.cancelRejectDate.toDate().getDate()) + ' '
                    + n(item.cancelRejectDate.toDate().getHours())  + ':'
                    + n(item.cancelRejectDate.toDate().getMinutes())
                    : '';
              }

              if (item.returnDate) {
                returnDate = item.returnDate
                    ? item.returnDate.toDate().getFullYear() + '/'
                    + n(item.returnDate.toDate().getMonth()+1) + '/'
                    + n(item.returnDate.toDate().getDate()) + ' '
                    + n(item.returnDate.toDate().getHours()) + ':'
                    + n(item.returnDate.toDate().getMinutes())
                    : '';
              }

              if (item.returnConfirmDate) {
                returnConfirmDate = item.returnConfirmDate
                    ? item.returnConfirmDate.toDate().getFullYear() + '/'
                    + n(item.returnConfirmDate.toDate().getMonth()+1) + '/'
                    + n(item.returnConfirmDate.toDate().getDate()) + ' '
                    + n(item.returnConfirmDate.toDate().getHours()) + ':'
                    + n(item.returnConfirmDate.toDate().getMinutes())
                    : '';
              }

              if (item.returnRejectDate) {
                returnRejectDate = item.returnRejectDate
                    ? item.returnRejectDate.toDate().getFullYear() + '/'
                    + n(item.returnRejectDate.toDate().getMonth()+1) + '/'
                    + n(item.returnRejectDate.toDate().getDate()) + ' '
                    + n(item.returnRejectDate.toDate().getHours())  + ':'
                    + n(item.returnRejectDate.toDate().getMinutes())
                    : '';
              }

              cart.push({
                no: <div className={item.divided && 'redText'} style={{paddingLeft: item.returnConfirm ? 15 : 0}}>
                  {templatedNo}<br/>
                  {item.subDivided && item.divideConfirm === true && <div className={'divideTag'}>分納</div>}
                  {item.subDivided && item.divideConfirm === false && <div className={'dividedStatusProgress'}>分納手続中</div>}
                  {item.readComment === false && <div><MDBIcon icon="comments" className="mr-1 commentIcon" /></div>}
                  {item.readComment === true && <div><MDBIcon icon="comments" className="mr-1 commentIconGray" /></div>}

                  {(item.cancelConfirm === false || item.cancelConfirm === true) && <div style={{fontSize: 12, wordBreak: 'keep-all', minWidth: 100, marginTop: 3}}>
                    {(item.cancelConfirm === false && item.cancelReject === false) && <div className={'cancelConfirmWait'}>キャンセル承認待</div>}
                    {item.cancelConfirm === true && <div className={'cancelConfirmed'}>キャンセル</div>}
                    {item.cancelReject === true && <div class={'cancelReject'}>キャンセル却下</div>}
                    <div>依頼者：{item.cancelByUser}</div>
                    <div>{cancelDate}</div>
                    {item.cancelConfirm === true && <div>確認者：{item.cancelConfirmByUser}</div>}
                    {item.cancelConfirm === true && <div>{cancelConfirmDate}</div>}
                    {item.cancelReject === true && <div>確認者：{item.cancelRejectByUser}</div>}
                    {item.cancelReject === true && <div>{cancelRejectDate}</div>}
                    {(item.cancelConfirm === false && item.cancelReject === false) && <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
                      <MDBBtn
                          className="ml-auto btn-block customGreenButton"
                          color=""
                          data-id={this.state.data.id}
                          onClick={() => this.cancelModalOpen(index)}
                      >承認</MDBBtn>
                      <MDBBtn
                          className="ml-auto btn-block customOrangeButton"
                          color=""
                          data-id={this.state.data.id}
                          onClick={() => this.cancelRejectModalOpen(index)}
                      >却下</MDBBtn>
                    </div>}
                  </div>}

                  {(item.returnConfirm === false || item.returnConfirm === true) && <div style={{fontSize: 12, wordBreak: 'keep-all', minWidth: 100, marginTop: 3}}>
                    {(item.returnConfirm === false && item.returnReject === false) && <div className={'cancelConfirmWait'}>返品承認待</div>}
                    {item.returnConfirm === true && <div className={'cancelConfirmed'}>返品</div>}
                    {item.returnReject === true && <div class={'cancelReject'}>返品却下</div>}
                    <div>依頼者：{item.returnByUser}</div>
                    <div>{returnDate}</div>
                    {item.returnConfirm === true && <div>確認者：{item.returnConfirmByUser}</div>}
                    {item.returnConfirm === true && <div>{returnConfirmDate}</div>}
                    {item.returnReject === true && <div>確認者：{item.returnRejectByUser}</div>}
                    {item.returnReject === true && <div>{returnRejectDate}</div>}
                    {(item.returnConfirm === false && item.returnReject === false) && <div style={{display: 'flex', flexDirection: 'row', gap: 10}}>
                      <MDBBtn
                          className="ml-auto btn-block customGreenButton"
                          color=""
                          data-id={this.state.data.id}
                          onClick={() => this.returnModalOpen(index)}
                      >承認</MDBBtn>
                      <MDBBtn
                          className="ml-auto btn-block customOrangeButton"
                          color=""
                          data-id={this.state.data.id}
                          onClick={() => this.returnRejectModalOpen(index)}
                      >却下</MDBBtn>
                    </div>}
                  </div>}
                </div>, // 分納手続中
                code: <span className={item.divided && 'redText'}>{item.sellercode}/{item.code}<br/>{item.makeredicode}/{item.makerordercode}</span>,
                name: <Link to={"/items/" + item.id}><span className={item.divided && 'redText'}>{item.name}</span></Link>,
                maker: <span className={item.divided && 'redText'}>{item.maker}</span>,
                count: <div className={'text-nowrap' + (item.divided && ' redText')}>
                  <div style={{textAlign: 'right'}}><span style={{color: "darkgray"}}>@</span>{item.price.toLocaleString() + '円'}</div>
                  <div style={{textAlign: 'right', fontSize: 18, fontWeight: 'bold'}}>
                    <div>
                      {item.subDivided ? <span style={{display: 'inline-flex', alignItems: 'center'}}>
                        <input type={'number'} className={'customInput'} disabled={item.divideConfirm || okurijyoStatus === 'shipped'} onChange={(v) => this.handleSubItemChange(v, cnum, item.mainSubDivided)} value={item.count}/>
                        &nbsp;/&nbsp;{item.mainCount}
                      </span> : item.count}
                    </div>
                  </div>
                  {item.subDivided && this.checkDividedItemCount(item) && <div style={{textAlign: 'right'}}>
                    <span style={{color: 'red'}}>合計数量が異なります。</span>
                  </div>}
                </div>,
                price: <p className={"text-right" + (item.divided && ' redText')}>{(item.count * item.price).toLocaleString() + '円'}</p>,
                description: <div style={{overflowWrap: 'anywhere'}} className={item.divided && 'redText'}>{item.description}</div>,
                nouki: <div style={{minHeight: 170}}>
                  <div className="d-md-flex flex-row mt-0 align-items-center">
                    <DatePicker
                      dateFormat="yyyy/MM/dd"
                      id="estimateDate"
                      style={{width: 80}}
                      disabled={!!item.divided || (item.returnConfirm) || (item.cancelConfirm) || (item.cancelConfirm === false && item.cancelReject === false) || (item.returnConfirm === false && item.returnReject === false) || (item.returnMain === true)}
                      className="form-control"
                      selected={noukiSDate}
                      onChange={(date) => this.handleItemNoukiSDateChange(date,cnum)}
                      // minDate={new Date(`${dateNow.getFullYear()}/01/01`)}
                      locale="ja-JP"
                    />
                    <div className="p-2">〜</div>
                    <DatePicker
                      dateFormat="yyyy/MM/dd"
                      id="estimateDate"
                      style={{width: 80}}
                      disabled={!!item.divided || (item.returnConfirm) || (item.cancelConfirm) || (item.cancelConfirm === false && item.cancelReject === false) || (item.returnConfirm === false && item.returnReject === false) || (item.returnMain === true)}
                      className="form-control"
                      selected={noukiEDate}
                      onChange={(date) => this.handleItemNoukiEDateChange(date,cnum)}
                      locale="ja-JP"
                    />
                  </div>
                  <div className={"d-md-flex flex-column mt-1 align-items-baseline"}>
                    <span style={{height: 20}}>
                      <MDBIcon icon="comments" className="mr-1 commentIconGray" />
                    </span>
                    <textarea
                        rows={3}
                        maxLength={200}
                        className={'form-control'}
                        disabled={!!item.divided || (item.returnConfirm) || (item.cancelConfirm) || (item.cancelConfirm === false && item.cancelReject === false) || (item.returnConfirm === false && item.returnReject === false) || (item.returnMain === true)}
                        value={item.noukiComment || ''}
                        onChange={(v) => this.handleItemNoukiCommentChange(v, cnum)}/>
                  </div>
                  <div className="d-md-flex flex-row mt-1 align-items-center">
                    <MDBBtn
                      className="ml-auto btn-block col-md-6"
                      color="success"
                      style={{fontSize: 13}}
                      onClick={(e) => this.handleItemNoukiDateUpdate(cnum,e)}
                      data-id={this.state.data.id}
                      disabled = {!(noukiSDate || noukiEDate) && !item.noukiComment || (item.returnConfirm) || (item.cancelConfirm) || (item.cancelConfirm === false && item.cancelReject === false) || (item.returnConfirm === false && item.returnReject === false) || (item.returnMain === true)}
                    >登録</MDBBtn>
                  </div>
                </div>
                ,
                okurijyo:
                <div style={{minHeight: 170}}>
                  <div className="d-md-flex flex-row mt-0 align-items-center">
                    <DatePicker
                      dateFormat="yyyy/MM/dd"
                      id="estimateDate"
                      style={{width: 80}}
                      disabled={!!item.divided || (item.subDivided && !item.divideConfirm) || (item.returnConfirm) || (item.cancelConfirm) || (item.cancelConfirm === false && item.cancelReject === false) || (item.returnConfirm === false && item.returnReject === false) || (item.returnMain === true)}
                      className="form-control"
                      selected={hassouDate}
                      onChange={(date) => this.handleItemHassouDateChange(date,cnum)}
                      locale="ja-JP"
                    />
                    <div className="p-2">/</div>
                    <input
                      type="text"
                      className="form-control"
                      id="orderNo"
                      style={{width: 80}}
                      disabled={!!item.divided || (item.subDivided && !item.divideConfirm) || (item.returnConfirm) || (item.cancelConfirm) || (item.cancelConfirm === false && item.cancelReject === false) || (item.returnConfirm === false && item.returnReject === false) || (item.returnMain === true)}
                      placeholder=""
                      onChange={(e) => this.handleItemOkurijyoNoChange(e,cnum)}
                      value={okurijyoNo}
                    />
                  </div>
                  <div className={"d-md-flex flex-column mt-1 align-items-baseline"}>
                    <span style={{height: 20}}>
                      <MDBIcon icon="comments" className="mr-1 commentIconGray" />
                    </span>
                    <textarea
                        rows={3}
                        maxLength={200}
                        className={'form-control'}
                        disabled={!!item.divided || (item.subDivided && !item.divideConfirm) || (item.returnConfirm) || (item.cancelConfirm) || (item.cancelConfirm === false && item.cancelReject === false) || (item.returnConfirm === false && item.returnReject === false) || (item.returnMain === true)}
                        value={item.okurijyoComment || ''}
                        onChange={(v) => this.handleItemOkurijyoCommentChange(v, cnum)}/>
                  </div>
                  <div className="d-md-flex flex-row mt-1 align-items-center">
                    <MDBBtn
                      className={`ml-auto btn-block ${sendButtonClass}`}
                      num={num}
                      color="success"
                      style={{fontSize: 13, wordWrap: "normal", wordBreak: "keep-all", width: "auto", minWidth: "50%"}}
                      onClick={(e) => this.handleItemOkurijyoNoUpdate(cnum,e)}
                      data-id={this.state.data.id}
                      disabled = {!(hassouDate && okurijyoNo) || (item.subDivided && !item.divideConfirm) || (item.returnConfirm) || (item.cancelConfirm) || (item.cancelConfirm === false && item.cancelReject === false) || (item.returnConfirm === false && item.returnReject === false) || (item.returnMain === true)}
                    >{sendButtonText}</MDBBtn>
                  </div>
                  {/*{item.divided && <div className={"d-md-flex flex-row mt-1 align-items-center"}>*/}
                  {/*  <MDBBtn*/}
                  {/*      className="ml-auto btn-block col-md-6"*/}
                  {/*      num={num}*/}
                  {/*      onClick={(e) => this.divideMore(item)}*/}
                  {/*      data-id={this.state.data.id}*/}
                  {/*      disabled = {this.checkMaxDivided(item)}*/}
                  {/*  >分納行を増やす</MDBBtn>*/}
                  {/*</div>}*/}
                </div>,
                okurijyoProcess: <div style={{display: 'flex', justifyContent: 'flex-start', width: 50, alignItems: 'center', position: 'relative', gap: 5}}>
                  {!item.divided && okurijyoProcess}
                  {((item.cancelConfirm === undefined && item.returnConfirm === undefined) || ((item.cancelReject === true && item.cancelConfirm !== true && item.returnConfirm !== true) || item.returnReject === true && item.returnConfirm !== true && item.cancelConfirm !== true)) && <div className={'menuButtonView'}>
                    {okurijyoStatus === 'unShipped' && item.divided && !this.checkAllConfirmed(item) && (this.state.data.toUser === this.props.user.id || (this.props.user && this.props.user.authorizer)) && <>
                      <div className={'menuButton'} onClick={(e) => {
                        e.stopPropagation();
                        this.showMenu(item.id, index);
                      }}>
                        <MDBIcon icon={'ellipsis-v'}/>
                      </div>
                      {this.state.showMenu === item.id && this.state.showMenuIndex === index && <div className={'menuArea'}>
                        {/*{!this.checkMaxDivided(item) &&}*/}
                        <div onClick={() => {
                          if (hassouDate && okurijyoNo && okurijyoStatus === 'unShipped') {
                            this.setState({divideType: 'divideMore', notSendingModal: true, showMenu: null, showMenuIndex: null, tempItem: item, tempIndex: index})
                          } else {
                            this.divideMore(item)
                          }
                        }}>
                          分納行を増やす
                        </div>
                        {!this.checkDividedItemShipped(item) && !this.checkOneConfirmed(item) && <div onClick={() => this.cancelDivide(item)}>
                          分納をやめる
                        </div>}
                      </div>}
                    </>
                    }
                    {okurijyoStatus === 'unShipped' && !item.divided && item.count > 1 &&
                        <>
                          {!item.subDivided &&  (this.state.data.toUser === this.props.user.id || (this.props.user && this.props.user.authorizer)) && (item.cancelConfirm === undefined || item.cancelReject === true || item.returnReject === true) &&  <><div className={'menuButton'} onClick={(e) => {
                            e.stopPropagation();
                            this.showMenu(item.id, index);
                          }}>
                            <MDBIcon icon={'ellipsis-v'}/>
                          </div>
                            {this.state.showMenu === item.id && this.state.showMenuIndex === index && <div className={'menuArea'}>
                              <div onClick={(e) => {
                                // console.log(hassouDate, okurijyoNo, okurijyoStatus)
                                if ((hassouDate || okurijyoNo) && okurijyoStatus === 'unShipped') {
                                  // console.log('unshipped')
                                  this.setState({divideType: 'divide', notSendingModal: true, showMenu: null, showMenuIndex: null, tempItem: item, tempIndex: index})
                                } else {
                                  // console.log('shipped')
                                  this.divide(item)
                                }
                              }}>
                                分納
                              </div>
                            </div>}
                          </>}
                        </>
                    }
                    {item.subDivided && okurijyoStatus === 'unShipped' && (item.dividedBy === this.props.user.id || (this.props.user && this.props.user.authorizer)) &&
                        <>
                          <div className={'menuButton'} onClick={(e) => {
                            e.stopPropagation();
                            this.showMenu(item.id, index)
                          }}>
                            <MDBIcon icon={'ellipsis-v'}/>
                          </div>
                          {this.state.showMenu === item.id && this.state.showMenuIndex === index && <div className={'menuArea'}>
                            {item.count > 0 && okurijyoStatus === 'unShipped' &&
                                <div onClick={() => {
                                  if (hassouDate && okurijyoNo && okurijyoStatus === 'unShipped') {
                                    this.setState({divideType: 'divideSub', notSendingModal: true, showMenu: null, showMenuIndex: null, tempItem: item, tempIndex: index})
                                  } else {
                                    this.subDivide(item, index)
                                  }
                                }}>
                                  分納行を増やす
                                </div>}
                            {!this.checkDividedItemCount(item) && !item.divideConfirm && <div onClick={() => this.confirmSubDivide(item, index)}>
                              分納確定
                            </div>}
                            {!item.mainSubDivided && !item.divideConfirm && okurijyoStatus === 'unShipped' && <div onClick={() => this.deleteDividedItem(item, index)}>
                              この分納行を削除
                            </div>}
                          </div>}
                        </>
                    }
                  </div>
                  }
                </div>
              })


        if (!item.divided) {
          if (!item.tax) {
            item.tax = 0.1;
          }

          if (item.returnConfirm) {
            returnTotal += (item.count * item.price);
          }
          if (item.cancelConfirm) {
            cancelTotal += (item.count * item.price);
          } else {
            if (taxes[item.tax]) {
              taxes[item.tax] += (item.count * item.price * item.tax);
            } else {
              taxes[item.tax] = (item.count * item.price * item.tax);
            }
            taxTotal += parseInt(item.count * item.price * item.tax, 10);
            total += item.count * item.price;
          }

          if (!item.returnSub) {
            mainTotal += (item.count * item.price);
          }
        }
        num++;
      });
      postage = cartData.carriage ? cartData.carriage : 0;
      total += postage;
    }
    const isEnable = (cartData.toUser !== '')
    && (this.state.orderNouki !== '')
    && (this.state.orderBasyo !== '')
;

    const data = {
      columns: [
        {
          label: 'No.',
          field: 'no',
        },
        {
          label: <>仕入先事業所C/商品コード<br />仕入先EDIC/仕入先発注C</>,
          field: 'code',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '商品名',
          field: 'name',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: 'メーカー名',
          field: 'maker',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '単価/数量',
          field: 'num',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '金額',
          field: 'price',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '備考',
          field: 'description',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '納期回答',
          field: 'nouki',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '出荷日/送り状番号',
          field: 'okurijyo',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            {/*<row style={{display: 'flex', justifyContent: 'center'}}>処理</row>*/}
            <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#19A22B', marginRight: 5}}/>出荷済</row>
            <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#FF3547', marginRight: 5}}/>未出荷</row>
            <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', wordBreak: 'keep-all'}}><div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#ff63e0', marginRight: 5}}/>キャンセル</row>
            <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#00c2ff', marginRight: 5}}/>返品</row>
          </div>,
          field: 'okurijyoProcess',
          minimal: 'sm'
        }
      ],
      //rows: this.state.dbItems

      rows: cart
    }

    // orderDate
    let orderDate = '';
    if(this.state.data && this.state.data['orderDate'] && this.state.data.cart && this.state.data.cart.items){
      orderDate = this.state.data['orderDate'].toDate().getFullYear() + '/'
          + n(this.state.data['orderDate'].toDate().getMonth()+1) + '/'
          + n(this.state.data['orderDate'].toDate().getDate()) + ' '
          + n(this.state.data['orderDate'].toDate().getHours()) + ':'
          + n(this.state.data['orderDate'].toDate().getMinutes());
    }else {
      orderDate = this.state.data['created'].toDate().getFullYear() + '/'
          + n(this.state.data['created'].toDate().getMonth()+1) + '/'
          + n(this.state.data['created'].toDate().getDate()) + ' '
          + n(this.state.data['created'].toDate().getHours()) + ':'
          + n(this.state.data['created'].toDate().getMinutes());
    }

    let date = '';
    if(this.state.data && this.state.data['latestUpdate'] && this.state.data.cart && this.state.data.cart.items){
      date = this.state.data['latestUpdate'].toDate().getFullYear() + '/'
          + n(this.state.data['latestUpdate'].toDate().getMonth()+1) + '/'
          + n(this.state.data['latestUpdate'].toDate().getDate()) + ' '
          + n(this.state.data['latestUpdate'].toDate().getHours()) + ':'
          + n(this.state.data['latestUpdate'].toDate().getMinutes());
    }else {
      date = this.state.data['created'].toDate().getFullYear() + '/'
          + n(this.state.data['created'].toDate().getMonth()+1) + '/'
          + n(this.state.data['created'].toDate().getDate()) + ' '
          + n(this.state.data['created'].toDate().getHours()) + ':'
          + n(this.state.data['created'].toDate().getMinutes());
    }

    let orderNoukiDate = '';
    if (this.state.data['cart'].orderNouki == '日付指定') {
      orderNoukiDate = this.state.data['cart'].orderNoukiDate ? this.state.data['cart'].orderNoukiDate.toDate().getFullYear() + '/'
          + (this.state.data['cart'].orderNoukiDate.toDate().getMonth()+1) + '/'
          + this.state.data['cart'].orderNoukiDate.toDate().getDate() + '' : '';
    }

    let visited = false;
    // console.log(this.state.data['visited'] && this.state.data['visited'][this.props.user.id], this.state.data['sellerConfirm'])
    // console.log('data', this.state.data['created'].toDate().getFullYear());
    // let createdYear = this.state.data['created'].toDate().getFullYear();
    let sellerConfirm = this.state.data['sellerConfirm'];
    if (sellerConfirm === true || sellerConfirm === false) {
      if (this.state.data['sellerConfirm']) {
        visited = true;
      }
    } else {
      if(this.state.data['visited'] && this.state.data['visited'][this.props.user.id] || this.state.data['sellerConfirm']) {
        visited = true;
      }
    }

    let applied = false;
    if(this.state.data['applied']) {
      applied = true;
    } else {
      // 承認されていないデータは表示しない
      return (<></>);
    }

    let deliveryAddress = cartData['deliveryAddress'] || {};
    let otasukeDeliveryAddress = cartData['otasukeDeliveryAddress'] || {};

    return (
      <div>
        {/*{this.state.showMenu && this.state.showMenuIndex && }*/}
        {this.state.showMenu && <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1041}} onClick={() => this.setState({showMenu: null, showMenuIndex: null})}/>}
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h2>注文書</h2>
          {this.state.data.applied && <MDBBtn disabled={this.state.loadingPDF} onClick={() => this.showPdf()}>
            {this.state.loadingPDF && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: 10}}/>}
            PDF 表示
          </MDBBtn>}
        </div>

        <MDBModal isOpen={this.state.pdfModal} toggle={this.toggle} size="xl">
          <MDBModalHeader toggle={this.toggle}>PDF 表示</MDBModalHeader>
          <MDBModalBody style={{padding: 30}}>
            <div style={{display: "inline-block", marginBottom: 10}}>
              <MDBBtn
                  className="btn-sm btn-block"
                  onClick={() => this.pdfGenerator(this.state.fileNamePDF, this.state.dataPDF, true)}
              >PDF ダウンロード</MDBBtn>
            </div>
            <div style={{overflow: 'auto', position: 'relative', border: '1px solid lightgray'}}>
              <object id="showPDF" type="application/pdf" style={{width: 1080, height: '75vh'}}/>
            </div>
          </MDBModalBody>
        </MDBModal>

        <MDBModal isOpen={this.state.notZeroModal} toggle={this.notZeroToggle}>
          <MDBModalHeader toggle={this.notZeroToggle}>エラー</MDBModalHeader>
          <MDBModalBody>
            １行目、２行目の数量は必須入力です。最初にこの２行の数量を確定してください。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn data-confirm={true} onClick={this.notZeroToggle} color="default">確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.notSendingModal} toggle={this.notSendingToggle}>
          <MDBModalHeader toggle={this.notSendingToggle}>出荷日＋送り状番号</MDBModalHeader>
          <MDBModalBody>
            「出荷日＋送り状番号」が入力されていますが、まだ送信されていません。
            <br/>
            「出荷日＋送り状番号」を破棄して分納手続をしますか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.notSendingToggle} color="secondary">いいえ</MDBBtn>
            <MDBBtn onClick={this.notSendingToggle} color="default" data-confirm={true}>はい</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.countZeroModal} toggle={this.countZeroToggle}>
          <MDBModalHeader toggle={this.countZeroToggle}>分納数量エラー</MDBModalHeader>
          <MDBModalBody>
            数量０の行があります。すべての行に数量を入力してから確定してください。不要な行は削除できます。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn data-confirm={true} onClick={this.countZeroToggle} color="default">確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.countMinusModal} toggle={this.countMinusToggle}>
          <MDBModalHeader toggle={this.countMinusToggle}>分納数量エラー</MDBModalHeader>
          <MDBModalBody>
            数量がマイナスの行があります。すべての行に正しい数量を入力してから確定してください。不要な行は削除できます。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn data-confirm={true} onClick={this.countMinusToggle} color="default">確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>



        <MDBModal isOpen={this.state.syukkaAllModal} toggle={this.syukkaAllToggle}>
          <MDBModalHeader toggle={this.syukkaAllToggle}>出荷日・送り状番号</MDBModalHeader>
          <MDBModalBody>
            出荷日・送り状番号およびコメントを送信しました。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
                className="btn-lg btn-block"
                onClick={this.syukkaAllToggle}
            >確　認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.syukkaCommentModal} toggle={this.syukkaCommentToggle}>
          <MDBModalHeader toggle={this.syukkaCommentToggle}>出荷日・送り状番号</MDBModalHeader>
          <MDBModalBody>
            コメントを登録しました。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
                className="btn-lg btn-block"
                onClick={this.syukkaCommentToggle}
            >確　認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.noukiAllModal} toggle={this.noukiAllToggle}>
          <MDBModalHeader toggle={this.noukiAllToggle}>納期回答</MDBModalHeader>
          <MDBModalBody>
            納期回答およびコメントを登録しました。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
                className="btn-lg btn-block"
                data-confirm={true}
                onClick={this.noukiAllToggle}
            >確　認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.noukiCommentModal} toggle={this.noukiCommentToggle}>
          <MDBModalHeader toggle={this.noukiCommentToggle}>納期回答</MDBModalHeader>
          <MDBModalBody>
            コメントを登録しました。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn
                className="btn-lg btn-block"
                data-confirm={true}
                onClick={this.noukiCommentToggle}
            >確　認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.divideModal} toggle={this.divideToggle}>
          <MDBModalHeader toggle={this.divideToggle}>分納手続開始</MDBModalHeader>
          <MDBModalBody>
            {/*{(this.state.divideItem || {}).name}の分納を確定しました。<br/>*/}
            {/*内容は販売先に通知されます。*/}
            {(this.state.divideItem || {}).name}の分納手続を開始しました。 <br/>
            数量および分納回数を指定した後、「分納確定ボタン」を押下すると分納が確定されます。<br/>
            内容は販売先に通知されます。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn data-confirm={true} onClick={this.divideToggle} color="default">確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.divideConfirmModal} toggle={this.divideConfirmToggle}>
          <MDBModalHeader toggle={this.divideConfirmToggle}>分納手続確定</MDBModalHeader>
          <MDBModalBody>
            {(this.state.divideItem || {}).name}の分納を確定しました。 内容は販売先に通知されます。
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn data-confirm={true} onClick={this.divideConfirmToggle} color="default">確認</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.cancelModal} toggle={this.cancelClose}>
          <MDBModalHeader toggle={this.cancelClose} >
            注文キャンセル依頼承認
          </MDBModalHeader>
          <MDBModalBody>
            キャンセル依頼を承認します。よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.cancelClose} color="secondary">戻る</MDBBtn>
            <MDBBtn onClick={this.cancelClose} color="danger" data-index={this.state.cancelIndex}>キャンセルを承認する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.cancelRejectModal} toggle={this.cancelRejectClose}>
          <MDBModalHeader toggle={this.cancelRejectClose} >
            注文キャンセル依頼却下
          </MDBModalHeader>
          <MDBModalBody>
            キャンセル依頼を却下します。よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.cancelRejectClose} color="secondary">戻る</MDBBtn>
            <MDBBtn onClick={this.cancelRejectClose} color="danger" data-index={this.state.cancelRejectIndex}>キャンセル依頼を却下する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.returnModal} toggle={this.returnClose}>
          <MDBModalHeader toggle={this.returnClose} >
            返品依頼承認
          </MDBModalHeader>
          <MDBModalBody>
            返品依頼を承認します。 よろしいですか?
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.returnClose} color="secondary">戻る</MDBBtn>
            <MDBBtn onClick={this.returnClose} color="danger" data-index={this.state.returnIndex}>返品依頼を承認する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.returnRejectModal} toggle={this.returnRejectClose}>
          <MDBModalHeader toggle={this.returnRejectClose} >
            返品依頼却下
          </MDBModalHeader>
          <MDBModalBody>
            返品依頼を却下します。 よろしいですか?
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.returnRejectClose} color="secondary">戻る</MDBBtn>
            <MDBBtn onClick={this.returnRejectClose} color="danger" data-index={this.state.returnRejectIndex}>返品依頼を却下する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <div className="p-3">

          <div className="form-group row mt-0">
            <label className="col-sm-2 p-2 bg-light">発注番号</label>
            <div className="col-sm-10 p-2" style={{overflowWrap: 'anywhere'}}>
              {cartData.orderNo}
            </div>

            <label className="col-sm-2 p-2 bg-light">発注日時</label>
            <div className="col-sm-10 p-2">
              {orderDate}
            </div>

            <label className="col-sm-2 p-2 bg-light">更新日時</label>
            <div className="col-sm-10 p-2">
              {date}
            </div>

            <label className="col-sm-2 p-2 bg-light">発注者</label>
            <div className="col-sm-10 p-2">
              {this.props.org.display_name} {this.state.data['fromUserName']}
            </div>

            <label className="col-sm-2 p-2 bg-light">納品場所</label>
            <div className="col-sm-10 p-2">
              {cartData['orderBasyo'] == '登録住所' &&
                <>
                (登録住所)<br />
                <br />
                {this.props.org.zipcode}<br />
                {this.props.org.address}<br />
                {this.props.org.display_name}<br />
                受取担当者：{this.state.data['fromUserName']}<br />
                電話番号：{this.props.org.TEL}
                </>
              }
              {cartData['orderBasyo'] == '登録住所以外' &&
                <>
                  (登録住所以外)<br />
                  {cartData['orderZipcode']}<br />
                  {cartData['orderAddr1']}<br />
                  {cartData['orderAddr2']}<br />
                  {cartData['orderJigyosyo']}<br />
                  受取担当者：{cartData['orderTanto']}<br />
                  電話番号：{cartData['orderPhone']}<br/>
                  {/*image: {cartData['orderFiles1'] && <StorageLink src={cartData['orderFiles1']} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                  {/*{cartData['orderFiles2'] && <StorageLink src={cartData['orderFiles2']} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                </>
              }
              {cartData['orderBasyo'] == '前回の納品先' &&
                  <>
                    (前回の納品先)<br/>
                    {deliveryAddress.propertyName}<br />
                    {deliveryAddress.postCode}<br />
                    {deliveryAddress.address1}<br />
                    {deliveryAddress.address2}<br />
                    {deliveryAddress.officeName}<br />
                    受取担当者：{deliveryAddress.personInCharge}<br />
                    電話番号：{deliveryAddress.phoneNumber}<br/>
                    {/*image: {deliveryAddress.files1 && <StorageLink src={deliveryAddress.files1} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                    {/*{deliveryAddress.files2 && <StorageLink src={deliveryAddress.files2} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                  </>
              }
              {cartData['orderBasyo'] == 'アドレス帳から選択する' &&
                  <>
                    (アドレス帳から選択する)<br/>
                    {deliveryAddress.propertyName}<br />
                    {deliveryAddress.postCode}<br />
                    {deliveryAddress.address1}<br />
                    {deliveryAddress.address2}<br />
                    {deliveryAddress.officeName}<br />
                    受取担当者：{deliveryAddress.personInCharge}<br />
                    電話番号：{deliveryAddress.phoneNumber}<br/>
                    {/*image: {deliveryAddress.files1 && <StorageLink src={deliveryAddress.files1} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                    {/*{deliveryAddress.files2 && <StorageLink src={deliveryAddress.files2} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                  </>
              }
              {cartData['orderBasyo'] === '販売側からお助けカートで指定された納品先' &&
                  <>
                    (販売側からお助けカートで指定された納品先)<br/>
                    {otasukeDeliveryAddress.propertyName}<br />
                    {otasukeDeliveryAddress.postCode}<br />
                    {otasukeDeliveryAddress.address1}<br />
                    {otasukeDeliveryAddress.address2}<br />
                    {otasukeDeliveryAddress.officeName}<br />
                    受取担当者：{otasukeDeliveryAddress.personInCharge}<br />
                    電話番号：{otasukeDeliveryAddress.phoneNumber}<br/>
                    {/*image: {otasukeDeliveryAddress.files1 && <StorageLink src={otasukeDeliveryAddress.files1} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                    {/*{otasukeDeliveryAddress.files2 && <StorageLink src={otasukeDeliveryAddress.files2} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                  </>
              }
              {cartData['orderBasyo'] == '納品先を直接入力する' &&
                  <>
                    (納品先を直接入力する)<br/>
                    {cartData['orderPropertyName']}<br />
                    {cartData['orderZipcode']}<br />
                    {cartData['orderAddr1']}<br />
                    {cartData['orderAddr2']}<br />
                    {cartData['orderJigyosyo']}<br />
                    受取担当者：{cartData['orderTanto']}<br />
                    電話番号：{cartData['orderPhone']}<br/>
                    {/*image: {cartData['orderFiles1'] && <StorageLink src={cartData['orderFiles1']} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                    {/*{cartData['orderFiles2'] && <StorageLink src={cartData['orderFiles2']} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                  </>
              }
            </div>

            <label className="col-sm-2 p-2 bg-light">希望納期</label>
            <div className="col-sm-10 p-2">
              {cartData['orderNouki'] == '最短' ? '最短' : cartData['orderNouki'] == '希望' ?
                <>
                {cartData['orderNoukiDay']}日
                </> :
                <>
                  {orderNoukiDate}
                </>
              }
            </div>

            <label className="col-sm-2 p-2 bg-light">備考</label>
            <div className="col-sm-10 p-2">
              {nl2br(cartData['orderBikou'])}
            </div>

            </div>

          </div>


        <div id="wrapper1" style={{height: 20, overflow: 'auto'}}>
          <div id="div1" style={{height: 20}}>
          </div>
        </div>
        <MDBTable btn responsive className="orderUrigawa" id={"div2"}>
          <MDBTableHead color="primary-color" textWhite columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>

        <MDBTable className="mt-5">
          <MDBTableBody>
            <tr>
              <th>運賃</th>
              <td className="text-right">{postage.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>小計</th>
              <td className="text-right">{mainTotal.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>キャンセル</th>
              <td className="text-right">{cancelTotal ? '-' : ''}{cancelTotal.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>返品</th>
              <td className="text-right">{returnTotal.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>消費税額計</th>
              <td className="text-right">{parseInt((Object.values(taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10).toLocaleString()}円</td>
            </tr>
            {Object.keys(taxes).sort((a, b) => { return b-a }).map((value, index) => {
              return <tr key={index}>
                <th>&nbsp; 消費税{value*100}%</th>
                <td className="text-right">{parseInt((taxes[value]),10).toLocaleString()}円</td>
              </tr>
            })}
            {/*<tr>*/}
            {/*  <th>消費税10%</th>*/}
            {/*  <td className="text-right">{parseInt((total * 0.1),10).toLocaleString()}円</td>*/}
            {/*</tr>*/}
            <tr>
              <th>合計</th>
              <td className="text-right">{parseInt((total + parseInt((Object.values(taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10)),10).toLocaleString()}円</td>
            </tr>
          </MDBTableBody>
        </MDBTable>
        { cart.length>0 &&
        <div>

          {/*{(this.props.user && this.props.user.authorizer || this.props.user && this.props.user.id === this.state.data.toUser) && this.state.data.divideConfirm === false && <div style={{textAlign: 'center'}}>*/}
          {/*  <MDBBtn className={'btn-lg grayBtn'} color={''} style={{minWidth: 200}} onClick={() => this.cancelMainDivide()}>分納手続中のすべての分納をやめる</MDBBtn>*/}
          {/*  <MDBBtn className={'btn-lg'} style={{minWidth: 200}} disabled={this.checkDividedAllItemCount()} onClick={() => this.confirmDivide()}>分納確定</MDBBtn>*/}
          {/*</div>}*/}

          { visited ?
          <div
            className="text-center p-3 mb-2 grey text-dark"
          >受注確認済み</div>
          :
          <MDBBtn
            className="btn-lg btn-block"
            color="success"
            disabled={!isEnable}
            onClick={this.visitedOrder}
            data-id={this.state.data.id}
            >受注確認</MDBBtn>
          }

          {false && <>
          <h4 className="bg-info p-3 mt-3 text-white">納期回答 <span className="font-small" >(相手先画面に表示されます)</span></h4>
          <div className="d-md-flex flex-row mt-3 align-items-center">
            <div className="p-2">納期予定</div>
            <div className="p-2">
              <DatePicker
                dateFormat="yyyy/MM/dd"
                id="estimateDate"
                className="form-control"
                selected={this.state.noukiSDate}
                onChange={this.handleNoukiSDateChange}
                locale="ja-JP"
              />
            </div>
            <div className="p-2">から</div>
            <div className="p-2">
              <DatePicker
                dateFormat="yyyy/MM/dd"
                id="estimateDate"
                className="form-control"
                selected={this.state.noukiEDate}
                onChange={this.handleNoukiEDateChange}
                locale="ja-JP"
              />
            </div>
            <div className="p-2">まで</div>
            <MDBBtn
              className="ml-auto btn-block col-md-2"
              color="success"
              onClick={this.handleNoukiDateUpdate}
              data-id={this.state.data.id}
            >登録</MDBBtn>
          </div>


          <h4 className="bg-info p-3 mt-3 text-white">送り状番号 <span className="font-small" >(相手先画面に表示されます)</span></h4>
            <div className="d-md-flex flex-row mt-3 align-items-center">
            <div className="p-2">送り状番号</div>
            <div className="p-2">
              <input
                type="text"
                className="form-control"
                id="orderNo"
                placeholder=""
                onChange={this.handleOkurijyoNoChange}
                value={this.state.okurijyoNo}
              />
            </div>
            <MDBBtn
              className="ml-auto btn-block col-md-2"
              color="success"
              onClick={this.handleOkurijyoNoUpdate}
              data-id={this.state.data.id}
            >送信</MDBBtn>
          </div>

          </>}


        </div>
        }
        <NoukiModal isOpen={this.state.noukiModal}　closeHandler={this.noukiModalClose} />
        <SyukkaModal isOpen={this.state.syukkaModal}　closeHandler={this.syukkaModalClose} />
      </div>
    );
  }
}

export default OrderUrigawa;
