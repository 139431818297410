import React, { Component } from 'react';
import { Link } from "react-router-dom";
import {
  MDBModal,
  MDBModalBody,
  MDBModalHeader,
  MDBModalFooter,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBBtn,
  MDBTable,
  MDBTableBody,
  MDBTableHead,
  MDBChip
} from 'mdbreact';
import firebase from "../../firebase"

import "react-datepicker/dist/react-datepicker.css";
import "./Order.css";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import { Alert, Button, Space } from 'antd';

import autoTable from "jspdf-autotable";
import { toast, ToastContainer } from 'react-toastify';
import _ from "lodash";

const nl2br = require('react-nl2br');

let MeiryoRegular;
let MeiryoBold;
let downloadClicked = false;

class OrderKaigawa extends Component {
  constructor(props) {
    super(props);
    this.selectorRef = React.createRef(null);

    this.state = {
      data: {},
      noukiSDate: '',
      noukiEDate: '',
      okurijyoNo: '',
      modal: false,
      loadingPDF: false,
      pdfModal: false,
      dataPDF: {
        deliveryAddress: {}
      },
      fileNamePDF: '',
      showMenu: '',
      showMenuIndex: null,
      cancelModal: false,
      cancelId: null,
      cancelIndex: null,
      returnModal: false,
      returnId: null,
      returnIndex: null,
      showToast: false
    }
  }


  getCarts = async () => {
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(this.props.org && collection){

      // this.unsub = this.props.db.collection("orders").doc(this.props.id).onSnapshot({
      //   error: e => console.log('e',e),
      //   next: snapshot => {
      //     let data = snapshot.data();
      //     data.id = snapshot.id;
      //     console.log('data', snapshot.data());

          let o = await this.props.db.collection('orders').doc(this.props.id).get();
          let data = o.data();


          // const data = doc.data();
          data.id = o.id;

          if(data.fromOrganization != this.props.userOrg.id ||
              data.toOrganization != this.props.org.id
          ){
            return;
          }

          let noukiSDate = '';
          let noukiEDate = '';
          let okurijyoNo = '';

          if(data.nouki){
            noukiSDate = data.nouki.slice(-1)[0].sdate ? data.nouki.slice(-1)[0].sdate.toDate() : '';
            noukiEDate = data.nouki.slice(-1)[0].edate ? data.nouki.slice(-1)[0].edate.toDate() : '';
          }
          if(data.okurijyoNo){
            okurijyoNo = data.okurijyoNo.slice(-1)[0].value;
          }

          let cart = data.cart;
          if (!data.newApply && !data.newReject) {
            delete cart['deletedItem'];
            Promise.all(cart.items.map(async (item) => {
              let itemSell = await collection.doc(item.id).get();
              let itemSellData = itemSell.data();

              delete item['deletedItem'];
              delete item['deletedText'];

              if (!itemSellData) {
                cart.deletedItem = true;
                item.deletedItem = true;
                item.deletedText = '販売終了';
              } else if (itemSellData && !itemSellData['price'] ||  itemSellData && itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['price']) {
                cart.deletedItem = true;
                item.deletedItem = true;
                item.deletedText = '要見積';
              } else if (itemSellData['price'] && itemSellData['price'][0] && !itemSellData['price'][0]['expiration_date']) {
                cart.deletedItem = true;
                item.deletedItem = true;
                item.deletedText = '要見積';
              } else if (itemSellData && itemSellData['price'] && itemSellData['price'][0] && itemSellData['price'][0]['expiration_date']) {
                let expiration_date = itemSellData['price'][0]['expiration_date'];
                let expDate = expiration_date.toDate();

                if ((expDate.getTime() - this.props.dateNow) < 0) {
                  cart.deletedItem = true;
                  item.deletedItem = true;
                  item.deletedText = '要見積';
                } else {
                  item.price = itemSellData['price'][0]['price'];
                }
              } else {
                item.price = itemSellData['price'][0]['price'];
              }
            }));
          }

          this.setState({
            data: data,
            detectChange: false,
            noukiSDate: noukiSDate,
            noukiEDate: noukiEDate,
            okurijyoNo: okurijyoNo,
          }, () => {
            let wrapper1 = document.getElementById('wrapper1');
            let div1 = document.getElementById('div1');
            let wrapper2 = document.getElementsByClassName('table-responsive')[0];
            let div2 = document.getElementById('div2');

            if (wrapper1 && div1 && wrapper2 && div2) {
              div1.style.width = div2.offsetWidth+'px';

              wrapper1.onscroll = function() {
                wrapper2.scrollLeft = wrapper1.scrollLeft;
              };
              wrapper2.onscroll = function() {
                wrapper1.scrollLeft = wrapper2.scrollLeft;
              };
            }
            this.visitedOrder();
          })
          // this.visitedOrder();
        // }
      // })


    }
  }

  componentDidUpdate = (prevProps) => {
    if (this.props.org !== prevProps.org) {
      this.getCarts();
    }
  }

  componentDidMount() {
    this.getCarts();
    this.loadFont();
    this.changeListener();
  }

  componentWillUnmount() {
    this.unsub();
  }

  changeListener = () => {
    this.unsub = this.props.db.collection("orders").doc(this.props.id).onSnapshot({
      error: e => console.log('e',e),
      next: snapshot => {
        if (Object.keys(this.state.data).length > 0) {
          let data1 = snapshot.data();
          let changeDate1 = data1.changeDate;

          let data = this.state.data;
          let changeDate = data.changeDate;

          // let isEqual = _.isEqual(items, items1);
          // console.log(changeDate, changeDate1, this.state.showToast)
          if (changeDate !== changeDate1 && !this.state.showToast) {
            this.setState({ showToast: true }, () => {
              toast.info(({closeToast}) => <div style={{display: 'flex', flexDirection: 'column'}}>
                <div>この注文データが更新されました。<br/>更新ボタンをクリックして最新のデータを読み込んでください。</div>
                <Button size="small" type="primary" style={{alignSelf: 'flex-end', backgroundColor: '#3498db'}} onClick={() => {this.getCarts(); closeToast()}}>更新</Button>
              </div>, {
                position: "top-right",
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                onClose: (e) => this.setState({showToast: false})
              });
            });
          }
        }

      }
    });
  }

  loadFont = () => {
    Promise.all([import(/* webpackIgnore: true */ '/fontsModule/MeiryoRegular.js'), import(/* webpackIgnore: true */ '/fontsModule/MeiryoBold.js')])
        .then(([module1, module2]) => {
          MeiryoRegular = module1.default;
          MeiryoBold = module2.default;

          if (downloadClicked === true) {
            this.showPdf()
          }
        })
  }

  visitedOrder = (e) => {
    let esId = this.state.data.id;

    let ordersRef = this.props.db.collection('orders').doc(esId);

    let ordersRefSet = {
      visited: {[this.props.user.id]: true},
    }

    // if (this.state.data.readBuyer === false) {
      let data = this.state.data;
      let cart = data.cart;

      let withComment = false;

      for (let i=0; i<cart.items.length; i++) {
        if (cart.items[i].readComment === false) {
          cart.items[i].readComment = true;
        }

        if (cart.items[i].okurijyoComment || cart.items[i].noukiComment) {
          withComment = true;
        }
      }

      ordersRefSet.cart = cart;

      if (withComment === true) {
        ordersRefSet.readComment = true;
      }
      // this.props.readOrder(1);

      // console.log('visit', ordersRefSet)
    // }
    ordersRef.set(ordersRefSet, { merge: true });

    if(this.props.user.authorizer){
      ordersRef.set({
        authorizer_visited: true,
      }, { merge: true });
    }
  }

  applyFile = (e) => {
    let esId = this.state.data.id;
    this.setState({
      applyModal: true,
      applyId: esId,
    })
  }
  rejectFile = (e) => {
    let esId = this.state.data.id;
    this.setState({
      rejectModal: true,
      rejectId: esId,
    })
  }
  applyClose = async (e) => {
    this.setState({
      applyModal: false,
      applyId: null,
    });

    let esId = e.currentTarget.dataset.id;
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(esId && collection){
      let ordersRef = this.props.db.collection('orders').doc(esId);

      let o = await ordersRef.get();
      let data = o.data();
      let cart = data.cart || {};

      let notifText = '';
      if (data['apply'] && Object.keys(data['apply']).length > 0) {
        notifText = `${data['applyUserName']}はすでに適用されています`;
      }

      if (data['reject'] && Object.keys(data['reject']).length > 0) {
        notifText = `${data['rejectUserName']}はすでに拒否されています`;
      }

      if (notifText) {
        toast.warn(notifText, {
          closeButton: true
        });

        return;
      }

      await Promise.all(cart.items.map(async (item) => {
        let itemSell = await collection.doc(item.id).get();
        let itemSellData = itemSell.data();
        if (itemSellData && itemSellData['price']) {
          item.price = itemSellData['price'][0]['price'];
        }
      }));


      let username = this.props.user.last_name + ' '+ this.props.user.first_name
      await ordersRef.set({
        apply: {[this.props.user.id]: true},
        applied: true,
        newApply: true,
        cart,
        applyUserName: username,
        orderDate: firebase.firestore.Timestamp.fromDate(new Date()),
        latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        sortDate: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true });

      let cartMainRef = this.props.db.collection('carts').doc(data.fromUser);
      cartMainRef.set({
        dummy: 'test'
      }, {merge: true})

      let cartRef = cartMainRef.collection('carts').doc(data.toOrganization);

      let deliveryAddress = {}
      if (cart.orderBasyo === '前回の納品先' || cart.orderBasyo === 'アドレス帳から選択する') {
        deliveryAddress = cart.deliveryAddress;
      }

      if (cart.orderBasyo === '納品先を直接入力する') {
        deliveryAddress = {
          propertyName: cart.orderPropertyName,
          postCode: cart.orderZipcode,
          address1: cart.orderAddr1,
          address2: cart.orderAddr2,
          officeName: cart.orderJigyosyo,
          personInCharge: cart.orderTanto,
          phoneNumber: cart.orderPhone,
          files1: cart.orderFiles1,
          files2: cart.orderFiles2
        }
      }

      if (cart.orderBasyo === '販売側からお助けカートで指定された納品先' &&
          Object.keys(cart.otasukeDeliveryAddress || {}).length > 0 &&
          cart.otasukeDeliveryAddress.postCode &&
          cart.otasukeDeliveryAddress.address1 &&
          cart.otasukeDeliveryAddress.address2 &&
          cart.otasukeDeliveryAddress.officeName &&
          cart.otasukeDeliveryAddress.personInCharge &&
          cart.otasukeDeliveryAddress.phoneNumber
      ) {
        deliveryAddress = cart.otasukeDeliveryAddress
      }

      let setWithMerge = cartRef.set({
        [data.cartNo]: {},
      }, { merge: true });

      let cartInfoRef = this.props.db.collection('carts_info').doc(data.fromUser)
          .collection('carts_info').doc(data.toOrganization);

      if (Object.keys(deliveryAddress).length > 0) {
        cartInfoRef.set({
          lastDeliveryAddress: deliveryAddress,
          update: firebase.firestore.Timestamp.fromDate(new Date())
        }, {merge: true})
      }

      this.getCarts();
    }


  }
  rejectClose = async (e) => {
    this.setState({
      rejectModal: false,
      rejectId: null,
    });

    let esId = e.currentTarget.dataset.id;
    let collection;
    if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.maker) {
      // manufacturer
      collection = this.props.db.collection('items_stock').doc(this.props.userOrg.id).collection('buyers').doc(this.props.org.id).collection('items')
    } else if(this.props.org && this.props.org.maker && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/Purchase mode
      collection = this.props.db.collection('items_stock').doc(this.props.org.id).collection('buyers').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.reseller && this.props.userOrg && this.props.userOrg.buyer) {
      // purchasing user
      collection = this.props.db.collection('items_sell').doc(this.props.userOrg.id).collection('items')
    } else if(this.props.org && this.props.org.buyer && this.props.userOrg && this.props.userOrg.reseller) {
      // Distributor/sales mode
      collection = this.props.db.collection('items_sell').doc(this.props.org.id).collection('items')
    }

    if(esId && collection){
      let ordersRef = this.props.db.collection('orders').doc(esId);
      let o = await ordersRef.get();
      let data = o.data();
      let cart = data.cart || {};

      let notifText = '';
      if (data['apply'] && Object.keys(data['apply']).length > 0) {
        notifText = `${data['applyUserName']}はすでに適用されています`;
      }

      if (data['reject'] && Object.keys(data['reject']).length > 0) {
        notifText = `${data['rejectUserName']}はすでに拒否されています`;
      }

      if (notifText) {
        toast.warn(notifText, {
          closeButton: true
        });

        return;
      }

      await Promise.all(cart.items.map(async (item) => {
        let itemSell = await collection.doc(item.id).get();
        let itemSellData = itemSell.data();
        if (itemSellData && itemSellData['price']) {
          item.price = itemSellData['price'][0]['price'];
        }
      }));

      let username = this.props.user.last_name + ' '+ this.props.user.first_name
      await ordersRef.set({
        reject: {[this.props.user.id]: true},
        rejectUserName: username,
        newReject: true,
        cart,
        rejectDate: firebase.firestore.Timestamp.fromDate(new Date()),
        latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        sortDate: firebase.firestore.Timestamp.fromDate(new Date()),
      }, { merge: true });

      let cartMainRef = this.props.db.collection('carts').doc(data.fromUser);
      cartMainRef.set({
        dummy: 'test'
      }, {merge: true})

      let cartRef = cartMainRef.collection('carts').doc(data.toOrganization);
      let setWithMerge = cartRef.set({
        [data.cartNo]: {
          requested: false,
          rejected: true,
        },
      }, { merge: true });

      this.getCarts();
    }

  }

  returnModalOpen = (index) => {
    this.setState({
      showMenu: null,
      showMenuIndex: null,
      returnModal: true,
      returnIndex: index
    })
  }

  returnClose = async (e) => {
    let index = e && e.currentTarget.dataset.index;
    this.setState({
      returnModal: false,
      returnId: null,
    });

    if (index !== undefined) {
      let data = this.state.data;
      let cart = this.state.data.cart;
      let items = cart.items || [];

      // let index = items.findIndex(v => v.id === id);

      let returnDate = firebase.firestore.Timestamp.now();
      let returnBy = this.props.user.id;
      let returnByUser = this.props.user.last_name + ' '+ this.props.user.first_name;
      // let returnConfirm = false;

      let item = items[index];
      item.returnDate = returnDate;
      item.returnBy = returnBy;
      item.returnByUser = returnByUser;
      item.returnConfirm = false;
      item.returnReject = false;

      let dateTime = new Date().getTime();
      data.changeDate = dateTime;

      this.setState({data}, () => {
        let ordersRef = this.props.db.collection('orders').doc(data.id);
        ordersRef.set({
          cart: data.cart,
          returnDate: returnDate,
          returnBy: returnBy,
          returnByUser: returnByUser,
          returnConfirmed: !!data.returnConfirmed,
          returnRejected: !!data.returnRejected,
          returnWait: true,
          changeDate: dateTime,
          latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        }, { merge: true });
      })

      function n(n){
        return n > 9 ? "" + n: "0" + n;
      }

      let returnDateFormatted = '';
      if (returnDate) {
        returnDateFormatted = returnDate.toDate().getFullYear() + '/'
            + n(returnDate.toDate().getMonth()+1) + '/'
            + n(returnDate.toDate().getDate()) + ' '
            + n(returnDate.toDate().getHours()) + ':'
            + n(returnDate.toDate().getMinutes());
      }

      let apply = Object.keys(data.apply);

      let orderId = data.id;
      firebase.functions().httpsCallable('sendReturnEmail')({
        orderNo: data.cart.orderNo,
        returnDate: returnDateFormatted,
        returnBy,
        returnByUser,
        fromUser: data.fromUser,
        apply: apply,
        toUser: data.toUser,
        toUserCC: data.toUserCC,
        itemName: item.name,
        toOrganization: data.toOrganization,
        fromOrganization: data.fromOrganization,
        orderId
      });
    }
  }

  cancelModalOpen = (index) => {
    this.setState({
      showMenu: null,
      showMenuIndex: null,
      cancelModal: true,
      cancelIndex: index,
    });
  }

  cancelClose = async (e) => {
    let index = e && e.currentTarget.dataset.index;
    this.setState({
      cancelModal: false,
      cancelId: null,
    });

    // console.log('index', index);

    if (index !== undefined) {
      let data = this.state.data;
      let cart = this.state.data.cart;
      let items = cart.items || [];

      let cancelDate = firebase.firestore.Timestamp.now();
      let cancelBy = this.props.user.id;
      let cancelByUser = this.props.user.last_name + ' '+ this.props.user.first_name;

      let item = items[index];
      item.cancelDate = cancelDate;
      item.cancelBy = cancelBy;
      item.cancelByUser = cancelByUser;
      item.cancelConfirm = false;
      item.cancelReject = false;

      let dateTime = new Date().getTime();
      data.changeDate = dateTime;

      this.setState({data}, () => {
        let ordersRef = this.props.db.collection('orders').doc(data.id);
        ordersRef.set({
          cart: data.cart,
          cancelDate: cancelDate,
          cancelBy: cancelBy,
          cancelByUser: cancelByUser,
          cancelConfirmed: !!data.cancelConfirmed,
          cancelRejected: !!data.cancelRejected,
          cancelWait: true,
          changeDate: dateTime,
          latestUpdate: firebase.firestore.Timestamp.fromDate(new Date()),
        }, { merge: true });
      })

      function n(n){
        return n > 9 ? "" + n: "0" + n;
      }

      let cancelDateFormatted = '';
      if (cancelDate) {
        cancelDateFormatted =cancelDate.toDate().getFullYear() + '/'
            + n(cancelDate.toDate().getMonth()+1) + '/'
            + n(cancelDate.toDate().getDate()) + ' '
            + n(cancelDate.toDate().getHours()) + ':'
            + n(cancelDate.toDate().getMinutes());
      }

      let apply = Object.keys(data.apply);

      let orderId = data.id;
      let a = await firebase.functions().httpsCallable('sendCancelEmail')({
        orderNo: data.cart.orderNo,
        cancelDate: cancelDateFormatted,
        cancelBy,
        cancelByUser,
        fromUser: data.fromUser,
        apply: apply,
        toUser: data.toUser,
        toUserCC: data.toUserCC,
        itemName: item.name,
        toOrganization: data.toOrganization,
        fromOrganization: data.fromOrganization,
        orderId
      });

      console.log('a', a)

      // console.log('cancel request', {
      //   orderNo: data.cart.orderNo,
      //   cancelDate: cancelDateFormatted,
      //   cancelByUser,
      //   itemName: item.name,
      //   toOrganization: data.toOrganization,
      //   fromOrganization: data.fromOrganization,
      //   orderId
      // })
    }
  }

  pdfSave = () => {

    // const input = document.getElementById('divToPrint');
    html2canvas(this.selectorRef.current, {scale: 2})
        .then((canvas) => {

          var imgData = canvas.toDataURL('image/png');
          var imgWidth = 210;
          var pageHeight = 295;
          var pageInnerHeight = 275;
          var imgHeight = canvas.height * imgWidth / canvas.width;
          var heightLeft = imgHeight;
          var doc = new jsPDF('p', 'mm', 'a4', true);
          var position = 10; // give some top padding to first page

          doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
          heightLeft -= pageHeight;

          while (heightLeft >= 0) {
            // position += heightLeft - imgHeight; // top padding for other pages
            position = heightLeft - imgHeight + 10
            // console.log('position', position)
            doc.addPage();
            doc.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;
          }
          doc.save( this.state.fileNamePDF+'.pdf');

          // window.open(doc.output('bloburl'));
          // pdf.save("download.pdf");
        })
  }

  toggle = () => {
    this.setState({
      pdfModal: !this.state.pdfModal
    });
  }

  showPdf = async () => {
    this.setState({loadingPDF: true});

    if (!MeiryoRegular || !MeiryoBold) {
      downloadClicked = true;
      return;
    }

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    let sellerOrg = this.props.org;
    let buyerOrg = this.props.userOrg;
    console.log('buyerOrg', buyerOrg, sellerOrg)
    let data = this.state.data;
    let cart = this.state.data.cart;
    let items = cart.items || [];
    let fileNamePDF = '';
    let deliveryAddress = {};
    let applyId = '';
    let mainInstallmentStatus = '分納手続中';

    if (data.apply) {
      applyId = Object.keys(data.apply)[0];
    }

    let orderNoukiDate = '';
    if (cart.orderNouki == '日付指定') {
      orderNoukiDate = cart.orderNoukiDate ? cart.orderNoukiDate.toDate().getFullYear() + '/'
          + n(cart.orderNoukiDate.toDate().getMonth()+1) + '/'
          + n(cart.orderNoukiDate.toDate().getDate()) + '' : '';
    }

    let toUserData = await firebase.functions().httpsCallable('getUserEmail')({uid: data.toUser})

    let date;
    if (data.orderDate) {
      date = data.orderDate
    } else {
      date = data.created;
    }
    let orderDate = date.toDate().getFullYear() + '年'
        + n(date.toDate().getMonth() + 1) + '月'
        + n(date.toDate().getDate()) + '日 '
        + n(date.toDate().getHours()) + ':'
        + n(date.toDate().getMinutes());

    let nDate = date.toDate().getFullYear() + ''
        + n(date.toDate().getMonth() + 1) + ''
        + n(date.toDate().getDate()) + ''
        + n(date.toDate().getHours()) + ''
        + n(date.toDate().getMinutes()) + ''
        + n(date.toDate().getSeconds());

    let divideDate;
    let divideCount = items.filter(v => v.subDivided === true);
    if (divideCount.length > 0) {
      if (data.dividedDate) {
        divideDate = data.dividedDate;
        if (this.state.algolia) {
          divideDate = new Date(divideDate._seconds * 1000 + divideDate._nanoseconds/1000000);
        } else {
          divideDate = divideDate.toDate();
        }

        divideDate = divideDate.getFullYear() + ''
            + n(divideDate.getMonth() + 1) + ''
            + n(divideDate.getDate()) + ''
            + n(divideDate.getHours()) + ''
            + n(divideDate.getMinutes()) + ''
            + n(divideDate.getSeconds());
      }
    }

    let no = nDate+'-'+data.id;

    //divided item-g xasax
    items = items.filter(v => v.divided !== true);

    let totalAmount = 0;
    let mainAmount = 0;
    let cancelAmount = 0;
    let returnAmount = 0;
    let totalTax = 0;
    let taxes = {};
    let totals = {};
    let subDivideCount = 0;
    let subDivideConfirmCount  = 0;
    cart.items.forEach((item) => {
      if (item.subDivided) {
        subDivideCount += 1;
        if (item.divideConfirm === true) {
          subDivideConfirmCount += 1;
        }
      }
      if (!item.divided) {
        if (item.returnConfirm) {
          returnAmount += (item.count * item.price);
        }
        if (item.cancelConfirm) {
          cancelAmount += (item.count * item.price);
        }
        if (!item.returnSub) {
          mainAmount += (item.count * item.price);
        }
        totalAmount += (item.cancelConfirm === true ? 0 : item.price*item.count);
        totalTax += parseInt((item.cancelConfirm === true ? 0 : item.count * item.price * item.tax), 10);

        if (taxes[item.tax]) {
          taxes[item.tax] += (item.cancelConfirm === true ? 0 : item.count * item.price * item.tax);
          totals[item.tax] += (item.cancelConfirm === true ? 0 : item.count * item.price);
        } else {
          taxes[item.tax] = (item.cancelConfirm === true ? 0 : item.count * item.price * item.tax);
          totals[item.tax] = (item.cancelConfirm === true ? 0 : item.count * item.price);
        }
      }
    });

    if (subDivideCount === subDivideConfirmCount) {
      mainInstallmentStatus = '分納完了済';
    } else {
      mainInstallmentStatus = '分納手続中';
    }

    switch (cart.orderBasyo) {
      case '登録住所':
        deliveryAddress = {
          postCode: this.props.userOrg.zipcode,
          address1: this.props.userOrg.address,
          address2: '',
          officeName: this.props.userOrg.display_name,
          personInCharge: this.state.data['fromUserName'],
          phoneNumber: this.props.userOrg.TEL,
          propertyName: ""
        };
        break;
      case '登録住所以外':
        deliveryAddress = {
          postCode: cart.orderZipcode,
          address1: cart.orderAddr1,
          address2: cart.orderAddr2,
          officeName: cart.orderJigyosyo,
          personInCharge: cart.orderTanto,
          phoneNumber: cart.orderPhone,
          propertyName: ""
        }
        break;
      case '前回の納品先':
        deliveryAddress = cart.deliveryAddress;
        break;
      case 'アドレス帳から選択する':
        deliveryAddress = cart.deliveryAddress;
        break;
      case '販売側からお助けカートで指定された納品先':
        deliveryAddress = cart.otasukeDeliveryAddress;
        break;
      case '納品先を直接入力する':
        deliveryAddress = {
          postCode: cart.orderZipcode,
          address1: cart.orderAddr1,
          address2: cart.orderAddr2,
          officeName: cart.orderJigyosyo,
          personInCharge: cart.orderTanto,
          phoneNumber: cart.orderPhone,
          propertyName: cart.orderPropertyName
        }
        break;

    }

    // let sAddress = deliveryAddress.propertyName + ' ' + deliveryAddress.address1 + ' ' + deliveryAddress.address2 + ' ' + deliveryAddress.officeName;

    fileNamePDF = 'ORD-'+nDate+'-'+data.id;

    let dataPDF = {
      sellerZipcode: sellerOrg.zipcode,
      sellerAddress: sellerOrg.address,
      sellerFAX: sellerOrg.FAX,
      sellerTEL: sellerOrg.TEL,
      sellerMAIL: toUserData.data,
      sellerOrganizationsCompanyName: sellerOrg.company_name,
      sellerOrganizationsName: sellerOrg.name,

      buyerZipcode: buyerOrg.zipcode,
      buyerAddress: buyerOrg.address,
      buyerFAX: buyerOrg.FAX,
      buyerTEL: buyerOrg.TEL,
      buyerOrganizationsCompanyName: buyerOrg.company_name,
      buyerOrganizationsName: buyerOrg.name,

      toUserName: data.toUserName,
      fromUserName: data.fromUserName,
      applyUserName: data.applyUserName,
      applyId: applyId,

      orderNo: cart.orderNo,
      deliveryAddress: deliveryAddress,
      orderDate: orderDate,
      orderNouki: cart.orderNouki,
      orderNoukiDay: cart.orderNoukiDay,
      orderNoukiDate: orderNoukiDate,
      orderBikou: cart.orderBikou,
      mainInstallmentStatus: mainInstallmentStatus,
      no: no,
      items: items,
      totalAmount: totalAmount,
      cancelAmount: cancelAmount,
      returnAmount: returnAmount,
      mainAmount: mainAmount,
      totalTax: totalTax,
      taxes: taxes,
      totals: totals,
      divideDate: divideDate
    }

    this.setState({dataPDF: dataPDF, pdfModal: true, fileNamePDF, loadingPDF: false}, () => {
      this.pdfGenerator(fileNamePDF, dataPDF, false);
    });
  }

  addFooters = (doc, data) => {
    const pageCount = doc.internal.getNumberOfPages()

    doc.setFont('Meiryo', 'normal')
    doc.setFontSize(8)
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.text('Page ' + String(i) + '/' + String(pageCount), 15, 15);

      if (i > 1) {
        doc.text(`発注日 ${data.orderDate}\nNo.${data.no}`, 130, 15);
      }
    }
  }

  pdfGenerator = (fileName, data, download) => {
    let doc = new jsPDF('p', 'mm', 'a4', true);
    let top = 20;
    doc.setLineHeightFactor(1.15);
    doc.addFileToVFS("MeiryoRegular.ttf", MeiryoRegular);
    doc.addFileToVFS("MeiryoBold.ttf", MeiryoBold);
    doc.addFont("MeiryoRegular.ttf", "Meiryo", "normal");
    doc.addFont("MeiryoBold.ttf", "Meiryo", "bold");
    doc.setFont("Meiryo", 'normal');
    doc.setFontSize(8);
    doc.setFontSize(25);
    doc.text('発注書', 90, top);
    doc.setFontSize(8);

    if (data.divideDate) {
      doc.text(`（分納版 - ${data.divideDate})`, 195, 15, {align: 'right'});
      doc.text(`${data.mainInstallmentStatus}`, 179.5, 19);
      doc.roundedRect(178, 16.4, 17, 3.2, 0.5, 0.5);
    }

    doc.text(`〒${data.sellerZipcode}`, 15, top+20);
    doc.text(`${data.sellerAddress}`, 15, top+25);

    doc.text(`発注日 ${data.orderDate}`, 125, top+20);
    doc.text(`No.${data.no}`, 125, top+25);

    doc.setFont('Meiryo', 'bold');
    doc.text(`${data.sellerOrganizationsCompanyName} ${data.sellerOrganizationsName}`, 17, top+30);
    doc.text('御中', 110, top+30);
    doc.line(15, top+31, 110, top+31);

    doc.setFont('Meiryo', 'normal');
    doc.text(`${data.toUserName} 様`, 15, top+37);
    doc.text(`〒${data.buyerZipcode}`, 125, top+37);
    doc.text(`${data.buyerAddress}`, 125, top+42);
    doc.text(`${data.buyerOrganizationsCompanyName} ${data.buyerOrganizationsName}`, 125, top+50);
    doc.text(`TEL: ${data.buyerTEL}`, 125, top+55);
    doc.text(`FAX: ${data.buyerFAX}`, 170, top+55);
    doc.setFontSize(6);
    doc.text(`TEL: ${data.sellerTEL}`, 15, top+40);
    doc.text(`FAX: ${data.sellerFAX}`, 40, top+40);
    doc.text(`Mail: ${data.sellerMAIL}`, 15, top+43);
    doc.setFontSize(8);
    doc.text('以下の通り発注いたします。', 15, top+53);

    doc.text('〔本書に関するお問い合わせ〕', 125, top+63);
    let fromUserName = doc.splitTextToSize(`発注者 ${data.buyerOrganizationsCompanyName} ${data.buyerOrganizationsName} ${data.fromUserName}`, 70);
    let applyUserName = doc.splitTextToSize(`承認者 ${data.buyerOrganizationsCompanyName} ${data.buyerOrganizationsName} ${data.applyUserName || data.applyId}`, 70)
    doc.text(fromUserName, 125, top+68);
    doc.text(applyUserName, 125, top+78);

    top = top+63

    doc.text('発注番号:', 15, top);
    let text = `${data.orderNo}`.trim();
    let da = doc.splitTextToSize(text, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines = da.length;
    doc.text(da, 40, top);
    if (amountOfLines > 1) {
      top += doc.getTextDimensions(da)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('納品場所:', 15, top);
    let text1 = `〒${data.deliveryAddress.postCode}`.trim();
    let da1 = doc.splitTextToSize(text1, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines1 = da1.length;
    doc.text(da1, 40, top);
    if (amountOfLines1 > 1) {
      top += doc.getTextDimensions(da1)['h']-2;
    } else {
      top += 3;
    }

    let text2 = `${data.deliveryAddress.propertyName} ${data.deliveryAddress.address1} ${data.deliveryAddress.address2} ${data.deliveryAddress.officeName}`.trim();
    let da2 = doc.splitTextToSize(text2, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines2 = da2.length;
    doc.text(da2, 40, top);
    if (amountOfLines2 > 1) {
      top += doc.getTextDimensions(da2)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('受取担当者:', 15, top);
    let text3 = `${data.deliveryAddress.personInCharge} ${data.deliveryAddress.personInCharge && '様'}`.trim();
    let da3 = doc.splitTextToSize(text3, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines3 = da3.length;
    doc.text(da3, 40, top);
    if (amountOfLines3 > 1) {
      top += doc.getTextDimensions(da3)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('電話番号:', 15, top);
    let text4 = `${data.deliveryAddress.phoneNumber}`.trim();
    let da4 = doc.splitTextToSize(text4, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines4 = da4.length;
    doc.text(da4, 40, top);
    if (amountOfLines4 > 1) {
      top += doc.getTextDimensions(da4)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    top += 4;
    doc.text('希望納期:', 15, top);
    let orderNouki = data['orderNouki'] === '最短' ? '最短' : data['orderNouki'] === '希望' ? `${data['orderNoukiDay']}日` : `${data['orderNoukiDate']}`
    let da5 = doc.splitTextToSize(`${orderNouki}`, 75, {fontSize: 8, lineHeightFactor: 1.15});
    let amountOfLines5 = da5.length;
    doc.text(da5, 40, top);
    if (amountOfLines5 > 1) {
      top += doc.getTextDimensions(da5)['h']-2;
    } else {
      top += 1
    }
    doc.line(40, top, 115, top);

    autoTable(doc, {
      startY: top+8,
      head: [['税抜合計金額', 'キャンセル', '返品', '消費税額計', '今回発注額']],
      body: [
        [
          `${data.mainAmount.toLocaleString()}円`,
          `${data.cancelAmount ? '-' : ''}${data.cancelAmount.toLocaleString()}円`,
          `${data.returnAmount.toLocaleString()}円`,
          `${parseInt((Object.values(data.taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10).toLocaleString()}円`,
          `${(data.totalAmount + parseInt((Object.values(data.taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10)).toLocaleString()}円`],
      ],
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 27, halign: 'right' },
        1: { cellWidth: 27, halign: 'right' },
        2: { cellWidth: 27, halign: 'right' },
        3: { cellWidth: 27, halign: 'right' },
        4: { cellWidth: 27, halign: 'right' }
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain'
    });

    let posY = doc.lastAutoTable.finalY;
    // console.log('posY', posY);

    autoTable(doc, {
      startY: posY+8,
      head: [['課税対象額(10%)', '消費税(10%)', '課税対象額(8%)', '消費税(8%)']],
      body: [
        [
          `${(data.totals['0.1'] || 0).toLocaleString()}円`,
          `${parseInt((data.taxes['0.1'] || 0), 10).toLocaleString()}円`,
          `${(data.totals['0.08'] || 0).toLocaleString()}円`,
          `${parseInt((data.taxes['0.08'] || 0), 10).toLocaleString()}円`
        ],
      ],
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 33, halign: 'right' },
        1: { cellWidth: 34, halign: 'right' },
        2: { cellWidth: 34, halign: 'right' },
        3: { cellWidth: 34, halign: 'right' }
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain'
    });

    posY = doc.lastAutoTable.finalY;

    autoTable(doc, {
      startY: posY+8,
      head: [['備考', 'orderBikou']],
      showHead: false,
      body: [
        ['備考', `${data['orderBikou'] ? data['orderBikou'] : ''}`],
      ],
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 15, minCellHeight: 20, halign: 'center', valign: 'middle', fillColor: [189, 190, 191] },
        1: { cellWidth: 'auto', halign: 'left', valign: 'middle' },
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain'
    });

    posY = doc.lastAutoTable.finalY;

    const substringFullWidth = (str, limit) => {
      var l = str.length;
      var c = '';
      var res = '';
      var length = 0;
      for (var i = 0; i < l; i++) {
        c = str.charCodeAt(i);
        if (0x0000 <= c && c <= 0x0019) {
          // length 0
          // do nothing
        } else if (0x0020 <= c && c <= 0x1FFF) {
          // length 1
          length = length + 1;
        } else if (0x2000 <= c && c <= 0xFF60) {
          // length 2
          length = length + 2;
        } else if (0xFF61 <= c && c <= 0xFF9F) {
          // length 1
          length = length + 1;
        } else if (0xFFA0 <= c) {
          // length 2
          length = length + 2;
        }
        if (length <= limit) {
          res += str.charAt(i);
        }
        else {
          break;
        }
      }
      return res;
    }

    let no = 0;
    let subNum = 0;
    let subId = '';
    let itemDataBody = data.items.map((item, index) => {
      if (subId !== item.id) {
        subId = item.id;
        if (!item.returnSub) {
          no += 1;
        }
        if (item.subDivided) {
          subNum = 1;
        } else {
          subNum = 0;
        }
      } else {
        if (item.subDivided) {
          if (!item.returnSub) {
            subNum += 1;
          }
        } else {
          subNum = 0;
        }
      }

      let confirmStatus = '';
      if (item.subDivided) {
        confirmStatus = `分納手続中`;
        if (item.divideConfirm) {
          confirmStatus = `分納`;
        }
      }

      let cancelConfirmStatus = '';
      if (item.cancelConfirm) {
        cancelConfirmStatus = `キャンセル`;
      }

      let returnConfirmStatus = '';
      if (item.returnConfirm) {
        returnConfirmStatus = `返品`;
      }

      return [
        `${no}${item.returnSub ? '-1':''}${subNum > 0 ? `\n(${subNum}${item.returnSub ? '-1':''})` : ''}`,
        `${item.code ? item.code : ''}${item.subDivided ? `\n${confirmStatus}` : ''}${item.cancelConfirm ? `\n${cancelConfirmStatus}` : ''}${item.returnConfirm ? `\n${returnConfirmStatus}` : ''}`,
        `${item.maker}\n${substringFullWidth(item.name,156)}`,
        `${item.price.toLocaleString()}\n${item.count.toLocaleString()}`,
        `${(item.count * item.price).toLocaleString()}\n${item.tax*100}%`,
        // `${item.description ? substringFullWidth(item.description,87).trim() : ''}`
          ``
      ]
    });

    autoTable(doc, {
      startY: posY+8,
      margin: {top: 20},
      rowPageBreak: 'avoid',
      head: [['No.', '商品コード', 'メーカー名\n商品名', '単価(円)\n数量', '合計金額(円)\n税率', '備考']],
      body: itemDataBody,
      styles: {
        font: 'Meiryo',
        fontSize: 8
      },
      headStyles: {
        fillColor: [189, 190, 191],
        textColor: [0],
        halign: 'center',
        font: 'Meiryo',
        fontSize: 8,
        lineWidth: .1,
        lineColor: 'black'
      },
      columnStyles: {
        0: { cellWidth: 10, halign: 'center', valign: 'middle'},
        1: { cellWidth: 20, halign: 'left', valign: 'middle'},
        2: { cellWidth: 'auto', halign: 'left', valign: 'middle'},
        3: { cellWidth: 15, halign: 'right', valign: 'middle'},
        4: { cellWidth: 25, halign: 'right', valign: 'middle'},
        5: { cellWidth: 35, halign: 'left', valign: 'middle', minCellHeight: 15}
      },
      bodyStyles: {
        font: 'Meiryo',
        fontSize: 8,
        color: 0,
        lineWidth: .1,
        lineColor: 'black'
      },
      theme: 'plain',
      didDrawCell: (data) => {
        // console.log('celltext', data.cell.text)
        if ((data.cell.text[1] === '分納手続中' || data.cell.text[1] === '分納') || (data.cell.text[2] === '分納手続中' || data.cell.text[2] === '分納') || (data.cell.text[3] === '分納手続中' || data.cell.text[3] === '分納')) {
          let w = 16.6;
          if (data.cell.text[1] === '分納' || data.cell.text[2] === '分納' || data.cell.text[3] === '分納') {
            w = 8.5;
          }
          doc.roundedRect(data.cell.x+0.5, data.cell.y+data.cell.contentHeight+(data.cell.height-data.cell.contentHeight)/data.cell.text.length-(5.3 * (data.cell.text.length > 2 ? 1.6 : 1)), w, 3.2, 0.5, 0.5);
        }
        if ((data.cell.text[1] === 'キャンセル' || data.cell.text[1] === '返品') || (data.cell.text[2] === 'キャンセル' || data.cell.text[2] === '返品') || (data.cell.text[3] === 'キャンセル' || data.cell.text[3] === '返品')) {
          let w = 16.6;
          let text = 'キャンセル';
          if (data.cell.text[1] === '返品' || data.cell.text[2] === '返品' || data.cell.text[3] === '返品') {
            w = 8.5;
            text = '返品';
          }
          doc.roundedRect(data.cell.x+0.5, data.cell.y+data.cell.contentHeight+(data.cell.height-data.cell.contentHeight)/2-5.3, w, 3.2, 0.5, 0.5, 'F');
          doc.setTextColor('#fff');
          doc.setFontSize(8);
          doc.text(text, data.cell.x+2, data.cell.y+data.cell.contentHeight+(data.cell.height-data.cell.contentHeight)/2-2.6);
        }
      },
      willDrawCell: (d) => {
        if (d.section === 'body' && d.column.index === 5) {
          let desc = data.items[d.row.index]['description'] || '';
          let a =  doc.splitTextToSize(desc, 32);
          d.cell.text = a.slice(0,4);
        }
      }
    });

    this.addFooters(doc, data);

    if (download) {
      doc.save(`${fileName}.pdf`)
    } else {
      // console.log('fileName1', fileName, this.state.fileNamePDF);
      doc.setProperties({title: this.state.fileNamePDF});
      setTimeout(() => {
        document.getElementById("showPDF").data = doc.output('datauristring');
      }, 10);
    }

  }

  showMenu = (itemId, index) => {
    let obj;
    if (itemId === this.state.showMenu && index === this.state.showMenuIndex) {
      obj = {
        showMenu: null,
        showMenuIndex: null
      }
    } else {
      obj = {
        showMenu: itemId,
        showMenuIndex: index
      }
    }

    this.setState({showMenu: obj.showMenu, showMenuIndex: obj.showMenuIndex})
  }

  showCancelMenu = (order, item) => {
    // console.log('item',
    //     !item.divided,
    //     !(item.divided && item.divideConfirm === false),
    //     ((!(item.subDivided === true && item.divideConfirm === false) && item.cancelConfirm === undefined && item.returnConfirm === undefined) ||
    //         (!(item.subDivided === true && item.divideConfirm === false) && (item.cancelReject === true && item.cancelConfirm !== true && item.returnConfirm !== true || item.returnReject === true && item.cancelConfirm !== true && item.returnConfirm !== true))),
    //     !item.returnMain,
    //     item
    //     )
    // console.log('showCancelMenu', item, !item.divided && !(item.divideConfirm === false) && item.cancelConfirm === undefined && item.returnConfirm === undefined)
    // console.log(!item.divided , !(item.divided && item.divideConfirm === false), (item.cancelConfirm === undefined || item.cancelReject === true || item.returnReject === true || item.returnConfirm === undefined), !item.returnMain);
    if (!item.divided &&
        !(item.divided && item.divideConfirm === false) &&
        ((!(item.subDivided === true && item.divideConfirm === false) && item.cancelConfirm === undefined && item.returnConfirm === undefined) || (!(item.subDivided === true && item.divideConfirm === false) && (item.cancelReject === true && item.cancelConfirm !== true && item.returnConfirm !== true || item.returnReject === true && item.cancelConfirm !== true && item.returnConfirm !== true))) &&
        !item.returnMain &&
        !(item.cancelConfirm === false && item.cancelReject === false || item.returnConfirm === false && item.returnReject === false) && order.newApply
    ) {
      return true
    }
  }

  render() {
    if (!this.props.org) {
      return (
        <>
          取引先を選択してください。
        </>
      )
    }
    if (!this.state.data.id) {
      return (
        <>
        </>
      )
    }

    function n(n){
      return n > 9 ? "" + n: "0" + n;
    }

    // console.log('kaigawa');

    let cart = [];
    let total = 0;
    let mainTotal = 0;
    let postage = 0;
    let taxes = {};
    let taxTotal = 0;
    let cancelTotal = 0;
    let returnTotal = 0;

    let cartData = {
      items: [],
      orderNo: '',
      toUser: '',
      toUserName: '',
      orderNouki: '最短',
      orderNoukiDay: '',
      orderBasyo: '登録住所',
      orderZipcode: '',
      orderAddr1: '',
      orderAddr2: '',
      orderJigyosyo: '',
      orderTanto: '',
      orderPhone: '',
      orderBikou: '',
      requested: false,
    };
    if(this.state.data && this.state.data.cart && this.state.data.cart.items){
      Object.assign(cartData, this.state.data.cart);
      let items = this.state.data.cart.items;

      let no = 0;
      let subDividedNo = 1;
      let returnSubNo = 1;

      items.forEach((item, index) => {
        let date = item.update.toDate().getFullYear() + '年'
              + (item.update.toDate().getMonth() + 1) + '月'
              + item.update.toDate().getDate() + '日';

              let noukiSDate = '';
              let noukiEDate = '';
              let okurijyoNo = '';
              let hassouDate = '';
              let isShipped = false;

              if(item.nouki){
                noukiSDate = item.nouki.slice(-1)[0].sdate
                              ? item.nouki.slice(-1)[0].sdate.toDate().getFullYear() + '/'
                               + n(item.nouki.slice(-1)[0].sdate.toDate().getMonth()+1) + '/'
                               + n(item.nouki.slice(-1)[0].sdate.toDate().getDate())
                              : '';
                noukiEDate = item.nouki.slice(-1)[0].edate
                              ? item.nouki.slice(-1)[0].edate.toDate().getFullYear() + '/'
                               + n(item.nouki.slice(-1)[0].edate.toDate().getMonth()+1) + '/'
                               + n(item.nouki.slice(-1)[0].edate.toDate().getDate())
                              : '';
              }

              let okurijyoProcess = <div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#FF3547'}}/>;
              let okurijyoStatus = 'unShipped';

              if(item.okurijyoNo){
                okurijyoNo = item.okurijyoNo.slice(-1)[0].value;
                hassouDate = item.okurijyoNo.slice(-1)[0].hassouDate
                              ? item.okurijyoNo.slice(-1)[0].hassouDate.toDate().getFullYear() + '/'
                               + n(item.okurijyoNo.slice(-1)[0].hassouDate.toDate().getMonth()+1) + '/'
                               + n(item.okurijyoNo.slice(-1)[0].hassouDate.toDate().getDate())
                              : '';

                let latestUpdate = item.okurijyoNo.slice(-1)[0].latestUpdate;
                let shippingDate = item.okurijyoNo.slice(-1)[0].shippingDate;

                try {
                  if (latestUpdate && shippingDate) {
                    let luTime = latestUpdate.toDate().getTime();
                    let shTime = shippingDate.toDate().getTime();

                    if (okurijyoNo && hassouDate && (luTime === shTime)) {
                      okurijyoProcess = <div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#19A22B'}}/>;
                      okurijyoStatus = 'shipped';
                      isShipped = true;
                    }
                  }

                  if (!latestUpdate && !shippingDate && hassouDate) {
                    okurijyoProcess = <div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#19A22B'}}/>;
                    okurijyoStatus = 'shipped';
                    isShipped = true;
                  }

                  if (!latestUpdate && shippingDate && hassouDate) {
                    okurijyoProcess = <div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#19A22B'}}/>;
                    okurijyoStatus = 'shipped';
                    isShipped = true;
                  }
                } catch (e) {
                  console.log(e)
                }

                // if (okurijyoNo && hassouDate) {
                //   okurijyoProcess = <div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#19A22B'}}/>;
                //   isShipped = true;
                // }
              }

              if (item.cancelConfirm === false && item.cancelReject === false) {
                okurijyoProcess = <div style={{width: 12, height: 12, backgroundColor: '#ff63e0'}}/>;
              }

              if (item.cancelConfirm === true) {
                okurijyoProcess = <div style={{ width: 12, height: 12, borderRadius: 6, backgroundColor: '#ff63e0' }}/>
              }

              if (item.returnConfirm === false && item.returnReject === false) {
                okurijyoProcess = <div style={{width: 12, height: 12, backgroundColor: '#00C2FF'}}/>;
              }

              if (item.returnConfirm === true) {
                okurijyoProcess = <div style={{width: 12, height: 12, borderRadius: 6, backgroundColor: '#00C2FF'}}/>;
              }

              let count = <div className={'text-nowrap'}>
                <div style={{textAlign: 'right'}}><span style={{color: "darkgray"}}>@</span>{item.price.toLocaleString() + '円'}</div>
                <div style={{textAlign: 'right', fontSize: 18, fontWeight: 'bold'}}>{item.count}</div>
              </div>
              let price = <p className="text-right">{(item.count * item.price).toLocaleString() + '円'}</p>;

              if (item.deletedItem) {
                count = <div style={{textAlign: 'right'}}><span style={{color: 'red'}}>{item.deletedText}</span></div>;
                price = '';
              }

              let templatedNo;
              if (item.subDivided) {
                templatedNo = n(no)+'-'+subDividedNo;
                if (item.returnSub) {
                  templatedNo = n(no)+'-'+(subDividedNo-1)+'-'+returnSubNo;
                  returnSubNo += 1;
                } else {
                  returnSubNo = 1;
                  subDividedNo += 1;
                }
              } else {
                if (item.returnSub) {
                  templatedNo = n(no)+'-'+returnSubNo;
                  returnSubNo += 1;
                } else {
                  no += 1;
                  subDividedNo = 1;
                  returnSubNo = 1;
                  templatedNo = n(no)
                }
              }

              let cancelDate = '';
              let cancelConfirmDate = '';
              let cancelRejectDate = '';
              let returnDate = '';
              let returnConfirmDate = '';
              let returnRejectDate = '';
              if (item.cancelDate) {
                cancelDate = item.cancelDate
                    ? item.cancelDate.toDate().getFullYear() + '/'
                    + n(item.cancelDate.toDate().getMonth()+1) + '/'
                    + n(item.cancelDate.toDate().getDate()) + ' '
                    + n(item.cancelDate.toDate().getHours()) + ':'
                    + n(item.cancelDate.toDate().getMinutes())
                    : '';
              }

              if (item.cancelConfirmDate) {
                cancelConfirmDate = item.cancelConfirmDate
                    ? item.cancelConfirmDate.toDate().getFullYear() + '/'
                    + n(item.cancelConfirmDate.toDate().getMonth()+1) + '/'
                    + n(item.cancelConfirmDate.toDate().getDate()) + ' '
                    + n(item.cancelConfirmDate.toDate().getHours()) + ':'
                    + n(item.cancelConfirmDate.toDate().getMinutes())
                    : '';
              }

              if (item.cancelRejectDate) {
                cancelRejectDate = item.cancelRejectDate
                    ? item.cancelRejectDate.toDate().getFullYear() + '/'
                    + n(item.cancelRejectDate.toDate().getMonth()+1) + '/'
                    + n(item.cancelRejectDate.toDate().getDate()) + ' '
                    + n(item.cancelRejectDate.toDate().getHours()) + ':'
                    + n(item.cancelRejectDate.toDate().getMinutes())
                    : '';
              }

              if (item.returnDate) {
                returnDate = item.returnDate
                    ? item.returnDate.toDate().getFullYear() + '/'
                    + n(item.returnDate.toDate().getMonth()+1) + '/'
                    + n(item.returnDate.toDate().getDate()) + ' '
                    + n(item.returnDate.toDate().getHours()) + ':'
                    + n(item.returnDate.toDate().getMinutes())
                    : '';
              }

              if (item.returnConfirmDate) {
                returnConfirmDate = item.returnConfirmDate
                    ? item.returnConfirmDate.toDate().getFullYear() + '/'
                    + n(item.returnConfirmDate.toDate().getMonth()+1) + '/'
                    + n(item.returnConfirmDate.toDate().getDate()) + ' '
                    + n(item.returnConfirmDate.toDate().getHours()) + ':'
                    + n(item.returnConfirmDate.toDate().getMinutes())
                    : '';
              }

              if (item.returnRejectDate) {
                returnRejectDate = item.returnRejectDate
                    ? item.returnRejectDate.toDate().getFullYear() + '/'
                    + n(item.returnRejectDate.toDate().getMonth()+1) + '/'
                    + n(item.returnRejectDate.toDate().getDate()) + ' '
                    + n(item.returnRejectDate.toDate().getHours()) + ':'
                    + n(item.returnRejectDate.toDate().getMinutes())
                    : '';
              }

              cart.push({
                // date: date,
                no: <div style={{paddingLeft: item.returnConfirm ? 15 : 0}}>
                  {templatedNo}<br/>
                  {/*{item.subDivided && <div className={'divideTag'}>分納</div>}*/}
                  {item.subDivided && item.divideConfirm === true && <div className={'divideTag'}>分納</div>}
                  {item.subDivided && item.divideConfirm === false && <div className={'dividedStatusProgress'}>分納手続中</div>}
                  {item.readComment === false && <div><MDBIcon icon="comments" className="mr-1 commentIcon" /></div>}
                  {item.readComment === true && <div><MDBIcon icon="comments" className="mr-1 commentIconGray" /></div>}

                  {(item.cancelConfirm === false || item.cancelConfirm === true) && <div style={{fontSize: 12, wordBreak: 'keep-all', minWidth: 100, marginTop: 3}}>
                    {(item.cancelConfirm === false && item.cancelReject === false) && <div className={'cancelConfirmWait'}>キャンセル依頼中</div>}
                    {item.cancelConfirm === true && <div className={'cancelConfirmed'}>キャンセル</div>}
                    {item.cancelReject === true && <div class={'cancelReject'}>キャンセル却下</div>}
                    <div>依頼者：{item.cancelByUser}</div>
                    <div>{cancelDate}</div>
                    {item.cancelConfirm === true && <div>確認者：{item.cancelConfirmByUser}</div>}
                    {item.cancelConfirm === true && <div>{cancelConfirmDate}</div>}
                    {item.cancelReject === true && <div>確認者：{item.cancelRejectByUser}</div>}
                    {item.cancelReject === true && <div>{cancelRejectDate}</div>}
                  </div>}

                  {(item.returnConfirm === false || item.returnConfirm === true) && <div style={{fontSize: 12, wordBreak: 'keep-all', minWidth: 100, marginTop: 3}}>
                    {(item.returnConfirm === false && item.returnReject === false) && <div className={'cancelConfirmWait'}>返品依頼中</div>}
                    {item.returnConfirm === true && <div className={'cancelConfirmed'}>返品</div>}
                    {item.returnReject === true && <div class={'cancelReject'}>返品却下</div>}
                    <div>依頼者：{item.returnByUser}</div>
                    <div>{returnDate}</div>
                    {item.returnConfirm === true && <div>確認者：{item.returnConfirmByUser}</div>}
                    {item.returnConfirm === true && <div>{returnConfirmDate}</div>}
                    {item.returnReject === true && <div>確認者：{item.returnRejectByUser}</div>}
                    {item.returnReject === true && <div>{returnRejectDate}</div>}
                  </div>}
                </div>,
                code: item.code,
                name: <Link to={"/items/" + item.id}>{item.name}</Link>,
                maker: item.maker,
                count: count,
                price: price,
                description: <div style={{overflowWrap: 'anywhere'}}>{item.description}</div>,
                nouki: <>
                  {noukiSDate} 〜 {noukiEDate}
                  {item.noukiComment && <>
                    <br/>
                    <span style={{height: 20}}>
                      <MDBIcon icon="comments" className="mr-1 commentIconGray" />
                    </span>
                    <br/>
                    {item.noukiComment}
                  </>}
                </>,
                okurijyo: <>
                  {hassouDate} / {okurijyoNo}
                  {item.okurijyoComment && <>
                    <br/>
                    <span style={{height: 20}}>
                      <MDBIcon icon="comments" className="mr-1 commentIconGray" />
                    </span>
                    <br/>
                    {item.okurijyoComment}
                  </>}
                </>,
                okurijyoProcess: <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative', gap: 5}}>
                  {!item.divided && okurijyoProcess}
                  {this.showCancelMenu(this.state.data, item) && <div className={'menuButtonView'}>
                    <div className={'menuButton'} onClick={(e) => {
                      e.stopPropagation();
                      this.showMenu(item.id, index);
                    }}>
                      <MDBIcon icon={'ellipsis-v'}/>
                    </div>
                    {this.state.showMenu === item.id && this.state.showMenuIndex === index && <div className={'menuArea'} style={{width: 'fit-content'}}>
                      {isShipped === false && <div onClick={() => this.cancelModalOpen(index)}>注文キャンセル</div>}
                      {isShipped === true && <div onClick={() => this.returnModalOpen(index)}>返品</div>}
                    </div>}
                  </div>}
                </div>
              })

        // console.log('item', item.count, item.price, item.tax)

        if (!item.deletedItem && !item.divided) {
          if (!item.tax) {
            item.tax = 0.1;
          }

          if (item.returnConfirm) {
            returnTotal += (item.count * item.price);
          }

          if (item.cancelConfirm) {
            cancelTotal += (item.count * item.price);
          } else {
            if (taxes[item.tax]) {
              taxes[item.tax] += (item.count * item.price * item.tax);
            } else {
              taxes[item.tax] = (item.count * item.price * item.tax);
            }

            taxTotal += parseInt((item.count * item.price * item.tax), 10);
            total += (item.count * item.price);
          }

          if (!item.returnSub) {
            mainTotal += (item.count * item.price);
          }
        }

      });
      postage = cartData.carriage ? cartData.carriage : 0;
      total += postage;
    }
    // console.log(total, cancelTotal)
    const isEnable = (cartData.toUser !== '')
    && (this.state.orderNouki !== '')
    && (this.state.orderBasyo !== '');

    const data = {
      columns: [
        {
          label: 'No.',
          field: 'no',
        },
        {
          label: '商品コード',
          field: 'code',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '商品名',
          field: 'name',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: 'メーカー名',
          field: 'maker',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '単価/数量',
          field: 'num',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '金額',
          field: 'price',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '備考',
          field: 'description',
          sort: 'asc',
          width: 150,
          minimal: 'sm'
        },
        {
          label: '納期回答',
          field: 'nouki',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: '出荷日/送り状番号',
          field: 'okurijyo',
          sort: 'asc',
          width: 270,
          minimal: 'sm'
        },
        {
          label: <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
            <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#19A22B', marginRight: 5}}/>出荷済</row>
            <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#FF3547', marginRight: 5}}/>未出荷</row>
            <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center', wordBreak: 'keep-all'}}><div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#ff63e0', marginRight: 5}}/>キャンセル</row>
            <row style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}><div style={{width: 12, height: 12, borderRadius: 10, backgroundColor: '#00c2ff', marginRight: 5}}/>返品</row>
          </div>,
          field: 'okurijyoProcess',
          width: 200,
          minimal: 'sm'
        }
      ],
      //rows: this.state.dbItems

      rows: cart
    }

    // orderDate
    let orderDate = '';
    if(this.state.data && this.state.data['orderDate'] && this.state.data.cart && this.state.data.cart.items){
      orderDate = this.state.data['orderDate'].toDate().getFullYear() + '/'
          + n(this.state.data['orderDate'].toDate().getMonth()+1) + '/'
          + n(this.state.data['orderDate'].toDate().getDate()) + ' '
          + n(this.state.data['orderDate'].toDate().getHours()) + ':'
          + n(this.state.data['orderDate'].toDate().getMinutes());
    }else {
      orderDate = this.state.data['created'].toDate().getFullYear() + '/'
          + n(this.state.data['created'].toDate().getMonth()+1) + '/'
          + n(this.state.data['created'].toDate().getDate()) + ' '
          + n(this.state.data['created'].toDate().getHours()) + ':'
          + n(this.state.data['created'].toDate().getMinutes());
    }

    // created
    let date = '';
    if(this.state.data && this.state.data['latestUpdate'] && this.state.data.cart && this.state.data.cart.items){
      date = this.state.data['latestUpdate'].toDate().getFullYear() + '/'
           + n(this.state.data['latestUpdate'].toDate().getMonth()+1) + '/'
           + n(this.state.data['latestUpdate'].toDate().getDate()) + ' '
          + n(this.state.data['latestUpdate'].toDate().getHours()) + ':'
          + n(this.state.data['latestUpdate'].toDate().getMinutes());
    }else {
      date = this.state.data['created'].toDate().getFullYear() + '/'
          + n(this.state.data['created'].toDate().getMonth()+1) + '/'
          + n(this.state.data['created'].toDate().getDate()) + ' '
          + n(this.state.data['created'].toDate().getHours()) + ':'
          + n(this.state.data['created'].toDate().getMinutes());
    }

    let orderNoukiDate = '';
    if (this.state.data['cart'].orderNouki == '日付指定') {
      orderNoukiDate = this.state.data['cart'].orderNoukiDate ? this.state.data['cart'].orderNoukiDate.toDate().getFullYear() + '/'
          + (this.state.data['cart'].orderNoukiDate.toDate().getMonth()+1) + '/'
          + this.state.data['cart'].orderNoukiDate.toDate().getDate() + '' : '';
    }

    let rejectDate = '';
    let rejectUserName = '';
    if(this.state.data && this.state.data['rejectDate']){
      rejectDate = this.state.data['rejectDate'].toDate().getFullYear() + '/'
          + n(this.state.data['rejectDate'].toDate().getMonth()+1) + '/'
          + n(this.state.data['rejectDate'].toDate().getDate()) + ' '
          + n(this.state.data['rejectDate'].toDate().getHours()) + ':'
          + n(this.state.data['rejectDate'].toDate().getMinutes());

      rejectUserName = this.state.data['rejectUserName'];
    }

    let visited = false;
    if(this.state.data['visited'] && this.state.data['visited'][this.props.user.id]) {
      visited = true;
    }

    let actionButtons = false;
    if(this.props.user.authorizer){
      if(this.state.data['apply'] && Object.keys(this.state.data['apply']).length > 0) {
      } else if(this.state.data['reject'] && Object.keys(this.state.data['reject']).length > 0) {
      } else {
        actionButtons = true;
      }
    }

    let deliveryAddress = cartData['deliveryAddress'] || {};
    let otasukeDeliveryAddress = cartData['otasukeDeliveryAddress'] || {};

    setTimeout(() => {
      let data = this.state.data;
      let cart = data.cart;
      let items = cart.items;

      let dividedIndexes = [];
      for (let i=0; i<items.length; i++) {
        if (items[i].divided) {
          dividedIndexes.push(i);
        }
      }
      let trs = document.querySelectorAll(".itemListTable tr");
      for (let i=0; i<trs.length; i++) {
        for (let a=0; a<dividedIndexes.length; a++) {
          if (i === dividedIndexes[a]+1) {
            trs[i].classList.add('disabledTR')
          }
        }
      }
    }, 0)


    return (
      <>
        {this.state.showMenu && <div style={{position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 1041}} onClick={() => this.setState({showMenu: null, showMenuIndex: null})}/>}
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <h2>発注書</h2>
          {this.state.data.applied && <MDBBtn disabled={this.state.loadingPDF} onClick={() => this.showPdf()}>
            {this.state.loadingPDF && <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" style={{marginRight: 10}}/>}
            PDF 表示
          </MDBBtn>}
        </div>

        <MDBModal isOpen={this.state.pdfModal} toggle={this.toggle} size="xl">
          <MDBModalHeader toggle={this.toggle}>PDF 表示</MDBModalHeader>
          <MDBModalBody style={{padding: 30}}>
            <div style={{display: "inline-block", marginBottom: 10}}>
              <MDBBtn
                  className="btn-sm btn-block"
                  onClick={() => this.pdfGenerator(this.state.fileNamePDF, this.state.dataPDF, true)}
              >PDF ダウンロード</MDBBtn>
            </div>
            <div style={{overflow: 'auto', position: 'relative', border: '1px solid lightgray'}}>
              <object id="showPDF" type="application/pdf" style={{width: 1080, height: '75vh'}}/>
            </div>
          </MDBModalBody>
        </MDBModal>

        {/*{this.state.detectChange && <Alert*/}
        {/*    message="この注文データが更新されました。"*/}
        {/*    description="更新ボタンをクリックして最新のデータを読み込んでください。"*/}
        {/*    showIcon*/}
        {/*    type="info"*/}
        {/*    action={*/}
        {/*      <Space align="end">*/}
        {/*        <Button size="small" type="primary" onClick={() => this.getCarts()}>*/}
        {/*          更新*/}
        {/*        </Button>*/}
        {/*      </Space>*/}
        {/*    }*/}
        {/*    closable*/}
        {/*/>}*/}

        <div id="wrapper1" style={{height: 20, overflow: 'auto'}}>
          <div id="div1" style={{height: 20}}>
          </div>
        </div>
        <MDBTable btn responsive className={'itemListTable'} id={"div2"}>
          <MDBTableHead color="primary-color" textWhite columns={data.columns} />
          <MDBTableBody rows={data.rows} />
        </MDBTable>

        <MDBTable className="mt-5">
          <MDBTableBody>
            <tr>
              <th>運賃</th>
              <td className="text-right">{postage.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>小計</th>
              <td className="text-right">{mainTotal.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>キャンセル</th>
              <td className="text-right">{cancelTotal ? '-' : ''}{cancelTotal.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>返品</th>
              <td className="text-right">{returnTotal.toLocaleString()}円</td>
            </tr>
            <tr>
              <th>消費税額計</th>
              <td className="text-right">{parseInt((Object.values(taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10).toLocaleString()}円</td>
            </tr>
            {Object.keys(taxes).sort((a, b) => { return b-a }).map(value => {
              return <tr>
                <th>&nbsp; 消費税{value*100}%</th>
                <td className="text-right">{parseInt((taxes[value]),10).toLocaleString()}円</td>
              </tr>
            })}
            {/*<tr>*/}
            {/*  <th>消費税10%</th>*/}
            {/*  <td className="text-right">{parseInt((total * 0.1),10).toLocaleString()}円</td>*/}
            {/*</tr>*/}
            <tr>
              <th>合計</th>
              <td className="text-right">{parseInt((total + parseInt((Object.values(taxes).reduce((a, b) => parseInt(a, 10) + parseInt(b, 10), 0)),10)),10).toLocaleString()}円</td>
            </tr>
          </MDBTableBody>
        </MDBTable>
        { cart.length>0 &&
        <div>

        <div className="p-3">

          <div className="form-group row mt-0">
            <label className="col-sm-2 p-2 bg-light">発注番号</label>
            <div className="col-sm-10 p-2">
              {cartData.orderNo}
            </div>

            <label className="col-sm-2 p-2 bg-light">発注日時</label>
            <div className="col-sm-10 p-2">
              {orderDate}
            </div>

            <label className="col-sm-2 p-2 bg-light">更新日時</label>
            <div className="col-sm-10 p-2">
              {date}
              {rejectDate && <span style={{marginLeft: 50, color: 'red'}}>{`却下: ${rejectDate} (${rejectUserName}) `}</span>}
            </div>

            <label className="col-sm-2 p-2 bg-light">発注者</label>
            <div className="col-sm-10 p-2">
              {this.state.data['fromUserName']}
            </div>

            <label className="col-sm-2 p-2 bg-light">納品場所</label>
            <div className="col-sm-10 p-2">
              {cartData['orderBasyo'] == '登録住所' &&
                <>
                  (登録住所)<br />
                  {this.props.userOrg.zipcode}<br />
                  {this.props.userOrg.address}<br />
                  {this.props.userOrg.display_name}<br />
                  受取担当者：{this.state.data['fromUserName']}<br />
                  電話番号：{this.props.userOrg.TEL}<br/>
                  {/*image: {cartData['files1'] && <StorageLink src={cartData['files1']} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                </>
              }
              {cartData['orderBasyo'] == '登録住所以外' &&
                <>
                  (登録住所以外)<br />
                  {cartData['orderZipcode']}<br />
                  {cartData['orderAddr1']}<br />
                  {cartData['orderAddr2']}<br />
                  {cartData['orderJigyosyo']}<br />
                  受取担当者：{cartData['orderTanto']}<br />
                  電話番号：{cartData['orderPhone']}<br/>
                  {/*image: {cartData['orderFiles1'] && <StorageLink src={cartData['orderFiles1']} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                  {/*{cartData['orderFiles2'] && <StorageLink src={cartData['orderFiles2']} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                </>
              }
              {cartData['orderBasyo'] == '前回の納品先' &&
                  <>
                    (前回の納品先)<br/>
                    {deliveryAddress.propertyName}<br />
                    {deliveryAddress.postCode}<br />
                    {deliveryAddress.address1}<br />
                    {deliveryAddress.address2}<br />
                    {deliveryAddress.officeName}<br />
                    受取担当者：{deliveryAddress.personInCharge}<br />
                    電話番号：{deliveryAddress.phoneNumber}<br/>
                    {/*image: {deliveryAddress.files1 && <StorageLink src={deliveryAddress.files1} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                    {/*{deliveryAddress.files2 && <StorageLink src={deliveryAddress.files2} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                  </>
              }
              {cartData['orderBasyo'] == 'アドレス帳から選択する' &&
                <>
                  (アドレス帳から選択する)<br/>
                  {deliveryAddress.propertyName}<br />
                  {deliveryAddress.postCode}<br />
                  {deliveryAddress.address1}<br />
                  {deliveryAddress.address2}<br />
                  {deliveryAddress.officeName}<br />
                  受取担当者：{deliveryAddress.personInCharge}<br />
                  電話番号：{deliveryAddress.phoneNumber}<br/>
                  {/*image: {deliveryAddress.files1 && <StorageLink src={deliveryAddress.files1} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                  {/*{deliveryAddress.files2 && <StorageLink src={deliveryAddress.files2} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                </>
              }
              {cartData['orderBasyo'] === '販売側からお助けカートで指定された納品先' &&
                  <>
                    (販売側からお助けカートで指定された納品先)<br/>
                    {otasukeDeliveryAddress.propertyName}<br />
                    {otasukeDeliveryAddress.postCode}<br />
                    {otasukeDeliveryAddress.address1}<br />
                    {otasukeDeliveryAddress.address2}<br />
                    {otasukeDeliveryAddress.officeName}<br />
                    受取担当者：{otasukeDeliveryAddress.personInCharge}<br />
                    電話番号：{otasukeDeliveryAddress.phoneNumber}<br/>
                    {/*image: {otasukeDeliveryAddress.files1 && <StorageLink src={otasukeDeliveryAddress.files1} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                    {/*{otasukeDeliveryAddress.files2 && <StorageLink src={otasukeDeliveryAddress.files2} storage={this.props.storage} target="_blank">*/}
                    {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                    {/*</StorageLink>}*/}
                  </>
              }
              {cartData['orderBasyo'] == '納品先を直接入力する' &&
                <>
                  (納品先を直接入力する)<br/>
                  {cartData['orderPropertyName']}<br />
                  {cartData['orderZipcode']}<br />
                  {cartData['orderAddr1']}<br />
                  {cartData['orderAddr2']}<br />
                  {cartData['orderJigyosyo']}<br />
                  受取担当者：{cartData['orderTanto']}<br />
                  電話番号：{cartData['orderPhone']}<br/>
                  {/*image: {cartData['orderFiles1'] && <StorageLink src={cartData['orderFiles1']} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                  {/*{cartData['orderFiles2'] && <StorageLink src={cartData['orderFiles2']} storage={this.props.storage} target="_blank">*/}
                  {/*  <MDBChip bgColor="default-color" text="white" size="sm4" className="text-nowrap" >表示</MDBChip>*/}
                  {/*</StorageLink>}*/}
                </>
              }
            </div>

            <label className="col-sm-2 p-2 bg-light">希望納期</label>
            <div className="col-sm-10 p-2">
              {cartData['orderNouki'] == '最短' ? '最短' : cartData['orderNouki'] == '希望' ?
                <>
                {cartData['orderNoukiDay']}日
                </> :
                <>
                  {orderNoukiDate}
                </>
              }
            </div>

            <label className="col-sm-2 p-2 bg-light">備考</label>
            <div className="col-sm-10 p-2">
              {nl2br(cartData['orderBikou'])}
            </div>

            {false &&
            <>
            <label className="col-sm-2 p-2 bg-light">送り状番号</label>
            <div className="col-sm-10 p-2">
              {this.state.okurijyoNo}
            </div>
            </>
            }

          </div>

        </div>


        { actionButtons &&

          <div className="text-center">

            <MDBBtn
              color=""
              // bgColor="default-color"
              text="white"
              className="btn-lg text-nowrap col-sm-5 customGreenButton"
              disabled={cartData['deletedItem']}
              onClick={this.applyFile}>承認（発注確定）確認</MDBBtn>

            <MDBBtn
              color=""
              // bgColor="warning-color-dark"
              text="white"
              className="btn-lg text-nowrap col-sm-5 customOrangeButton"
              onClick={this.rejectFile}>却下（担当者に差戻）確認</MDBBtn>
          </div>

        }

         </div>
        }

        <MDBModal isOpen={this.state.applyModal} toggle={this.applyClose}>
          <MDBModalHeader toggle={this.applyClose}
                  className="text-center default-color white-text"
                  titleClass="w-100 font-weight-bold"
          >承認(発注確定)</MDBModalHeader>
          <MDBModalBody>
          発注を承認しますか？<br />
          承認すると発注が確定し、販売会社に通知されます。<br />
          よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.applyClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.applyClose} color="default" data-id={this.state.applyId}>承認(発注確定)する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.rejectModal} toggle={this.rejectClose}>
          <MDBModalHeader toggle={this.rejectClose}
                  className="text-center warning-color-dark white-text"
                  titleClass="w-100 font-weight-bold"
          >却下（担当者に差戻）</MDBModalHeader>
          <MDBModalBody>
          発注を却下（担当者に差戻）しますか？<br />
          本発注書での注文はできなくなります。<br />
          よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.rejectClose} color="secondary">キャンセル</MDBBtn>
            <MDBBtn onClick={this.rejectClose} color="danger" data-id={this.state.rejectId}>却下（担当者に差戻）する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.cancelModal} toggle={this.cancelClose}>
          <MDBModalHeader toggle={this.cancelClose} >
            注文キャンセル依頼
          </MDBModalHeader>
          <MDBModalBody>
            キャンセルを依頼します。よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.cancelClose} color="secondary">中止する</MDBBtn>
            <MDBBtn onClick={this.cancelClose} color="danger" data-index={this.state.cancelIndex}>キャンセルを依頼する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>

        <MDBModal isOpen={this.state.returnModal} toggle={this.returnClose}>
          <MDBModalHeader toggle={this.returnClose} >
            返品依頼
          </MDBModalHeader>
          <MDBModalBody>
            返品を依頼します。よろしいですか？
          </MDBModalBody>
          <MDBModalFooter>
            <MDBBtn onClick={this.returnClose} color="secondary">中止する</MDBBtn>
            <MDBBtn onClick={this.returnClose} color="danger" data-index={this.state.returnIndex}>返品を依頼する</MDBBtn>
          </MDBModalFooter>
        </MDBModal>
      </>
    );
  }
}

export default OrderKaigawa;
